import { Dispatch } from "react";

import {CommonActions, CommonState, GetState} from "types/store";
import { KeyValue } from "types";
import { TIMEOUT_MSECS } from "utils/constants";
import { refreshingToken } from "./auth-actions";

let alertTimer: ReturnType<typeof setTimeout>;

export function setModalName(
  modalName: CommonState["modalName"]
): CommonActions {
  return {
    type: "SET_MODAL_NAME",
    payload: modalName,
  };
}

export function toggleLoading(loading: CommonState["loading"]): CommonActions {
  return {
    type: "TOGGLE_LOADING_STATE",
    payload: loading,
  };
}
export function clearCommonState():CommonActions {
  return {
    type:"SET_COMMON_EMPTY",
    payload: undefined
  }
}
export function setError(message: CommonState["errorMessage"]): CommonActions {
  return {
      type: "SET_ERROR_MESSAGE",
      payload: message,
  };
}

export function setResponseState(payload: CommonState["responseState"]) {
  let timeoutSec = payload?.timeoutSec ? payload?.timeoutSec : TIMEOUT_MSECS
  return (dispatch: Dispatch<CommonActions>) => {
    if (alertTimer) {
      clearTimeout(alertTimer);
    }
    dispatch({
      type: "SET_RESPONSE_STATE",
      payload,
    });
    alertTimer = setTimeout(() => {
      dispatch({
        type: "SET_RESPONSE_STATE",
        payload: undefined,
      });
    }, timeoutSec);
  };
}

/**
 * Middleware for api handshake. It includes common actions like loading, error message handling etc
 *
 * @param options
 * client: GRPC client
 * request: request for the client
 * action: client specific action e.d addUser, listUsers etc
 * triggerLoading: control loading event
 * errorMessage: custom error message. Default will be err.message from response
 * successMessage: optional if given then success message will display
 * dispatcher: custom action related to logic
 */
export function apiResponse<
  Client extends KeyValue,
  Request extends KeyValue,
  Response extends KeyValue
>(options: {
  client: Client;
  request: Request;
  action: keyof Client;
  triggerLoading?: boolean;
  errorMessage?: string;
  successMessage?: string;
  dispatcher?: (response: Response) => void;
  ownerOrgId?: string;
  newToken?: String;
  modalAction?: boolean;
}) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      client,
      action,
      request,
      dispatcher,
      errorMessage,
      successMessage,
      newToken,
      triggerLoading = true,
      modalAction,
    } = options;

    const {
      common: { token, refreshToken, ownerOrgId },
    } = getState();

    // Cleaner: Clean previous response state
    dispatch(setResponseState(undefined));
    // Start loading effect if needed
    triggerLoading && dispatch(toggleLoading(true));

    // If owner id is required
    if (request.setOwnerOrgId && (ownerOrgId || options.ownerOrgId)) {
      request.setOwnerOrgId(
        options.ownerOrgId === undefined ? ownerOrgId : options.ownerOrgId
      );
    }

    // client calling specific action
    client[action](
      request,
      {
        authorization: newToken || token,
      },
      function (err: Error & { code: number }, resp: Response) {
        // api call success case
        if (resp) {
          // Dispatch which will dispatch custom actions.
          dispatcher && dispatcher(resp);
          // If there is success message then show it
          // optional for some cases like fetching data in list/tables
          successMessage &&
            dispatch(
              setResponseState({ message: successMessage, type: "success" })
            );

          modalAction && dispatch(setModalName(undefined));
        }
         else if (err.code === 16) { 
          // If user un-authenticated
          // Recursion
          const callback = (newToken: string) => {
            dispatch(
              apiResponse<Client, Request, Response>({ ...options, newToken })
            );
          };
          // Refresh token and make recursion
          if(refreshToken != null) {
            dispatch(refreshingToken(refreshToken || "", callback));
          }
        } else {
          console.log("Response: ",resp);
          console.log("error", action, err.message);
          // Mandatory, can use custom message
           dispatch(
            setResponseState({
              message: errorMessage || err.message,
              type: "error",
            })
          );
        }

        // Start loading effect if needed
        triggerLoading && dispatch(toggleLoading(false));
      }
    );
  };
}
