import { Row, Col } from "antd";

import { VehicleTile } from "components";
import {FINANCIALS_TIES_DATA, FINANCIALS_WIDGET} from "../__mock__";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {useEffect, useMemo} from "react";
import {parseBatteryModelTable} from "../../../../utils/parsers";
import {initializeBatteryModels, initializeCustomerBilling, setTotalBill} from "../../../../store/actions";
import {parseBillingFieldsTable} from "../../../../utils/parsers/billing-parser";
import {useCookies} from "react-cookie";
import {number} from "yup";


export interface FinancialsTileContainerProps {
  selectedDate?: string;
}

export default function FinancialsTileContainer({ selectedDate }: FinancialsTileContainerProps) {
  const [{ meta }] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    if (meta?.user?.organizationId && selectedDate) {
      dispatch(initializeCustomerBilling({ 
        organization_id: meta.user.organizationId, 
        date: selectedDate 
      }));
    }
  }, [dispatch, meta, selectedDate]);

  const {
   bill
  } = useSelector<AppState, AppState["billing"]>((state) => state.billing);
  const {
    organizationId
  } = useSelector<AppState, AppState["common"]>((state) => state.common);
  const data = useMemo(
      () => parseBillingFieldsTable(bill),
      [bill]
  );
  interface BillingTileProps {
    title: string;
    value: string;
  }
  interface BillingSectionProps {
    title: string;
    children: React.ReactNode;
  }
  
  const BillingTile: React.FC<BillingTileProps> = ({ title, value }) => (
    <div className="billing-tile">
      <p>{title}</p>
      <h1>{value}</h1>
    </div>
  );
  
  const BillingSection: React.FC<BillingSectionProps> = ({ title, children }) => (
    <div className="billing-section">
      <h2>{title}</h2>
      {children}
    </div>
  );
  
  interface BillingRowProps {
    children: React.ReactNode;
  }
  
  const BillingRow: React.FC<BillingRowProps> = ({ children }) => (
    <div className="billing-row">
      {children}
    </div>
  );

  return (
     // <Row className="tiles-container">
    //   {Object.values(data).map((tile) => 
    //   (
        
        // <Col key={tile.name} className={tile.className}>
        //   <VehicleTile
        //     name={tile.name}
        //     value={tile.value}
        //     buttonText={tile.buttonText}
        //     isAmount={tile.isAmount}
        //     paymentPer={tile.paymentPer}
        //   />
        // </Col>
      <div className="billing-container">
        <BillingSection title="">
          <BillingRow>
            <BillingTile title="Total Bikes in Operation" value={"" + data.totalBikes} />
          </BillingRow>
        </BillingSection>
          {data.billingItems.map((lineItem, index) => (
              <BillingSection key={index} title={lineItem.item}>
                  <BillingRow>
                      <BillingTile title="Rate" value={"PKR " + lineItem.rate} />
                      <BillingTile title={""+ lineItem.units == "KM" ? "Distance" : "Quantity"} value={ lineItem.quantity  + " " + lineItem.units} />
                      <BillingTile title="Total" value={"" + lineItem.total} />
                  </BillingRow>
              </BillingSection>
          ))}

      </div>
      )
}
