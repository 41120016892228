import { useCallback, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import { actionMenu } from "svgs";
import { useDispatch } from "react-redux";
import { setModalName, setSelectedUser } from "store/actions";
import { getFullName } from "utils/helpers";
import { UserDataItem } from "types";

export default function DropdownAction({ user }: { user: UserDataItem }) {
  const dispatch = useDispatch();

  const onAddTasks = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setModalName("add_task"));
  }, [dispatch]);

  const onViewTaskDetails = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setModalName("view_task_details"));
  }, [dispatch]);

  const onEditShift = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setModalName("edit_shift"));
  }, [dispatch]);

  const onArchive = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    //dispatch(
    //   setSelectedUser({
    //     id: user?.id,
    //     name: getFullName(user?.firstName, user?.lastName),
    //     phone: user?.phoneNumber,
    //     email: user?.email,
    //     imgUrl: user?.profilePhotoLink,
    //     type: user?.type,
    //     documentId: user?.documentId,
    //     lincenceNo: user?.licenseNo,
    //     document: user?.document,
    //     documentName: user?.documentName,
    //     documentType: user?.documentExt,
    //       organization_id:user?.organizationId,
    //       organization_name:user?.organizationName
    //   })
    // );
    dispatch(setModalName("block_account"));
  }, [dispatch, user]);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="3" className="delete-action" onClick={onArchive}>
          Archive
        </Menu.Item>
      </Menu>
    ),
    [onAddTasks, onEditShift, onArchive]
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.stopPropagation()}>{actionMenu}</a>
    </Dropdown>
  );
}
