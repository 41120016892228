// source: vehicle_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var page_pb = require('./page_pb.js');
goog.object.extend(proto, page_pb);
var filter_pb = require('./filter_pb.js');
goog.object.extend(proto, filter_pb);
var geo_pb = require('./geo_pb.js');
goog.object.extend(proto, geo_pb);
var assignment_management_service_pb = require('./assignment_management_service_pb.js');
goog.object.extend(proto, assignment_management_service_pb);
var kioskService_pb = require('./kioskService_pb.js');
goog.object.extend(proto, kioskService_pb);
var webhook_pb = require('./webhook_pb.js');
goog.object.extend(proto, webhook_pb);
goog.exportSymbol('proto.api.AddModelAdminRequest', null, global);
goog.exportSymbol('proto.api.AddModelAdminResponse', null, global);
goog.exportSymbol('proto.api.AddModelManagmentRequest', null, global);
goog.exportSymbol('proto.api.AddModelManagmentResponse', null, global);
goog.exportSymbol('proto.api.AddVehicleFromCSVRequest', null, global);
goog.exportSymbol('proto.api.AddVehicleFromCSVResponse', null, global);
goog.exportSymbol('proto.api.AddVehicleFromCSVResponse.ErrorMessage', null, global);
goog.exportSymbol('proto.api.AddVehicleRequest', null, global);
goog.exportSymbol('proto.api.AddVehicleResponse', null, global);
goog.exportSymbol('proto.api.AllAssignedVehicleResponse', null, global);
goog.exportSymbol('proto.api.AllAssignedVehicleResponse.Item', null, global);
goog.exportSymbol('proto.api.AllAvailableAssignedVehicleResponse', null, global);
goog.exportSymbol('proto.api.AllAvailableAssignedVehicleResponse.Item', null, global);
goog.exportSymbol('proto.api.AssignVehicleRequest', null, global);
goog.exportSymbol('proto.api.AssignVehicleResponse', null, global);
goog.exportSymbol('proto.api.AssignedVehicleRequest', null, global);
goog.exportSymbol('proto.api.AssignedVehicleResponse', null, global);
goog.exportSymbol('proto.api.ByIotIdRequest', null, global);
goog.exportSymbol('proto.api.DeleteModelRequest', null, global);
goog.exportSymbol('proto.api.DeleteModelResponse', null, global);
goog.exportSymbol('proto.api.DeleteVehicleAdminRequest', null, global);
goog.exportSymbol('proto.api.DeleteVehicleAdminResponse', null, global);
goog.exportSymbol('proto.api.DeleteVehicleManagementRequest', null, global);
goog.exportSymbol('proto.api.DeleteVehicleManagementResponse', null, global);
goog.exportSymbol('proto.api.FirmwareItem', null, global);
goog.exportSymbol('proto.api.GenerateVehicleRIDRequest', null, global);
goog.exportSymbol('proto.api.GenerateVehicleRIDResponse', null, global);
goog.exportSymbol('proto.api.GetKioskListRequest', null, global);
goog.exportSymbol('proto.api.GetModelAdminRequest', null, global);
goog.exportSymbol('proto.api.GetModelAdminResponse', null, global);
goog.exportSymbol('proto.api.GetModelAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.GetModelManagementRequest', null, global);
goog.exportSymbol('proto.api.GetModelManagementResponse', null, global);
goog.exportSymbol('proto.api.GetModelManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.GetModelRequest', null, global);
goog.exportSymbol('proto.api.GetModelResponse', null, global);
goog.exportSymbol('proto.api.GetTotalDistanceByClientRequest', null, global);
goog.exportSymbol('proto.api.GetTotalDistanceByClientResponse', null, global);
goog.exportSymbol('proto.api.GetTotalDistanceByClientResponse.Item', null, global);
goog.exportSymbol('proto.api.GetTotalVehicleMileageRequest', null, global);
goog.exportSymbol('proto.api.GetTotalVehicleMileageResponse', null, global);
goog.exportSymbol('proto.api.GetVehicleAdminRequest', null, global);
goog.exportSymbol('proto.api.GetVehicleAdminResponse', null, global);
goog.exportSymbol('proto.api.GetVehicleAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.GetVehicleListRequest', null, global);
goog.exportSymbol('proto.api.GetVehicleListResponse', null, global);
goog.exportSymbol('proto.api.GetVehicleListResponse.VehicleListItem', null, global);
goog.exportSymbol('proto.api.GetVehicleManagementRequest', null, global);
goog.exportSymbol('proto.api.GetVehicleManagementResponse', null, global);
goog.exportSymbol('proto.api.GetVehicleManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.GetVehicleRequest', null, global);
goog.exportSymbol('proto.api.GetVehicleResponse', null, global);
goog.exportSymbol('proto.api.GetVehicleResponse.Item', null, global);
goog.exportSymbol('proto.api.ListDropDownVehiclesRequest', null, global);
goog.exportSymbol('proto.api.ListDropDownVehiclesResponse', null, global);
goog.exportSymbol('proto.api.ListDropDownVehiclesResponse.Item', null, global);
goog.exportSymbol('proto.api.ListFirmwaresRequest', null, global);
goog.exportSymbol('proto.api.ListFirmwaresResponse', null, global);
goog.exportSymbol('proto.api.ListModelsAdminRequest', null, global);
goog.exportSymbol('proto.api.ListModelsAdminResponse', null, global);
goog.exportSymbol('proto.api.ListModelsAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.ListModelsManagementRequest', null, global);
goog.exportSymbol('proto.api.ListModelsManagementResponse', null, global);
goog.exportSymbol('proto.api.ListModelsManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.ListVehicleModelsRequest', null, global);
goog.exportSymbol('proto.api.ListVehicleModelsResponse', null, global);
goog.exportSymbol('proto.api.ListVehicleModelsResponse.Item', null, global);
goog.exportSymbol('proto.api.ListVehicleRequest', null, global);
goog.exportSymbol('proto.api.ListVehicleResponse', null, global);
goog.exportSymbol('proto.api.ListVehicleResponse.Item', null, global);
goog.exportSymbol('proto.api.ListVehiclesAdminRequest', null, global);
goog.exportSymbol('proto.api.ListVehiclesAdminRequest.Filter', null, global);
goog.exportSymbol('proto.api.ListVehiclesAdminResponse', null, global);
goog.exportSymbol('proto.api.ListVehiclesAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.ListVehiclesManagementRequest', null, global);
goog.exportSymbol('proto.api.ListVehiclesManagementRequest.Filter', null, global);
goog.exportSymbol('proto.api.ListVehiclesManagementResponse', null, global);
goog.exportSymbol('proto.api.ListVehiclesManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.ModelItem', null, global);
goog.exportSymbol('proto.api.ModelUpdateItem', null, global);
goog.exportSymbol('proto.api.MyVehicleRequest', null, global);
goog.exportSymbol('proto.api.MyVehicleResponse', null, global);
goog.exportSymbol('proto.api.MyVehicleResponse.Item', null, global);
goog.exportSymbol('proto.api.UpdateBulkVehicleFirmwareVersionRequest', null, global);
goog.exportSymbol('proto.api.UpdateBulkVehicleFirmwareVersionResponse', null, global);
goog.exportSymbol('proto.api.UpdateFirmwareOnVehiclesRequest', null, global);
goog.exportSymbol('proto.api.UpdateFirmwareOnVehiclesResponse', null, global);
goog.exportSymbol('proto.api.UpdateFirmwareVersionToModelRequest', null, global);
goog.exportSymbol('proto.api.UpdateFirmwareVersionToModelResponse', null, global);
goog.exportSymbol('proto.api.UpdateModelRequest', null, global);
goog.exportSymbol('proto.api.UpdateModelResponse', null, global);
goog.exportSymbol('proto.api.UpdateMqttServerByVehicleRequest', null, global);
goog.exportSymbol('proto.api.UpdateMqttServerByVehicleResponse', null, global);
goog.exportSymbol('proto.api.UpdateRegistrationPlateNumberRequest', null, global);
goog.exportSymbol('proto.api.UpdateRegistrationPlateNumberResponse', null, global);
goog.exportSymbol('proto.api.UpdateVehicleFirmwareVersionRequest', null, global);
goog.exportSymbol('proto.api.UpdateVehicleFirmwareVersionResponse', null, global);
goog.exportSymbol('proto.api.UpdateVehicleItem', null, global);
goog.exportSymbol('proto.api.UpdateVehicleRequest', null, global);
goog.exportSymbol('proto.api.UpdateVehicleResponse', null, global);
goog.exportSymbol('proto.api.UploadCsvRequest', null, global);
goog.exportSymbol('proto.api.UploadCsvResponse', null, global);
goog.exportSymbol('proto.api.UploadCsvResponse.ErrorMessages', null, global);
goog.exportSymbol('proto.api.UploadFirmwareToModelRequest', null, global);
goog.exportSymbol('proto.api.UploadFirmwareToModelResponse', null, global);
goog.exportSymbol('proto.api.UploadVehicleFirmwareToSpacesRequest', null, global);
goog.exportSymbol('proto.api.UploadVehicleFirmwareToSpacesResponse', null, global);
goog.exportSymbol('proto.api.UserDetailsItem', null, global);
goog.exportSymbol('proto.api.VehicleCheckoutRequest', null, global);
goog.exportSymbol('proto.api.VehicleCheckoutResponse', null, global);
goog.exportSymbol('proto.api.VehicleItem', null, global);
goog.exportSymbol('proto.api.VehicleModelFileItem', null, global);
goog.exportSymbol('proto.api.VehicleReturnRequest', null, global);
goog.exportSymbol('proto.api.VehicleReturnResponse', null, global);
goog.exportSymbol('proto.api.VehicleServiceStatus', null, global);
goog.exportSymbol('proto.api.VehicleServiceStatus.Enum', null, global);
goog.exportSymbol('proto.api.VehicleStatus', null, global);
goog.exportSymbol('proto.api.VehicleStatus.Enum', null, global);
goog.exportSymbol('proto.api.VehicleUtilizeStatus', null, global);
goog.exportSymbol('proto.api.VehicleUtilizeStatus.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AssignVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignVehicleRequest.displayName = 'proto.api.AssignVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AssignVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignVehicleResponse.displayName = 'proto.api.AssignVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignedVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AssignedVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignedVehicleRequest.displayName = 'proto.api.AssignedVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MyVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MyVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MyVehicleRequest.displayName = 'proto.api.MyVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetKioskListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetKioskListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetKioskListRequest.displayName = 'proto.api.GetKioskListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignedVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AssignedVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignedVehicleResponse.displayName = 'proto.api.AssignedVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AllAssignedVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AllAssignedVehicleResponse.repeatedFields_, null);
};
goog.inherits(proto.api.AllAssignedVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AllAssignedVehicleResponse.displayName = 'proto.api.AllAssignedVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AllAssignedVehicleResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AllAssignedVehicleResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AllAssignedVehicleResponse.Item.displayName = 'proto.api.AllAssignedVehicleResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AllAvailableAssignedVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AllAvailableAssignedVehicleResponse.repeatedFields_, null);
};
goog.inherits(proto.api.AllAvailableAssignedVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AllAvailableAssignedVehicleResponse.displayName = 'proto.api.AllAvailableAssignedVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AllAvailableAssignedVehicleResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AllAvailableAssignedVehicleResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AllAvailableAssignedVehicleResponse.Item.displayName = 'proto.api.AllAvailableAssignedVehicleResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MyVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.MyVehicleResponse.repeatedFields_, null);
};
goog.inherits(proto.api.MyVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MyVehicleResponse.displayName = 'proto.api.MyVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MyVehicleResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MyVehicleResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MyVehicleResponse.Item.displayName = 'proto.api.MyVehicleResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ByIotIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ByIotIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ByIotIdRequest.displayName = 'proto.api.ByIotIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleCheckoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleCheckoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleCheckoutRequest.displayName = 'proto.api.VehicleCheckoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleCheckoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleCheckoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleCheckoutResponse.displayName = 'proto.api.VehicleCheckoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleReturnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleReturnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleReturnRequest.displayName = 'proto.api.VehicleReturnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleReturnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleReturnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleReturnResponse.displayName = 'proto.api.VehicleReturnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.VehicleItem.repeatedFields_, null);
};
goog.inherits(proto.api.VehicleItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleItem.displayName = 'proto.api.VehicleItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleStatus.displayName = 'proto.api.VehicleStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleServiceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleServiceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleServiceStatus.displayName = 'proto.api.VehicleServiceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleUtilizeStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleUtilizeStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleUtilizeStatus.displayName = 'proto.api.VehicleUtilizeStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddVehicleResponse.displayName = 'proto.api.AddVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateVehicleResponse.displayName = 'proto.api.UpdateVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleResponse.displayName = 'proto.api.GetVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetVehicleResponse.Item.repeatedFields_, null);
};
goog.inherits(proto.api.GetVehicleResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleResponse.Item.displayName = 'proto.api.GetVehicleResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleManagementResponse.displayName = 'proto.api.GetVehicleManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetVehicleManagementResponse.Item.repeatedFields_, null);
};
goog.inherits(proto.api.GetVehicleManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleManagementResponse.Item.displayName = 'proto.api.GetVehicleManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleAdminResponse.displayName = 'proto.api.GetVehicleAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleAdminResponse.Item.displayName = 'proto.api.GetVehicleAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleRequest.displayName = 'proto.api.GetVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleManagementRequest.displayName = 'proto.api.GetVehicleManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleAdminRequest.displayName = 'proto.api.GetVehicleAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateVehicleItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.UpdateVehicleItem.repeatedFields_, null);
};
goog.inherits(proto.api.UpdateVehicleItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateVehicleItem.displayName = 'proto.api.UpdateVehicleItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateVehicleRequest.displayName = 'proto.api.UpdateVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddVehicleRequest.displayName = 'proto.api.AddVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteVehicleAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteVehicleAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteVehicleAdminResponse.displayName = 'proto.api.DeleteVehicleAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ModelItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ModelItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ModelItem.displayName = 'proto.api.ModelItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ModelUpdateItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ModelUpdateItem.repeatedFields_, null);
};
goog.inherits(proto.api.ModelUpdateItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ModelUpdateItem.displayName = 'proto.api.ModelUpdateItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddModelAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddModelAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddModelAdminRequest.displayName = 'proto.api.AddModelAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddModelAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddModelAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddModelAdminResponse.displayName = 'proto.api.AddModelAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VehicleModelFileItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VehicleModelFileItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VehicleModelFileItem.displayName = 'proto.api.VehicleModelFileItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddModelManagmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddModelManagmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddModelManagmentRequest.displayName = 'proto.api.AddModelManagmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddModelManagmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddModelManagmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddModelManagmentResponse.displayName = 'proto.api.AddModelManagmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateModelRequest.displayName = 'proto.api.UpdateModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateModelResponse.displayName = 'proto.api.UpdateModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteModelRequest.displayName = 'proto.api.DeleteModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteModelResponse.displayName = 'proto.api.DeleteModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelRequest.displayName = 'proto.api.GetModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelManagementRequest.displayName = 'proto.api.GetModelManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelAdminRequest.displayName = 'proto.api.GetModelAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelResponse.displayName = 'proto.api.GetModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelManagementResponse.displayName = 'proto.api.GetModelManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelManagementResponse.Item.displayName = 'proto.api.GetModelManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelAdminResponse.displayName = 'proto.api.GetModelAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetModelAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetModelAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetModelAdminResponse.Item.displayName = 'proto.api.GetModelAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListModelsManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsManagementRequest.displayName = 'proto.api.ListModelsManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListModelsAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsAdminRequest.displayName = 'proto.api.ListModelsAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListModelsManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListModelsManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsManagementResponse.displayName = 'proto.api.ListModelsManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListModelsManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsManagementResponse.Item.displayName = 'proto.api.ListModelsManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListModelsAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListModelsAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsAdminResponse.displayName = 'proto.api.ListModelsAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListModelsAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListModelsAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListModelsAdminResponse.Item.displayName = 'proto.api.ListModelsAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehiclesManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesManagementRequest.displayName = 'proto.api.ListVehiclesManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesManagementRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehiclesManagementRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesManagementRequest.Filter.displayName = 'proto.api.ListVehiclesManagementRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UserDetailsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UserDetailsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UserDetailsItem.displayName = 'proto.api.UserDetailsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListVehiclesManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListVehiclesManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesManagementResponse.displayName = 'proto.api.ListVehiclesManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListVehiclesManagementResponse.Item.repeatedFields_, null);
};
goog.inherits(proto.api.ListVehiclesManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesManagementResponse.Item.displayName = 'proto.api.ListVehiclesManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListDropDownVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListDropDownVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListDropDownVehiclesRequest.displayName = 'proto.api.ListDropDownVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListDropDownVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListDropDownVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListDropDownVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListDropDownVehiclesResponse.displayName = 'proto.api.ListDropDownVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListDropDownVehiclesResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListDropDownVehiclesResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListDropDownVehiclesResponse.Item.displayName = 'proto.api.ListDropDownVehiclesResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleRequest.displayName = 'proto.api.ListVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListVehicleResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleResponse.displayName = 'proto.api.ListVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehicleResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleResponse.Item.displayName = 'proto.api.ListVehicleResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehiclesAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesAdminRequest.displayName = 'proto.api.ListVehiclesAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesAdminRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehiclesAdminRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesAdminRequest.Filter.displayName = 'proto.api.ListVehiclesAdminRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListVehiclesAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListVehiclesAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesAdminResponse.displayName = 'proto.api.ListVehiclesAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehiclesAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehiclesAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehiclesAdminResponse.Item.displayName = 'proto.api.ListVehiclesAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteVehicleAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteVehicleAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteVehicleAdminRequest.displayName = 'proto.api.DeleteVehicleAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadCsvRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadCsvRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadCsvRequest.displayName = 'proto.api.UploadCsvRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadCsvResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.UploadCsvResponse.repeatedFields_, null);
};
goog.inherits(proto.api.UploadCsvResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadCsvResponse.displayName = 'proto.api.UploadCsvResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadCsvResponse.ErrorMessages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadCsvResponse.ErrorMessages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadCsvResponse.ErrorMessages.displayName = 'proto.api.UploadCsvResponse.ErrorMessages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteVehicleManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteVehicleManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteVehicleManagementRequest.displayName = 'proto.api.DeleteVehicleManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteVehicleManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteVehicleManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteVehicleManagementResponse.displayName = 'proto.api.DeleteVehicleManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehicleModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleModelsRequest.displayName = 'proto.api.ListVehicleModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListVehicleModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListVehicleModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleModelsResponse.displayName = 'proto.api.ListVehicleModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListVehicleModelsResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListVehicleModelsResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListVehicleModelsResponse.Item.displayName = 'proto.api.ListVehicleModelsResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateVehicleFirmwareVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateVehicleFirmwareVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateVehicleFirmwareVersionRequest.displayName = 'proto.api.UpdateVehicleFirmwareVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateVehicleFirmwareVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateVehicleFirmwareVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateVehicleFirmwareVersionResponse.displayName = 'proto.api.UpdateVehicleFirmwareVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadVehicleFirmwareToSpacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadVehicleFirmwareToSpacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadVehicleFirmwareToSpacesRequest.displayName = 'proto.api.UploadVehicleFirmwareToSpacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadVehicleFirmwareToSpacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadVehicleFirmwareToSpacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadVehicleFirmwareToSpacesResponse.displayName = 'proto.api.UploadVehicleFirmwareToSpacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateBulkVehicleFirmwareVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateBulkVehicleFirmwareVersionRequest.displayName = 'proto.api.UpdateBulkVehicleFirmwareVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateBulkVehicleFirmwareVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateBulkVehicleFirmwareVersionResponse.displayName = 'proto.api.UpdateBulkVehicleFirmwareVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadFirmwareToModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadFirmwareToModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadFirmwareToModelRequest.displayName = 'proto.api.UploadFirmwareToModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UploadFirmwareToModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UploadFirmwareToModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UploadFirmwareToModelResponse.displayName = 'proto.api.UploadFirmwareToModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateFirmwareVersionToModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateFirmwareVersionToModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateFirmwareVersionToModelRequest.displayName = 'proto.api.UpdateFirmwareVersionToModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateFirmwareVersionToModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateFirmwareVersionToModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateFirmwareVersionToModelResponse.displayName = 'proto.api.UpdateFirmwareVersionToModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateMqttServerByVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateMqttServerByVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateMqttServerByVehicleRequest.displayName = 'proto.api.UpdateMqttServerByVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateMqttServerByVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateMqttServerByVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateMqttServerByVehicleResponse.displayName = 'proto.api.UpdateMqttServerByVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateFirmwareOnVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.UpdateFirmwareOnVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.api.UpdateFirmwareOnVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateFirmwareOnVehiclesRequest.displayName = 'proto.api.UpdateFirmwareOnVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateFirmwareOnVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateFirmwareOnVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateFirmwareOnVehiclesResponse.displayName = 'proto.api.UpdateFirmwareOnVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.FirmwareItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.FirmwareItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.FirmwareItem.displayName = 'proto.api.FirmwareItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListFirmwaresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListFirmwaresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListFirmwaresRequest.displayName = 'proto.api.ListFirmwaresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListFirmwaresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListFirmwaresResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListFirmwaresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListFirmwaresResponse.displayName = 'proto.api.ListFirmwaresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetTotalDistanceByClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetTotalDistanceByClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetTotalDistanceByClientRequest.displayName = 'proto.api.GetTotalDistanceByClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetTotalDistanceByClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetTotalDistanceByClientResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetTotalDistanceByClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetTotalDistanceByClientResponse.displayName = 'proto.api.GetTotalDistanceByClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetTotalDistanceByClientResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetTotalDistanceByClientResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetTotalDistanceByClientResponse.Item.displayName = 'proto.api.GetTotalDistanceByClientResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddVehicleFromCSVRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddVehicleFromCSVRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddVehicleFromCSVRequest.displayName = 'proto.api.AddVehicleFromCSVRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddVehicleFromCSVResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AddVehicleFromCSVResponse.repeatedFields_, null);
};
goog.inherits(proto.api.AddVehicleFromCSVResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddVehicleFromCSVResponse.displayName = 'proto.api.AddVehicleFromCSVResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddVehicleFromCSVResponse.ErrorMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddVehicleFromCSVResponse.ErrorMessage.displayName = 'proto.api.AddVehicleFromCSVResponse.ErrorMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateRegistrationPlateNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateRegistrationPlateNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateRegistrationPlateNumberRequest.displayName = 'proto.api.UpdateRegistrationPlateNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateRegistrationPlateNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateRegistrationPlateNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateRegistrationPlateNumberResponse.displayName = 'proto.api.UpdateRegistrationPlateNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GenerateVehicleRIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GenerateVehicleRIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GenerateVehicleRIDRequest.displayName = 'proto.api.GenerateVehicleRIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GenerateVehicleRIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GenerateVehicleRIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GenerateVehicleRIDResponse.displayName = 'proto.api.GenerateVehicleRIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleListRequest.displayName = 'proto.api.GetVehicleListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetVehicleListResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetVehicleListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleListResponse.displayName = 'proto.api.GetVehicleListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetVehicleListResponse.VehicleListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetVehicleListResponse.VehicleListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetVehicleListResponse.VehicleListItem.displayName = 'proto.api.GetVehicleListResponse.VehicleListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetTotalVehicleMileageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetTotalVehicleMileageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetTotalVehicleMileageRequest.displayName = 'proto.api.GetTotalVehicleMileageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetTotalVehicleMileageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetTotalVehicleMileageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetTotalVehicleMileageResponse.displayName = 'proto.api.GetTotalVehicleMileageResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignVehicleRequest}
 */
proto.api.AssignVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignVehicleRequest;
  return proto.api.AssignVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignVehicleRequest}
 */
proto.api.AssignVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.AssignVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignVehicleRequest} returns this
 */
proto.api.AssignVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.AssignVehicleRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignVehicleRequest} returns this
 */
proto.api.AssignVehicleRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignVehicleResponse}
 */
proto.api.AssignVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignVehicleResponse;
  return proto.api.AssignVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignVehicleResponse}
 */
proto.api.AssignVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignedVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignedVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignedVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignedVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignedVehicleRequest}
 */
proto.api.AssignedVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignedVehicleRequest;
  return proto.api.AssignedVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignedVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignedVehicleRequest}
 */
proto.api.AssignedVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignedVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignedVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignedVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignedVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.AssignedVehicleRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignedVehicleRequest} returns this
 */
proto.api.AssignedVehicleRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MyVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MyVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MyVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MyVehicleRequest}
 */
proto.api.MyVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MyVehicleRequest;
  return proto.api.MyVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MyVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MyVehicleRequest}
 */
proto.api.MyVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MyVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MyVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MyVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.MyVehicleRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleRequest} returns this
 */
proto.api.MyVehicleRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetKioskListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetKioskListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetKioskListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetKioskListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetKioskListRequest}
 */
proto.api.GetKioskListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetKioskListRequest;
  return proto.api.GetKioskListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetKioskListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetKioskListRequest}
 */
proto.api.GetKioskListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetKioskListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetKioskListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetKioskListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetKioskListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignedVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignedVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignedVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignedVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignedVehicleResponse}
 */
proto.api.AssignedVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignedVehicleResponse;
  return proto.api.AssignedVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignedVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignedVehicleResponse}
 */
proto.api.AssignedVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignedVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignedVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignedVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignedVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.AssignedVehicleResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignedVehicleResponse} returns this
 */
proto.api.AssignedVehicleResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string license_plate = 2;
 * @return {string}
 */
proto.api.AssignedVehicleResponse.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignedVehicleResponse} returns this
 */
proto.api.AssignedVehicleResponse.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AllAssignedVehicleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AllAssignedVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AllAssignedVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AllAssignedVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAssignedVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleList: jspb.Message.toObjectList(msg.getVehicleList(),
    proto.api.AllAssignedVehicleResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AllAssignedVehicleResponse}
 */
proto.api.AllAssignedVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AllAssignedVehicleResponse;
  return proto.api.AllAssignedVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AllAssignedVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AllAssignedVehicleResponse}
 */
proto.api.AllAssignedVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AllAssignedVehicleResponse.Item;
      reader.readMessage(value,proto.api.AllAssignedVehicleResponse.Item.deserializeBinaryFromReader);
      msg.addVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AllAssignedVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AllAssignedVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AllAssignedVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAssignedVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AllAssignedVehicleResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AllAssignedVehicleResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AllAssignedVehicleResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAssignedVehicleResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AllAssignedVehicleResponse.Item}
 */
proto.api.AllAssignedVehicleResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AllAssignedVehicleResponse.Item;
  return proto.api.AllAssignedVehicleResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AllAssignedVehicleResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AllAssignedVehicleResponse.Item}
 */
proto.api.AllAssignedVehicleResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AllAssignedVehicleResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AllAssignedVehicleResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAssignedVehicleResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AllAssignedVehicleResponse.Item} returns this
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string license_plate = 2;
 * @return {string}
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AllAssignedVehicleResponse.Item} returns this
 */
proto.api.AllAssignedVehicleResponse.Item.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Item vehicle = 1;
 * @return {!Array<!proto.api.AllAssignedVehicleResponse.Item>}
 */
proto.api.AllAssignedVehicleResponse.prototype.getVehicleList = function() {
  return /** @type{!Array<!proto.api.AllAssignedVehicleResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AllAssignedVehicleResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.AllAssignedVehicleResponse.Item>} value
 * @return {!proto.api.AllAssignedVehicleResponse} returns this
*/
proto.api.AllAssignedVehicleResponse.prototype.setVehicleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AllAssignedVehicleResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AllAssignedVehicleResponse.Item}
 */
proto.api.AllAssignedVehicleResponse.prototype.addVehicle = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AllAssignedVehicleResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AllAssignedVehicleResponse} returns this
 */
proto.api.AllAssignedVehicleResponse.prototype.clearVehicleList = function() {
  return this.setVehicleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AllAvailableAssignedVehicleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AllAvailableAssignedVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AllAvailableAssignedVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AllAvailableAssignedVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAvailableAssignedVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleList: jspb.Message.toObjectList(msg.getVehicleList(),
    proto.api.AllAvailableAssignedVehicleResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AllAvailableAssignedVehicleResponse}
 */
proto.api.AllAvailableAssignedVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AllAvailableAssignedVehicleResponse;
  return proto.api.AllAvailableAssignedVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AllAvailableAssignedVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AllAvailableAssignedVehicleResponse}
 */
proto.api.AllAvailableAssignedVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AllAvailableAssignedVehicleResponse.Item;
      reader.readMessage(value,proto.api.AllAvailableAssignedVehicleResponse.Item.deserializeBinaryFromReader);
      msg.addVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AllAvailableAssignedVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AllAvailableAssignedVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AllAvailableAssignedVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAvailableAssignedVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AllAvailableAssignedVehicleResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AllAvailableAssignedVehicleResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AllAvailableAssignedVehicleResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AllAvailableAssignedVehicleResponse.Item}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AllAvailableAssignedVehicleResponse.Item;
  return proto.api.AllAvailableAssignedVehicleResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AllAvailableAssignedVehicleResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AllAvailableAssignedVehicleResponse.Item}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AllAvailableAssignedVehicleResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AllAvailableAssignedVehicleResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AllAvailableAssignedVehicleResponse.Item} returns this
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string license_plate = 2;
 * @return {string}
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AllAvailableAssignedVehicleResponse.Item} returns this
 */
proto.api.AllAvailableAssignedVehicleResponse.Item.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Item vehicle = 1;
 * @return {!Array<!proto.api.AllAvailableAssignedVehicleResponse.Item>}
 */
proto.api.AllAvailableAssignedVehicleResponse.prototype.getVehicleList = function() {
  return /** @type{!Array<!proto.api.AllAvailableAssignedVehicleResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AllAvailableAssignedVehicleResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.AllAvailableAssignedVehicleResponse.Item>} value
 * @return {!proto.api.AllAvailableAssignedVehicleResponse} returns this
*/
proto.api.AllAvailableAssignedVehicleResponse.prototype.setVehicleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AllAvailableAssignedVehicleResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AllAvailableAssignedVehicleResponse.Item}
 */
proto.api.AllAvailableAssignedVehicleResponse.prototype.addVehicle = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AllAvailableAssignedVehicleResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AllAvailableAssignedVehicleResponse} returns this
 */
proto.api.AllAvailableAssignedVehicleResponse.prototype.clearVehicleList = function() {
  return this.setVehicleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.MyVehicleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MyVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MyVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MyVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.api.MyVehicleResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MyVehicleResponse}
 */
proto.api.MyVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MyVehicleResponse;
  return proto.api.MyVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MyVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MyVehicleResponse}
 */
proto.api.MyVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.MyVehicleResponse.Item;
      reader.readMessage(value,proto.api.MyVehicleResponse.Item.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MyVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MyVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MyVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.MyVehicleResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MyVehicleResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MyVehicleResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MyVehicleResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: (f = msg.getLocation()) && geo_pb.Point.toObject(includeInstance, f),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    batteryLevel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    powerType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    totalMileage: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MyVehicleResponse.Item}
 */
proto.api.MyVehicleResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MyVehicleResponse.Item;
  return proto.api.MyVehicleResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MyVehicleResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MyVehicleResponse.Item}
 */
proto.api.MyVehicleResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 6:
      var value = new geo_pb.Point;
      reader.readMessage(value,geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatteryLevel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMileage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MyVehicleResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MyVehicleResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MyVehicleResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MyVehicleResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBatteryLevel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPowerType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTotalMileage();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.MyVehicleResponse.Item.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
*/
proto.api.MyVehicleResponse.Item.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MyVehicleResponse.Item.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.MyVehicleResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.MyVehicleResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.MyVehicleResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Point location = 6;
 * @return {?proto.api.Point}
 */
proto.api.MyVehicleResponse.Item.prototype.getLocation = function() {
  return /** @type{?proto.api.Point} */ (
    jspb.Message.getWrapperField(this, geo_pb.Point, 6));
};


/**
 * @param {?proto.api.Point|undefined} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
*/
proto.api.MyVehicleResponse.Item.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MyVehicleResponse.Item.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string owner_org_name = 7;
 * @return {string}
 */
proto.api.MyVehicleResponse.Item.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string battery_level = 8;
 * @return {string}
 */
proto.api.MyVehicleResponse.Item.prototype.getBatteryLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setBatteryLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string power_type = 9;
 * @return {string}
 */
proto.api.MyVehicleResponse.Item.prototype.getPowerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setPowerType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 total_mileage = 10;
 * @return {number}
 */
proto.api.MyVehicleResponse.Item.prototype.getTotalMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.MyVehicleResponse.Item} returns this
 */
proto.api.MyVehicleResponse.Item.prototype.setTotalMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated Item vehicles = 1;
 * @return {!Array<!proto.api.MyVehicleResponse.Item>}
 */
proto.api.MyVehicleResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.api.MyVehicleResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.MyVehicleResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.MyVehicleResponse.Item>} value
 * @return {!proto.api.MyVehicleResponse} returns this
*/
proto.api.MyVehicleResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.MyVehicleResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.MyVehicleResponse.Item}
 */
proto.api.MyVehicleResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.MyVehicleResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.MyVehicleResponse} returns this
 */
proto.api.MyVehicleResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ByIotIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ByIotIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ByIotIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ByIotIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    iotId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ByIotIdRequest}
 */
proto.api.ByIotIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ByIotIdRequest;
  return proto.api.ByIotIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ByIotIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ByIotIdRequest}
 */
proto.api.ByIotIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIotId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ByIotIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ByIotIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ByIotIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ByIotIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIotId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string iot_id = 1;
 * @return {string}
 */
proto.api.ByIotIdRequest.prototype.getIotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ByIotIdRequest} returns this
 */
proto.api.ByIotIdRequest.prototype.setIotId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleCheckoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleCheckoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleCheckoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleCheckoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleCheckoutRequest}
 */
proto.api.VehicleCheckoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleCheckoutRequest;
  return proto.api.VehicleCheckoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleCheckoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleCheckoutRequest}
 */
proto.api.VehicleCheckoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleCheckoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleCheckoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleCheckoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleCheckoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.VehicleCheckoutRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleCheckoutRequest} returns this
 */
proto.api.VehicleCheckoutRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assigned_to = 2;
 * @return {string}
 */
proto.api.VehicleCheckoutRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleCheckoutRequest} returns this
 */
proto.api.VehicleCheckoutRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleCheckoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleCheckoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleCheckoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleCheckoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccessful: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleCheckoutResponse}
 */
proto.api.VehicleCheckoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleCheckoutResponse;
  return proto.api.VehicleCheckoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleCheckoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleCheckoutResponse}
 */
proto.api.VehicleCheckoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccessful(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleCheckoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleCheckoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleCheckoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleCheckoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccessful();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_successful = 1;
 * @return {boolean}
 */
proto.api.VehicleCheckoutResponse.prototype.getIsSuccessful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.VehicleCheckoutResponse} returns this
 */
proto.api.VehicleCheckoutResponse.prototype.setIsSuccessful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.api.VehicleCheckoutResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleCheckoutResponse} returns this
 */
proto.api.VehicleCheckoutResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleReturnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleReturnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleReturnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleReturnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleReturnRequest}
 */
proto.api.VehicleReturnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleReturnRequest;
  return proto.api.VehicleReturnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleReturnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleReturnRequest}
 */
proto.api.VehicleReturnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleReturnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleReturnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleReturnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleReturnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.VehicleReturnRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleReturnRequest} returns this
 */
proto.api.VehicleReturnRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assigned_to = 2;
 * @return {string}
 */
proto.api.VehicleReturnRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleReturnRequest} returns this
 */
proto.api.VehicleReturnRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleReturnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleReturnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleReturnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleReturnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccessful: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleReturnResponse}
 */
proto.api.VehicleReturnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleReturnResponse;
  return proto.api.VehicleReturnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleReturnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleReturnResponse}
 */
proto.api.VehicleReturnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccessful(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleReturnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleReturnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleReturnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleReturnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccessful();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_successful = 1;
 * @return {boolean}
 */
proto.api.VehicleReturnResponse.prototype.getIsSuccessful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.VehicleReturnResponse} returns this
 */
proto.api.VehicleReturnResponse.prototype.setIsSuccessful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.api.VehicleReturnResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleReturnResponse} returns this
 */
proto.api.VehicleReturnResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.VehicleItem.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deviceIotId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    addedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    clientOrgId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    assignmentList: jspb.Message.toObjectList(msg.getAssignmentList(),
    assignment_management_service_pb.AssignmentItem.toObject, includeInstance),
    ustatus: jspb.Message.getFieldWithDefault(msg, 15, ""),
    statusAddedAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    mileage: jspb.Message.getFieldWithDefault(msg, 18, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 19, ""),
    registration: jspb.Message.getFieldWithDefault(msg, 20, ""),
    vehicleImageLink: jspb.Message.getFieldWithDefault(msg, 21, ""),
    vehicleServiceStatus: jspb.Message.getFieldWithDefault(msg, 22, 0),
    checkedOutBy: jspb.Message.getFieldWithDefault(msg, 23, ""),
    vehicleFunctionalStatus: jspb.Message.getFieldWithDefault(msg, 24, ""),
    checkedOutByName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    isRidCreated: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleItem}
 */
proto.api.VehicleItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleItem;
  return proto.api.VehicleItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleItem}
 */
proto.api.VehicleItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceIotId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddedAt(value);
      break;
    case 10:
      var value = /** @type {!proto.api.VehicleStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrgId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 14:
      var value = new assignment_management_service_pb.AssignmentItem;
      reader.readMessage(value,assignment_management_service_pb.AssignmentItem.deserializeBinaryFromReader);
      msg.addAssignment(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUstatus(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusAddedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMileage(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistration(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleImageLink(value);
      break;
    case 22:
      var value = /** @type {!proto.api.VehicleServiceStatus.Enum} */ (reader.readEnum());
      msg.setVehicleServiceStatus(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckedOutBy(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleFunctionalStatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckedOutByName(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRidCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeviceIotId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getClientOrgId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      assignment_management_service_pb.AssignmentItem.serializeBinaryToWriter
    );
  }
  f = message.getUstatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatusAddedAt();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getZoneId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMileage();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRegistration();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getVehicleImageLink();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getVehicleServiceStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getCheckedOutBy();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getVehicleFunctionalStatus();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCheckedOutByName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getIsRidCreated();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_org_id = 2;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string license_plate = 4;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string business_id = 6;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setBusinessId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string device_iot_id = 8;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getDeviceIotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setDeviceIotId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 added_at = 9;
 * @return {number}
 */
proto.api.VehicleItem.prototype.getAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setAddedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional VehicleStatus.Enum status = 10;
 * @return {!proto.api.VehicleStatus.Enum}
 */
proto.api.VehicleItem.prototype.getStatus = function() {
  return /** @type {!proto.api.VehicleStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.api.VehicleStatus.Enum} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string assigned_to = 11;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string client_org_id = 12;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getClientOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setClientOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_name = 13;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated AssignmentItem assignment = 14;
 * @return {!Array<!proto.api.AssignmentItem>}
 */
proto.api.VehicleItem.prototype.getAssignmentList = function() {
  return /** @type{!Array<!proto.api.AssignmentItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, assignment_management_service_pb.AssignmentItem, 14));
};


/**
 * @param {!Array<!proto.api.AssignmentItem>} value
 * @return {!proto.api.VehicleItem} returns this
*/
proto.api.VehicleItem.prototype.setAssignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.api.AssignmentItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AssignmentItem}
 */
proto.api.VehicleItem.prototype.addAssignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.api.AssignmentItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.clearAssignmentList = function() {
  return this.setAssignmentList([]);
};


/**
 * optional string uStatus = 15;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getUstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setUstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 status_added_at = 16;
 * @return {number}
 */
proto.api.VehicleItem.prototype.getStatusAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setStatusAddedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string zone_id = 17;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string mileage = 18;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getMileage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setMileage = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string transmission = 19;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string registration = 20;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getRegistration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setRegistration = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string vehicle_image_link = 21;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getVehicleImageLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setVehicleImageLink = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional VehicleServiceStatus.Enum vehicle_service_status = 22;
 * @return {!proto.api.VehicleServiceStatus.Enum}
 */
proto.api.VehicleItem.prototype.getVehicleServiceStatus = function() {
  return /** @type {!proto.api.VehicleServiceStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.api.VehicleServiceStatus.Enum} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setVehicleServiceStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string checked_out_by = 23;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getCheckedOutBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setCheckedOutBy = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string vehicle_functional_status = 24;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getVehicleFunctionalStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setVehicleFunctionalStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string checked_out_by_name = 25;
 * @return {string}
 */
proto.api.VehicleItem.prototype.getCheckedOutByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setCheckedOutByName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool is_rid_created = 26;
 * @return {boolean}
 */
proto.api.VehicleItem.prototype.getIsRidCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.VehicleItem} returns this
 */
proto.api.VehicleItem.prototype.setIsRidCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleStatus}
 */
proto.api.VehicleStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleStatus;
  return proto.api.VehicleStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleStatus}
 */
proto.api.VehicleStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.VehicleStatus.Enum = {
  UNKNOWN: 0,
  RIDE_IN_PROGRESS: 1,
  IN_MAINTENANCE: 2,
  MARKED_BROKEN: 3,
  IDLE: 4,
  RETIRED: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleServiceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleServiceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleServiceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleServiceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleServiceStatus}
 */
proto.api.VehicleServiceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleServiceStatus;
  return proto.api.VehicleServiceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleServiceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleServiceStatus}
 */
proto.api.VehicleServiceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleServiceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleServiceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleServiceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleServiceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.VehicleServiceStatus.Enum = {
  UNKNOWN: 0,
  OPERATIONAL: 1,
  IN_MAINTENANCE: 2,
  MARKED_BROKEN: 3,
  RETIRED: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleUtilizeStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleUtilizeStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleUtilizeStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleUtilizeStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleUtilizeStatus}
 */
proto.api.VehicleUtilizeStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleUtilizeStatus;
  return proto.api.VehicleUtilizeStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleUtilizeStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleUtilizeStatus}
 */
proto.api.VehicleUtilizeStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleUtilizeStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleUtilizeStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleUtilizeStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleUtilizeStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.VehicleUtilizeStatus.Enum = {
  CHECKED_OUT: 0,
  IN_THE_LOT: 1,
  ACTIVELY_OPERATING: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddVehicleResponse}
 */
proto.api.AddVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddVehicleResponse;
  return proto.api.AddVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddVehicleResponse}
 */
proto.api.AddVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.AddVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.AddVehicleResponse} returns this
*/
proto.api.AddVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddVehicleResponse} returns this
 */
proto.api.AddVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateVehicleResponse}
 */
proto.api.UpdateVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateVehicleResponse;
  return proto.api.UpdateVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateVehicleResponse}
 */
proto.api.UpdateVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.UpdateVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.UpdateVehicleResponse} returns this
*/
proto.api.UpdateVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateVehicleResponse} returns this
 */
proto.api.UpdateVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.GetVehicleResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleResponse}
 */
proto.api.GetVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleResponse;
  return proto.api.GetVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleResponse}
 */
proto.api.GetVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetVehicleResponse.Item;
      reader.readMessage(value,proto.api.GetVehicleResponse.Item.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetVehicleResponse.Item.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetVehicleResponse.Item.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assignedToUserDetails: (f = msg.getAssignedToUserDetails()) && proto.api.UserDetailsItem.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && geo_pb.Point.toObject(includeInstance, f),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentlyAssignedToList: jspb.Message.toObjectList(msg.getCurrentlyAssignedToList(),
    proto.api.UserDetailsItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleResponse.Item}
 */
proto.api.GetVehicleResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleResponse.Item;
  return proto.api.GetVehicleResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleResponse.Item}
 */
proto.api.GetVehicleResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 5:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.setAssignedToUserDetails(value);
      break;
    case 6:
      var value = new geo_pb.Point;
      reader.readMessage(value,geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    case 8:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.addCurrentlyAssignedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAssignedToUserDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentlyAssignedToList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem basic = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.GetVehicleResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
*/
proto.api.GetVehicleResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.GetVehicleResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.GetVehicleResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.GetVehicleResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserDetailsItem assigned_to_user_details = 5;
 * @return {?proto.api.UserDetailsItem}
 */
proto.api.GetVehicleResponse.Item.prototype.getAssignedToUserDetails = function() {
  return /** @type{?proto.api.UserDetailsItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDetailsItem, 5));
};


/**
 * @param {?proto.api.UserDetailsItem|undefined} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
*/
proto.api.GetVehicleResponse.Item.prototype.setAssignedToUserDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.clearAssignedToUserDetails = function() {
  return this.setAssignedToUserDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleResponse.Item.prototype.hasAssignedToUserDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point location = 6;
 * @return {?proto.api.Point}
 */
proto.api.GetVehicleResponse.Item.prototype.getLocation = function() {
  return /** @type{?proto.api.Point} */ (
    jspb.Message.getWrapperField(this, geo_pb.Point, 6));
};


/**
 * @param {?proto.api.Point|undefined} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
*/
proto.api.GetVehicleResponse.Item.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleResponse.Item.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string owner_org_name = 7;
 * @return {string}
 */
proto.api.GetVehicleResponse.Item.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UserDetailsItem currently_assigned_to = 8;
 * @return {!Array<!proto.api.UserDetailsItem>}
 */
proto.api.GetVehicleResponse.Item.prototype.getCurrentlyAssignedToList = function() {
  return /** @type{!Array<!proto.api.UserDetailsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UserDetailsItem, 8));
};


/**
 * @param {!Array<!proto.api.UserDetailsItem>} value
 * @return {!proto.api.GetVehicleResponse.Item} returns this
*/
proto.api.GetVehicleResponse.Item.prototype.setCurrentlyAssignedToList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.UserDetailsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UserDetailsItem}
 */
proto.api.GetVehicleResponse.Item.prototype.addCurrentlyAssignedTo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.UserDetailsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetVehicleResponse.Item} returns this
 */
proto.api.GetVehicleResponse.Item.prototype.clearCurrentlyAssignedToList = function() {
  return this.setCurrentlyAssignedToList([]);
};


/**
 * optional Item vehicle = 1;
 * @return {?proto.api.GetVehicleResponse.Item}
 */
proto.api.GetVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.GetVehicleResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetVehicleResponse.Item, 1));
};


/**
 * @param {?proto.api.GetVehicleResponse.Item|undefined} value
 * @return {!proto.api.GetVehicleResponse} returns this
*/
proto.api.GetVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleResponse} returns this
 */
proto.api.GetVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.GetVehicleManagementResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleManagementResponse}
 */
proto.api.GetVehicleManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleManagementResponse;
  return proto.api.GetVehicleManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleManagementResponse}
 */
proto.api.GetVehicleManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetVehicleManagementResponse.Item;
      reader.readMessage(value,proto.api.GetVehicleManagementResponse.Item.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetVehicleManagementResponse.Item.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetVehicleManagementResponse.Item.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assignedToUserDetails: (f = msg.getAssignedToUserDetails()) && proto.api.UserDetailsItem.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && geo_pb.Point.toObject(includeInstance, f),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentlyAssignedToList: jspb.Message.toObjectList(msg.getCurrentlyAssignedToList(),
    proto.api.UserDetailsItem.toObject, includeInstance),
    updatedAtLocation: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleManagementResponse.Item}
 */
proto.api.GetVehicleManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleManagementResponse.Item;
  return proto.api.GetVehicleManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleManagementResponse.Item}
 */
proto.api.GetVehicleManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 5:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.setAssignedToUserDetails(value);
      break;
    case 6:
      var value = new geo_pb.Point;
      reader.readMessage(value,geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    case 8:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.addCurrentlyAssignedTo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAtLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAssignedToUserDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentlyAssignedToList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAtLocation();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional VehicleItem basic = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
*/
proto.api.GetVehicleManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserDetailsItem assigned_to_user_details = 5;
 * @return {?proto.api.UserDetailsItem}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getAssignedToUserDetails = function() {
  return /** @type{?proto.api.UserDetailsItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDetailsItem, 5));
};


/**
 * @param {?proto.api.UserDetailsItem|undefined} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
*/
proto.api.GetVehicleManagementResponse.Item.prototype.setAssignedToUserDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.clearAssignedToUserDetails = function() {
  return this.setAssignedToUserDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.hasAssignedToUserDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point location = 6;
 * @return {?proto.api.Point}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getLocation = function() {
  return /** @type{?proto.api.Point} */ (
    jspb.Message.getWrapperField(this, geo_pb.Point, 6));
};


/**
 * @param {?proto.api.Point|undefined} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
*/
proto.api.GetVehicleManagementResponse.Item.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string owner_org_name = 7;
 * @return {string}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UserDetailsItem currently_assigned_to = 8;
 * @return {!Array<!proto.api.UserDetailsItem>}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getCurrentlyAssignedToList = function() {
  return /** @type{!Array<!proto.api.UserDetailsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UserDetailsItem, 8));
};


/**
 * @param {!Array<!proto.api.UserDetailsItem>} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
*/
proto.api.GetVehicleManagementResponse.Item.prototype.setCurrentlyAssignedToList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.UserDetailsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UserDetailsItem}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.addCurrentlyAssignedTo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.UserDetailsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.clearCurrentlyAssignedToList = function() {
  return this.setCurrentlyAssignedToList([]);
};


/**
 * optional int64 updated_at_location = 9;
 * @return {number}
 */
proto.api.GetVehicleManagementResponse.Item.prototype.getUpdatedAtLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetVehicleManagementResponse.Item} returns this
 */
proto.api.GetVehicleManagementResponse.Item.prototype.setUpdatedAtLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional Item vehicle = 1;
 * @return {?proto.api.GetVehicleManagementResponse.Item}
 */
proto.api.GetVehicleManagementResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.GetVehicleManagementResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetVehicleManagementResponse.Item, 1));
};


/**
 * @param {?proto.api.GetVehicleManagementResponse.Item|undefined} value
 * @return {!proto.api.GetVehicleManagementResponse} returns this
*/
proto.api.GetVehicleManagementResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleManagementResponse} returns this
 */
proto.api.GetVehicleManagementResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleManagementResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.GetVehicleAdminResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleAdminResponse}
 */
proto.api.GetVehicleAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleAdminResponse;
  return proto.api.GetVehicleAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleAdminResponse}
 */
proto.api.GetVehicleAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetVehicleAdminResponse.Item;
      reader.readMessage(value,proto.api.GetVehicleAdminResponse.Item.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetVehicleAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.VehicleItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleAdminResponse.Item}
 */
proto.api.GetVehicleAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleAdminResponse.Item;
  return proto.api.GetVehicleAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleAdminResponse.Item}
 */
proto.api.GetVehicleAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem basic = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.GetVehicleAdminResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.GetVehicleAdminResponse.Item} returns this
*/
proto.api.GetVehicleAdminResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleAdminResponse.Item} returns this
 */
proto.api.GetVehicleAdminResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleAdminResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Item vehicle = 1;
 * @return {?proto.api.GetVehicleAdminResponse.Item}
 */
proto.api.GetVehicleAdminResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.GetVehicleAdminResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetVehicleAdminResponse.Item, 1));
};


/**
 * @param {?proto.api.GetVehicleAdminResponse.Item|undefined} value
 * @return {!proto.api.GetVehicleAdminResponse} returns this
*/
proto.api.GetVehicleAdminResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetVehicleAdminResponse} returns this
 */
proto.api.GetVehicleAdminResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetVehicleAdminResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleRequest}
 */
proto.api.GetVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleRequest;
  return proto.api.GetVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleRequest}
 */
proto.api.GetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetVehicleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleRequest} returns this
 */
proto.api.GetVehicleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleManagementRequest}
 */
proto.api.GetVehicleManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleManagementRequest;
  return proto.api.GetVehicleManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleManagementRequest}
 */
proto.api.GetVehicleManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetVehicleManagementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleManagementRequest} returns this
 */
proto.api.GetVehicleManagementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleAdminRequest}
 */
proto.api.GetVehicleAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleAdminRequest;
  return proto.api.GetVehicleAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleAdminRequest}
 */
proto.api.GetVehicleAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetVehicleAdminRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleAdminRequest} returns this
 */
proto.api.GetVehicleAdminRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.UpdateVehicleItem.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateVehicleItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateVehicleItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateVehicleItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceIotId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentlyAssignedTo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    currentlyAssignedToArrayList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    locationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    utilizeStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 11, ""),
    registration: jspb.Message.getFieldWithDefault(msg, 12, ""),
    vehicleServiceStatus: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateVehicleItem}
 */
proto.api.UpdateVehicleItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateVehicleItem;
  return proto.api.UpdateVehicleItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateVehicleItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateVehicleItem}
 */
proto.api.UpdateVehicleItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceIotId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentlyAssignedTo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrentlyAssignedToArray(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 9:
      var value = /** @type {!proto.api.VehicleUtilizeStatus.Enum} */ (reader.readEnum());
      msg.setUtilizeStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistration(value);
      break;
    case 13:
      var value = /** @type {!proto.api.VehicleServiceStatus.Enum} */ (reader.readEnum());
      msg.setVehicleServiceStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateVehicleItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateVehicleItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateVehicleItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceIotId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentlyAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrentlyAssignedToArrayList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUtilizeStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getZoneId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRegistration();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVehicleServiceStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string license_plate = 2;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_id = 3;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_id = 4;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setBusinessId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_iot_id = 5;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getDeviceIotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setDeviceIotId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currently_assigned_to = 6;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getCurrentlyAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setCurrentlyAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string currently_assigned_to_array = 7;
 * @return {!Array<string>}
 */
proto.api.UpdateVehicleItem.prototype.getCurrentlyAssignedToArrayList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setCurrentlyAssignedToArrayList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.addCurrentlyAssignedToArray = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.clearCurrentlyAssignedToArrayList = function() {
  return this.setCurrentlyAssignedToArrayList([]);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional VehicleUtilizeStatus.Enum utilize_status = 9;
 * @return {!proto.api.VehicleUtilizeStatus.Enum}
 */
proto.api.UpdateVehicleItem.prototype.getUtilizeStatus = function() {
  return /** @type {!proto.api.VehicleUtilizeStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.api.VehicleUtilizeStatus.Enum} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setUtilizeStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string zone_id = 10;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string transmission = 11;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string registration = 12;
 * @return {string}
 */
proto.api.UpdateVehicleItem.prototype.getRegistration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setRegistration = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional VehicleServiceStatus.Enum vehicle_service_status = 13;
 * @return {!proto.api.VehicleServiceStatus.Enum}
 */
proto.api.UpdateVehicleItem.prototype.getVehicleServiceStatus = function() {
  return /** @type {!proto.api.VehicleServiceStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.api.VehicleServiceStatus.Enum} value
 * @return {!proto.api.UpdateVehicleItem} returns this
 */
proto.api.UpdateVehicleItem.prototype.setVehicleServiceStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.UpdateVehicleItem.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientOrgId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateVehicleRequest}
 */
proto.api.UpdateVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateVehicleRequest;
  return proto.api.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateVehicleRequest}
 */
proto.api.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UpdateVehicleItem;
      reader.readMessage(value,proto.api.UpdateVehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UpdateVehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientOrgId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional UpdateVehicleItem vehicle = 1;
 * @return {?proto.api.UpdateVehicleItem}
 */
proto.api.UpdateVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.api.UpdateVehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateVehicleItem, 1));
};


/**
 * @param {?proto.api.UpdateVehicleItem|undefined} value
 * @return {!proto.api.UpdateVehicleRequest} returns this
*/
proto.api.UpdateVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateVehicleRequest} returns this
 */
proto.api.UpdateVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.api.UpdateVehicleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleRequest} returns this
 */
proto.api.UpdateVehicleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_org_id = 3;
 * @return {string}
 */
proto.api.UpdateVehicleRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleRequest} returns this
 */
proto.api.UpdateVehicleRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string client_org_id = 4;
 * @return {string}
 */
proto.api.UpdateVehicleRequest.prototype.getClientOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleRequest} returns this
 */
proto.api.UpdateVehicleRequest.prototype.setClientOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.UpdateVehicleItem.toObject(includeInstance, f),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientOrgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assignmentStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddVehicleRequest}
 */
proto.api.AddVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddVehicleRequest;
  return proto.api.AddVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddVehicleRequest}
 */
proto.api.AddVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UpdateVehicleItem;
      reader.readMessage(value,proto.api.UpdateVehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrgId(value);
      break;
    case 4:
      var value = /** @type {!proto.api.AssignmentStatus} */ (reader.readEnum());
      msg.setAssignmentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UpdateVehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssignmentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional UpdateVehicleItem vehicle = 1;
 * @return {?proto.api.UpdateVehicleItem}
 */
proto.api.AddVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.api.UpdateVehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateVehicleItem, 1));
};


/**
 * @param {?proto.api.UpdateVehicleItem|undefined} value
 * @return {!proto.api.AddVehicleRequest} returns this
*/
proto.api.AddVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddVehicleRequest} returns this
 */
proto.api.AddVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string owner_org_id = 2;
 * @return {string}
 */
proto.api.AddVehicleRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleRequest} returns this
 */
proto.api.AddVehicleRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_org_id = 3;
 * @return {string}
 */
proto.api.AddVehicleRequest.prototype.getClientOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleRequest} returns this
 */
proto.api.AddVehicleRequest.prototype.setClientOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AssignmentStatus assignment_status = 4;
 * @return {!proto.api.AssignmentStatus}
 */
proto.api.AddVehicleRequest.prototype.getAssignmentStatus = function() {
  return /** @type {!proto.api.AssignmentStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.AssignmentStatus} value
 * @return {!proto.api.AddVehicleRequest} returns this
 */
proto.api.AddVehicleRequest.prototype.setAssignmentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteVehicleAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteVehicleAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteVehicleAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteVehicleAdminResponse}
 */
proto.api.DeleteVehicleAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteVehicleAdminResponse;
  return proto.api.DeleteVehicleAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteVehicleAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteVehicleAdminResponse}
 */
proto.api.DeleteVehicleAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteVehicleAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteVehicleAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteVehicleAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ModelItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ModelItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ModelItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ModelItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    make: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, 0),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    powerType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ModelItem}
 */
proto.api.ModelItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ModelItem;
  return proto.api.ModelItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ModelItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ModelItem}
 */
proto.api.ModelItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 6:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ModelItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ModelItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ModelItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ModelItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPowerType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.ModelItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.ModelItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_org_id = 3;
 * @return {string}
 */
proto.api.ModelItem.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string make = 4;
 * @return {string}
 */
proto.api.ModelItem.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 year = 5;
 * @return {number}
 */
proto.api.ModelItem.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Struct properties = 6;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.ModelItem.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 6));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.ModelItem} returns this
*/
proto.api.ModelItem.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ModelItem.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string created_by = 7;
 * @return {string}
 */
proto.api.ModelItem.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 added_at = 8;
 * @return {number}
 */
proto.api.ModelItem.prototype.getAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setAddedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string power_type = 9;
 * @return {string}
 */
proto.api.ModelItem.prototype.getPowerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setPowerType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string transmission = 10;
 * @return {string}
 */
proto.api.ModelItem.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelItem} returns this
 */
proto.api.ModelItem.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ModelUpdateItem.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ModelUpdateItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ModelUpdateItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ModelUpdateItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ModelUpdateItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    make: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    modelImagesList: jspb.Message.toObjectList(msg.getModelImagesList(),
    proto.api.VehicleModelFileItem.toObject, includeInstance),
    transmission: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ModelUpdateItem}
 */
proto.api.ModelUpdateItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ModelUpdateItem;
  return proto.api.ModelUpdateItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ModelUpdateItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ModelUpdateItem}
 */
proto.api.ModelUpdateItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 4:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 5:
      var value = new proto.api.VehicleModelFileItem;
      reader.readMessage(value,proto.api.VehicleModelFileItem.deserializeBinaryFromReader);
      msg.addModelImages(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ModelUpdateItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ModelUpdateItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ModelUpdateItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ModelUpdateItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getModelImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.VehicleModelFileItem.serializeBinaryToWriter
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.ModelUpdateItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string make = 2;
 * @return {string}
 */
proto.api.ModelUpdateItem.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 year = 3;
 * @return {number}
 */
proto.api.ModelUpdateItem.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Struct properties = 4;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.ModelUpdateItem.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.ModelUpdateItem} returns this
*/
proto.api.ModelUpdateItem.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ModelUpdateItem.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated VehicleModelFileItem model_images = 5;
 * @return {!Array<!proto.api.VehicleModelFileItem>}
 */
proto.api.ModelUpdateItem.prototype.getModelImagesList = function() {
  return /** @type{!Array<!proto.api.VehicleModelFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.VehicleModelFileItem, 5));
};


/**
 * @param {!Array<!proto.api.VehicleModelFileItem>} value
 * @return {!proto.api.ModelUpdateItem} returns this
*/
proto.api.ModelUpdateItem.prototype.setModelImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.VehicleModelFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.VehicleModelFileItem}
 */
proto.api.ModelUpdateItem.prototype.addModelImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.VehicleModelFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.clearModelImagesList = function() {
  return this.setModelImagesList([]);
};


/**
 * optional string transmission = 6;
 * @return {string}
 */
proto.api.ModelUpdateItem.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ModelUpdateItem} returns this
 */
proto.api.ModelUpdateItem.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddModelAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddModelAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddModelAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelUpdateItem.toObject(includeInstance, f),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddModelAdminRequest}
 */
proto.api.AddModelAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddModelAdminRequest;
  return proto.api.AddModelAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddModelAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddModelAdminRequest}
 */
proto.api.AddModelAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelUpdateItem;
      reader.readMessage(value,proto.api.ModelUpdateItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddModelAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddModelAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddModelAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelUpdateItem.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ModelUpdateItem model = 1;
 * @return {?proto.api.ModelUpdateItem}
 */
proto.api.AddModelAdminRequest.prototype.getModel = function() {
  return /** @type{?proto.api.ModelUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelUpdateItem, 1));
};


/**
 * @param {?proto.api.ModelUpdateItem|undefined} value
 * @return {!proto.api.AddModelAdminRequest} returns this
*/
proto.api.AddModelAdminRequest.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddModelAdminRequest} returns this
 */
proto.api.AddModelAdminRequest.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddModelAdminRequest.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string owner_org_id = 2;
 * @return {string}
 */
proto.api.AddModelAdminRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddModelAdminRequest} returns this
 */
proto.api.AddModelAdminRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddModelAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddModelAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddModelAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddModelAdminResponse}
 */
proto.api.AddModelAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddModelAdminResponse;
  return proto.api.AddModelAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddModelAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddModelAdminResponse}
 */
proto.api.AddModelAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddModelAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddModelAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddModelAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem model = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.AddModelAdminResponse.prototype.getModel = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.AddModelAdminResponse} returns this
*/
proto.api.AddModelAdminResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddModelAdminResponse} returns this
 */
proto.api.AddModelAdminResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddModelAdminResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VehicleModelFileItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VehicleModelFileItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VehicleModelFileItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleModelFileItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    fileExt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VehicleModelFileItem}
 */
proto.api.VehicleModelFileItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VehicleModelFileItem;
  return proto.api.VehicleModelFileItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VehicleModelFileItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VehicleModelFileItem}
 */
proto.api.VehicleModelFileItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileExt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VehicleModelFileItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VehicleModelFileItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VehicleModelFileItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VehicleModelFileItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileExt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.api.VehicleModelFileItem.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.api.VehicleModelFileItem.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.api.VehicleModelFileItem.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.api.VehicleModelFileItem} returns this
 */
proto.api.VehicleModelFileItem.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_ext = 2;
 * @return {string}
 */
proto.api.VehicleModelFileItem.prototype.getFileExt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleModelFileItem} returns this
 */
proto.api.VehicleModelFileItem.prototype.setFileExt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 13;
 * @return {string}
 */
proto.api.VehicleModelFileItem.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.VehicleModelFileItem} returns this
 */
proto.api.VehicleModelFileItem.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddModelManagmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddModelManagmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddModelManagmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelManagmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelUpdateItem.toObject(includeInstance, f),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddModelManagmentRequest}
 */
proto.api.AddModelManagmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddModelManagmentRequest;
  return proto.api.AddModelManagmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddModelManagmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddModelManagmentRequest}
 */
proto.api.AddModelManagmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelUpdateItem;
      reader.readMessage(value,proto.api.ModelUpdateItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddModelManagmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddModelManagmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddModelManagmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelManagmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelUpdateItem.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ModelUpdateItem model = 1;
 * @return {?proto.api.ModelUpdateItem}
 */
proto.api.AddModelManagmentRequest.prototype.getModel = function() {
  return /** @type{?proto.api.ModelUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelUpdateItem, 1));
};


/**
 * @param {?proto.api.ModelUpdateItem|undefined} value
 * @return {!proto.api.AddModelManagmentRequest} returns this
*/
proto.api.AddModelManagmentRequest.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddModelManagmentRequest} returns this
 */
proto.api.AddModelManagmentRequest.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddModelManagmentRequest.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string owner_org_id = 2;
 * @return {string}
 */
proto.api.AddModelManagmentRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddModelManagmentRequest} returns this
 */
proto.api.AddModelManagmentRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddModelManagmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddModelManagmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddModelManagmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelManagmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddModelManagmentResponse}
 */
proto.api.AddModelManagmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddModelManagmentResponse;
  return proto.api.AddModelManagmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddModelManagmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddModelManagmentResponse}
 */
proto.api.AddModelManagmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddModelManagmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddModelManagmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddModelManagmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddModelManagmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem model = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.AddModelManagmentResponse.prototype.getModel = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.AddModelManagmentResponse} returns this
*/
proto.api.AddModelManagmentResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddModelManagmentResponse} returns this
 */
proto.api.AddModelManagmentResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddModelManagmentResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    model: (f = msg.getModel()) && proto.api.ModelUpdateItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateModelRequest}
 */
proto.api.UpdateModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateModelRequest;
  return proto.api.UpdateModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateModelRequest}
 */
proto.api.UpdateModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.ModelUpdateItem;
      reader.readMessage(value,proto.api.ModelUpdateItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.ModelUpdateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UpdateModelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateModelRequest} returns this
 */
proto.api.UpdateModelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ModelUpdateItem model = 2;
 * @return {?proto.api.ModelUpdateItem}
 */
proto.api.UpdateModelRequest.prototype.getModel = function() {
  return /** @type{?proto.api.ModelUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelUpdateItem, 2));
};


/**
 * @param {?proto.api.ModelUpdateItem|undefined} value
 * @return {!proto.api.UpdateModelRequest} returns this
*/
proto.api.UpdateModelRequest.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateModelRequest} returns this
 */
proto.api.UpdateModelRequest.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateModelRequest.prototype.hasModel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateModelResponse}
 */
proto.api.UpdateModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateModelResponse;
  return proto.api.UpdateModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateModelResponse}
 */
proto.api.UpdateModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem model = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.UpdateModelResponse.prototype.getModel = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.UpdateModelResponse} returns this
*/
proto.api.UpdateModelResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateModelResponse} returns this
 */
proto.api.UpdateModelResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateModelResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteModelRequest}
 */
proto.api.DeleteModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteModelRequest;
  return proto.api.DeleteModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteModelRequest}
 */
proto.api.DeleteModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteModelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteModelRequest} returns this
 */
proto.api.DeleteModelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteModelResponse}
 */
proto.api.DeleteModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteModelResponse;
  return proto.api.DeleteModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteModelResponse}
 */
proto.api.DeleteModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelRequest}
 */
proto.api.GetModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelRequest;
  return proto.api.GetModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelRequest}
 */
proto.api.GetModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetModelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetModelRequest} returns this
 */
proto.api.GetModelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelManagementRequest}
 */
proto.api.GetModelManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelManagementRequest;
  return proto.api.GetModelManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelManagementRequest}
 */
proto.api.GetModelManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetModelManagementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetModelManagementRequest} returns this
 */
proto.api.GetModelManagementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelAdminRequest}
 */
proto.api.GetModelAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelAdminRequest;
  return proto.api.GetModelAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelAdminRequest}
 */
proto.api.GetModelAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetModelAdminRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetModelAdminRequest} returns this
 */
proto.api.GetModelAdminRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelResponse}
 */
proto.api.GetModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelResponse;
  return proto.api.GetModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelResponse}
 */
proto.api.GetModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem model = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.GetModelResponse.prototype.getModel = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.GetModelResponse} returns this
*/
proto.api.GetModelResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetModelResponse} returns this
 */
proto.api.GetModelResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetModelResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.GetModelManagementResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelManagementResponse}
 */
proto.api.GetModelManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelManagementResponse;
  return proto.api.GetModelManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelManagementResponse}
 */
proto.api.GetModelManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetModelManagementResponse.Item;
      reader.readMessage(value,proto.api.GetModelManagementResponse.Item.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetModelManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelManagementResponse.Item}
 */
proto.api.GetModelManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelManagementResponse.Item;
  return proto.api.GetModelManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelManagementResponse.Item}
 */
proto.api.GetModelManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem basic = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.GetModelManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.GetModelManagementResponse.Item} returns this
*/
proto.api.GetModelManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetModelManagementResponse.Item} returns this
 */
proto.api.GetModelManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetModelManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Item model = 1;
 * @return {?proto.api.GetModelManagementResponse.Item}
 */
proto.api.GetModelManagementResponse.prototype.getModel = function() {
  return /** @type{?proto.api.GetModelManagementResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetModelManagementResponse.Item, 1));
};


/**
 * @param {?proto.api.GetModelManagementResponse.Item|undefined} value
 * @return {!proto.api.GetModelManagementResponse} returns this
*/
proto.api.GetModelManagementResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetModelManagementResponse} returns this
 */
proto.api.GetModelManagementResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetModelManagementResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.api.GetModelAdminResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelAdminResponse}
 */
proto.api.GetModelAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelAdminResponse;
  return proto.api.GetModelAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelAdminResponse}
 */
proto.api.GetModelAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetModelAdminResponse.Item;
      reader.readMessage(value,proto.api.GetModelAdminResponse.Item.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetModelAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetModelAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetModelAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetModelAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetModelAdminResponse.Item}
 */
proto.api.GetModelAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetModelAdminResponse.Item;
  return proto.api.GetModelAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetModelAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetModelAdminResponse.Item}
 */
proto.api.GetModelAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetModelAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetModelAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetModelAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetModelAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem basic = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.GetModelAdminResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.GetModelAdminResponse.Item} returns this
*/
proto.api.GetModelAdminResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetModelAdminResponse.Item} returns this
 */
proto.api.GetModelAdminResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetModelAdminResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Item model = 1;
 * @return {?proto.api.GetModelAdminResponse.Item}
 */
proto.api.GetModelAdminResponse.prototype.getModel = function() {
  return /** @type{?proto.api.GetModelAdminResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetModelAdminResponse.Item, 1));
};


/**
 * @param {?proto.api.GetModelAdminResponse.Item|undefined} value
 * @return {!proto.api.GetModelAdminResponse} returns this
*/
proto.api.GetModelAdminResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetModelAdminResponse} returns this
 */
proto.api.GetModelAdminResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetModelAdminResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsManagementRequest}
 */
proto.api.ListModelsManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsManagementRequest;
  return proto.api.ListModelsManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsManagementRequest}
 */
proto.api.ListModelsManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListModelsManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListModelsManagementRequest} returns this
 */
proto.api.ListModelsManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsAdminRequest}
 */
proto.api.ListModelsAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsAdminRequest;
  return proto.api.ListModelsAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsAdminRequest}
 */
proto.api.ListModelsAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListModelsAdminRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListModelsAdminRequest} returns this
 */
proto.api.ListModelsAdminRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListModelsManagementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.api.ListModelsManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsManagementResponse}
 */
proto.api.ListModelsManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsManagementResponse;
  return proto.api.ListModelsManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsManagementResponse}
 */
proto.api.ListModelsManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListModelsManagementResponse.Item;
      reader.readMessage(value,proto.api.ListModelsManagementResponse.Item.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListModelsManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsManagementResponse.Item}
 */
proto.api.ListModelsManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsManagementResponse.Item;
  return proto.api.ListModelsManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsManagementResponse.Item}
 */
proto.api.ListModelsManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem basic = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.ListModelsManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.ListModelsManagementResponse.Item} returns this
*/
proto.api.ListModelsManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListModelsManagementResponse.Item} returns this
 */
proto.api.ListModelsManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListModelsManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item models = 1;
 * @return {!Array<!proto.api.ListModelsManagementResponse.Item>}
 */
proto.api.ListModelsManagementResponse.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.api.ListModelsManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListModelsManagementResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListModelsManagementResponse.Item>} value
 * @return {!proto.api.ListModelsManagementResponse} returns this
*/
proto.api.ListModelsManagementResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListModelsManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListModelsManagementResponse.Item}
 */
proto.api.ListModelsManagementResponse.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListModelsManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListModelsManagementResponse} returns this
 */
proto.api.ListModelsManagementResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListModelsAdminResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.api.ListModelsAdminResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsAdminResponse}
 */
proto.api.ListModelsAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsAdminResponse;
  return proto.api.ListModelsAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsAdminResponse}
 */
proto.api.ListModelsAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListModelsAdminResponse.Item;
      reader.readMessage(value,proto.api.ListModelsAdminResponse.Item.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListModelsAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListModelsAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListModelsAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListModelsAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ModelItem.toObject(includeInstance, f),
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListModelsAdminResponse.Item}
 */
proto.api.ListModelsAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListModelsAdminResponse.Item;
  return proto.api.ListModelsAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListModelsAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListModelsAdminResponse.Item}
 */
proto.api.ListModelsAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListModelsAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListModelsAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListModelsAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListModelsAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ModelItem basic = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.ListModelsAdminResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.ListModelsAdminResponse.Item} returns this
*/
proto.api.ListModelsAdminResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListModelsAdminResponse.Item} returns this
 */
proto.api.ListModelsAdminResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListModelsAdminResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string firmware_version = 2;
 * @return {string}
 */
proto.api.ListModelsAdminResponse.Item.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListModelsAdminResponse.Item} returns this
 */
proto.api.ListModelsAdminResponse.Item.prototype.setFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Item models = 1;
 * @return {!Array<!proto.api.ListModelsAdminResponse.Item>}
 */
proto.api.ListModelsAdminResponse.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.api.ListModelsAdminResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListModelsAdminResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListModelsAdminResponse.Item>} value
 * @return {!proto.api.ListModelsAdminResponse} returns this
*/
proto.api.ListModelsAdminResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListModelsAdminResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListModelsAdminResponse.Item}
 */
proto.api.ListModelsAdminResponse.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListModelsAdminResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListModelsAdminResponse} returns this
 */
proto.api.ListModelsAdminResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && page_pb.PageRequest.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.api.ListVehiclesManagementRequest.Filter.toObject(includeInstance, f),
    isMap: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesManagementRequest}
 */
proto.api.ListVehiclesManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesManagementRequest;
  return proto.api.ListVehiclesManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesManagementRequest}
 */
proto.api.ListVehiclesManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new page_pb.PageRequest;
      reader.readMessage(value,page_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new proto.api.ListVehiclesManagementRequest.Filter;
      reader.readMessage(value,proto.api.ListVehiclesManagementRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      page_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ListVehiclesManagementRequest.Filter.serializeBinaryToWriter
    );
  }
  f = message.getIsMap();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesManagementRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesManagementRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelMake: (f = msg.getModelMake()) && filter_pb.Filters.In.toObject(includeInstance, f),
    modelName: (f = msg.getModelName()) && filter_pb.Filters.In.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && filter_pb.Filters.In.toObject(includeInstance, f),
    modelYear: (f = msg.getModelYear()) && filter_pb.Filters.In.toObject(includeInstance, f),
    clientOrgId: (f = msg.getClientOrgId()) && filter_pb.Filters.In.toObject(includeInstance, f),
    functionalStatus: (f = msg.getFunctionalStatus()) && filter_pb.Filters.In.toObject(includeInstance, f),
    searchString: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter}
 */
proto.api.ListVehiclesManagementRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesManagementRequest.Filter;
  return proto.api.ListVehiclesManagementRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesManagementRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter}
 */
proto.api.ListVehiclesManagementRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelMake(value);
      break;
    case 2:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelName(value);
      break;
    case 3:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelYear(value);
      break;
    case 5:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setClientOrgId(value);
      break;
    case 6:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setFunctionalStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesManagementRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesManagementRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelMake();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getModelYear();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getClientOrgId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getFunctionalStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional Filters.In model_make = 1;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getModelMake = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 1));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setModelMake = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearModelMake = function() {
  return this.setModelMake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasModelMake = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters.In model_name = 2;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getModelName = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 2));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setModelName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearModelName = function() {
  return this.setModelName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasModelName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filters.In status = 3;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getStatus = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 3));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Filters.In model_year = 4;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getModelYear = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 4));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setModelYear = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearModelYear = function() {
  return this.setModelYear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasModelYear = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Filters.In client_org_id = 5;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getClientOrgId = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 5));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setClientOrgId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearClientOrgId = function() {
  return this.setClientOrgId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasClientOrgId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Filters.In functional_status = 6;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getFunctionalStatus = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 6));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
*/
proto.api.ListVehiclesManagementRequest.Filter.prototype.setFunctionalStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.clearFunctionalStatus = function() {
  return this.setFunctionalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.hasFunctionalStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string search_string = 7;
 * @return {string}
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementRequest.Filter} returns this
 */
proto.api.ListVehiclesManagementRequest.Filter.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListVehiclesManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
 */
proto.api.ListVehiclesManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageRequest page = 2;
 * @return {?proto.api.PageRequest}
 */
proto.api.ListVehiclesManagementRequest.prototype.getPage = function() {
  return /** @type{?proto.api.PageRequest} */ (
    jspb.Message.getWrapperField(this, page_pb.PageRequest, 2));
};


/**
 * @param {?proto.api.PageRequest|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
*/
proto.api.ListVehiclesManagementRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
 */
proto.api.ListVehiclesManagementRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.api.ListVehiclesManagementRequest.Filter}
 */
proto.api.ListVehiclesManagementRequest.prototype.getFilter = function() {
  return /** @type{?proto.api.ListVehiclesManagementRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.api.ListVehiclesManagementRequest.Filter, 3));
};


/**
 * @param {?proto.api.ListVehiclesManagementRequest.Filter|undefined} value
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
*/
proto.api.ListVehiclesManagementRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
 */
proto.api.ListVehiclesManagementRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_map = 4;
 * @return {boolean}
 */
proto.api.ListVehiclesManagementRequest.prototype.getIsMap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ListVehiclesManagementRequest} returns this
 */
proto.api.ListVehiclesManagementRequest.prototype.setIsMap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UserDetailsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UserDetailsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UserDetailsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserDetailsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serialId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UserDetailsItem}
 */
proto.api.UserDetailsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UserDetailsItem;
  return proto.api.UserDetailsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UserDetailsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UserDetailsItem}
 */
proto.api.UserDetailsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UserDetailsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UserDetailsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UserDetailsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserDetailsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSerialId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UserDetailsItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserDetailsItem} returns this
 */
proto.api.UserDetailsItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.api.UserDetailsItem.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserDetailsItem} returns this
 */
proto.api.UserDetailsItem.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.api.UserDetailsItem.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserDetailsItem} returns this
 */
proto.api.UserDetailsItem.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 serial_id = 4;
 * @return {number}
 */
proto.api.UserDetailsItem.prototype.getSerialId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UserDetailsItem} returns this
 */
proto.api.UserDetailsItem.prototype.setSerialId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListVehiclesManagementResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && page_pb.PageResponse.toObject(includeInstance, f),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.api.ListVehiclesManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesManagementResponse}
 */
proto.api.ListVehiclesManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesManagementResponse;
  return proto.api.ListVehiclesManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesManagementResponse}
 */
proto.api.ListVehiclesManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new page_pb.PageResponse;
      reader.readMessage(value,page_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new proto.api.ListVehiclesManagementResponse.Item;
      reader.readMessage(value,proto.api.ListVehiclesManagementResponse.Item.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      page_pb.PageResponse.serializeBinaryToWriter
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListVehiclesManagementResponse.Item.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListVehiclesManagementResponse.Item.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assignedToUserDetails: (f = msg.getAssignedToUserDetails()) && proto.api.UserDetailsItem.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && geo_pb.Point.toObject(includeInstance, f),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentlyAssignedToList: jspb.Message.toObjectList(msg.getCurrentlyAssignedToList(),
    proto.api.UserDetailsItem.toObject, includeInstance),
    batteryLevel: jspb.Message.getFieldWithDefault(msg, 9, ""),
    powerType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    zoneName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    updatedAtLocation: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesManagementResponse.Item}
 */
proto.api.ListVehiclesManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesManagementResponse.Item;
  return proto.api.ListVehiclesManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesManagementResponse.Item}
 */
proto.api.ListVehiclesManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 5:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.setAssignedToUserDetails(value);
      break;
    case 6:
      var value = new geo_pb.Point;
      reader.readMessage(value,geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    case 8:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.addCurrentlyAssignedTo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatteryLevel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAtLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAssignedToUserDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentlyAssignedToList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getBatteryLevel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPowerType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getZoneName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUpdatedAtLocation();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
*/
proto.api.ListVehiclesManagementResponse.Item.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserDetailsItem assigned_to_user_details = 5;
 * @return {?proto.api.UserDetailsItem}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getAssignedToUserDetails = function() {
  return /** @type{?proto.api.UserDetailsItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDetailsItem, 5));
};


/**
 * @param {?proto.api.UserDetailsItem|undefined} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
*/
proto.api.ListVehiclesManagementResponse.Item.prototype.setAssignedToUserDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.clearAssignedToUserDetails = function() {
  return this.setAssignedToUserDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.hasAssignedToUserDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point location = 6;
 * @return {?proto.api.Point}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getLocation = function() {
  return /** @type{?proto.api.Point} */ (
    jspb.Message.getWrapperField(this, geo_pb.Point, 6));
};


/**
 * @param {?proto.api.Point|undefined} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
*/
proto.api.ListVehiclesManagementResponse.Item.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string owner_org_name = 7;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UserDetailsItem currently_assigned_to = 8;
 * @return {!Array<!proto.api.UserDetailsItem>}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getCurrentlyAssignedToList = function() {
  return /** @type{!Array<!proto.api.UserDetailsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UserDetailsItem, 8));
};


/**
 * @param {!Array<!proto.api.UserDetailsItem>} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
*/
proto.api.ListVehiclesManagementResponse.Item.prototype.setCurrentlyAssignedToList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.UserDetailsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UserDetailsItem}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.addCurrentlyAssignedTo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.UserDetailsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.clearCurrentlyAssignedToList = function() {
  return this.setCurrentlyAssignedToList([]);
};


/**
 * optional string battery_level = 9;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getBatteryLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setBatteryLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string power_type = 10;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getPowerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setPowerType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string zone_name = 11;
 * @return {string}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 updated_at_location = 12;
 * @return {number}
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.getUpdatedAtLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListVehiclesManagementResponse.Item} returns this
 */
proto.api.ListVehiclesManagementResponse.Item.prototype.setUpdatedAtLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional PageResponse page = 1;
 * @return {?proto.api.PageResponse}
 */
proto.api.ListVehiclesManagementResponse.prototype.getPage = function() {
  return /** @type{?proto.api.PageResponse} */ (
    jspb.Message.getWrapperField(this, page_pb.PageResponse, 1));
};


/**
 * @param {?proto.api.PageResponse|undefined} value
 * @return {!proto.api.ListVehiclesManagementResponse} returns this
*/
proto.api.ListVehiclesManagementResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesManagementResponse} returns this
 */
proto.api.ListVehiclesManagementResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesManagementResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item vehicles = 2;
 * @return {!Array<!proto.api.ListVehiclesManagementResponse.Item>}
 */
proto.api.ListVehiclesManagementResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.api.ListVehiclesManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListVehiclesManagementResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListVehiclesManagementResponse.Item>} value
 * @return {!proto.api.ListVehiclesManagementResponse} returns this
*/
proto.api.ListVehiclesManagementResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListVehiclesManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListVehiclesManagementResponse.Item}
 */
proto.api.ListVehiclesManagementResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListVehiclesManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListVehiclesManagementResponse} returns this
 */
proto.api.ListVehiclesManagementResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListDropDownVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListDropDownVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListDropDownVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListDropDownVehiclesRequest}
 */
proto.api.ListDropDownVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListDropDownVehiclesRequest;
  return proto.api.ListDropDownVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListDropDownVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListDropDownVehiclesRequest}
 */
proto.api.ListDropDownVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListDropDownVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListDropDownVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListDropDownVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListDropDownVehiclesRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListDropDownVehiclesRequest} returns this
 */
proto.api.ListDropDownVehiclesRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListDropDownVehiclesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListDropDownVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListDropDownVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListDropDownVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.api.ListDropDownVehiclesResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListDropDownVehiclesResponse}
 */
proto.api.ListDropDownVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListDropDownVehiclesResponse;
  return proto.api.ListDropDownVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListDropDownVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListDropDownVehiclesResponse}
 */
proto.api.ListDropDownVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.api.ListDropDownVehiclesResponse.Item;
      reader.readMessage(value,proto.api.ListDropDownVehiclesResponse.Item.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListDropDownVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListDropDownVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListDropDownVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListDropDownVehiclesResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListDropDownVehiclesResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListDropDownVehiclesResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListDropDownVehiclesResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListDropDownVehiclesResponse.Item}
 */
proto.api.ListDropDownVehiclesResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListDropDownVehiclesResponse.Item;
  return proto.api.ListDropDownVehiclesResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListDropDownVehiclesResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListDropDownVehiclesResponse.Item}
 */
proto.api.ListDropDownVehiclesResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListDropDownVehiclesResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListDropDownVehiclesResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListDropDownVehiclesResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListDropDownVehiclesResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.ListDropDownVehiclesResponse.Item.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.ListDropDownVehiclesResponse.Item} returns this
*/
proto.api.ListDropDownVehiclesResponse.Item.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListDropDownVehiclesResponse.Item} returns this
 */
proto.api.ListDropDownVehiclesResponse.Item.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListDropDownVehiclesResponse.Item.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item vehicles = 2;
 * @return {!Array<!proto.api.ListDropDownVehiclesResponse.Item>}
 */
proto.api.ListDropDownVehiclesResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.api.ListDropDownVehiclesResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListDropDownVehiclesResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListDropDownVehiclesResponse.Item>} value
 * @return {!proto.api.ListDropDownVehiclesResponse} returns this
*/
proto.api.ListDropDownVehiclesResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListDropDownVehiclesResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListDropDownVehiclesResponse.Item}
 */
proto.api.ListDropDownVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListDropDownVehiclesResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListDropDownVehiclesResponse} returns this
 */
proto.api.ListDropDownVehiclesResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleRequest}
 */
proto.api.ListVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleRequest;
  return proto.api.ListVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleRequest}
 */
proto.api.ListVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListVehicleRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehicleRequest} returns this
 */
proto.api.ListVehicleRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListVehicleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.api.ListVehicleResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleResponse}
 */
proto.api.ListVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleResponse;
  return proto.api.ListVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleResponse}
 */
proto.api.ListVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListVehicleResponse.Item;
      reader.readMessage(value,proto.api.ListVehicleResponse.Item.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListVehicleResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: (f = msg.getLocation()) && geo_pb.Point.toObject(includeInstance, f),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleResponse.Item}
 */
proto.api.ListVehicleResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleResponse.Item;
  return proto.api.ListVehicleResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleResponse.Item}
 */
proto.api.ListVehicleResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 5:
      var value = new geo_pb.Point;
      reader.readMessage(value,geo_pb.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geo_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.ListVehicleResponse.Item.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
*/
proto.api.ListVehicleResponse.Item.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehicleResponse.Item.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.ListVehicleResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.ListVehicleResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.ListVehicleResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Point location = 5;
 * @return {?proto.api.Point}
 */
proto.api.ListVehicleResponse.Item.prototype.getLocation = function() {
  return /** @type{?proto.api.Point} */ (
    jspb.Message.getWrapperField(this, geo_pb.Point, 5));
};


/**
 * @param {?proto.api.Point|undefined} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
*/
proto.api.ListVehicleResponse.Item.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehicleResponse.Item.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string owner_org_name = 6;
 * @return {string}
 */
proto.api.ListVehicleResponse.Item.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehicleResponse.Item} returns this
 */
proto.api.ListVehicleResponse.Item.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Item vehicles = 1;
 * @return {!Array<!proto.api.ListVehicleResponse.Item>}
 */
proto.api.ListVehicleResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.api.ListVehicleResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListVehicleResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListVehicleResponse.Item>} value
 * @return {!proto.api.ListVehicleResponse} returns this
*/
proto.api.ListVehicleResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListVehicleResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListVehicleResponse.Item}
 */
proto.api.ListVehicleResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListVehicleResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListVehicleResponse} returns this
 */
proto.api.ListVehicleResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && page_pb.PageRequest.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.api.ListVehiclesAdminRequest.Filter.toObject(includeInstance, f),
    isMap: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesAdminRequest}
 */
proto.api.ListVehiclesAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesAdminRequest;
  return proto.api.ListVehiclesAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesAdminRequest}
 */
proto.api.ListVehiclesAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new page_pb.PageRequest;
      reader.readMessage(value,page_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new proto.api.ListVehiclesAdminRequest.Filter;
      reader.readMessage(value,proto.api.ListVehiclesAdminRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      page_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ListVehiclesAdminRequest.Filter.serializeBinaryToWriter
    );
  }
  f = message.getIsMap();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesAdminRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesAdminRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelMake: (f = msg.getModelMake()) && filter_pb.Filters.In.toObject(includeInstance, f),
    modelName: (f = msg.getModelName()) && filter_pb.Filters.In.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && filter_pb.Filters.In.toObject(includeInstance, f),
    modelYear: (f = msg.getModelYear()) && filter_pb.Filters.In.toObject(includeInstance, f),
    clientOrgId: (f = msg.getClientOrgId()) && filter_pb.Filters.In.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter}
 */
proto.api.ListVehiclesAdminRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesAdminRequest.Filter;
  return proto.api.ListVehiclesAdminRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesAdminRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter}
 */
proto.api.ListVehiclesAdminRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelMake(value);
      break;
    case 2:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelName(value);
      break;
    case 3:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setModelYear(value);
      break;
    case 5:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setClientOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesAdminRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesAdminRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelMake();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getModelYear();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getClientOrgId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filters.In model_make = 1;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.getModelMake = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 1));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
*/
proto.api.ListVehiclesAdminRequest.Filter.prototype.setModelMake = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.clearModelMake = function() {
  return this.setModelMake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.hasModelMake = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters.In model_name = 2;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.getModelName = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 2));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
*/
proto.api.ListVehiclesAdminRequest.Filter.prototype.setModelName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.clearModelName = function() {
  return this.setModelName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.hasModelName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filters.In status = 3;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.getStatus = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 3));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
*/
proto.api.ListVehiclesAdminRequest.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Filters.In model_year = 4;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.getModelYear = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 4));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
*/
proto.api.ListVehiclesAdminRequest.Filter.prototype.setModelYear = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.clearModelYear = function() {
  return this.setModelYear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.hasModelYear = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Filters.In client_org_id = 5;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.getClientOrgId = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 5));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
*/
proto.api.ListVehiclesAdminRequest.Filter.prototype.setClientOrgId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest.Filter} returns this
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.clearClientOrgId = function() {
  return this.setClientOrgId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.Filter.prototype.hasClientOrgId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListVehiclesAdminRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
 */
proto.api.ListVehiclesAdminRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageRequest page = 2;
 * @return {?proto.api.PageRequest}
 */
proto.api.ListVehiclesAdminRequest.prototype.getPage = function() {
  return /** @type{?proto.api.PageRequest} */ (
    jspb.Message.getWrapperField(this, page_pb.PageRequest, 2));
};


/**
 * @param {?proto.api.PageRequest|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
*/
proto.api.ListVehiclesAdminRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
 */
proto.api.ListVehiclesAdminRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.api.ListVehiclesAdminRequest.Filter}
 */
proto.api.ListVehiclesAdminRequest.prototype.getFilter = function() {
  return /** @type{?proto.api.ListVehiclesAdminRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.api.ListVehiclesAdminRequest.Filter, 3));
};


/**
 * @param {?proto.api.ListVehiclesAdminRequest.Filter|undefined} value
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
*/
proto.api.ListVehiclesAdminRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
 */
proto.api.ListVehiclesAdminRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_map = 4;
 * @return {boolean}
 */
proto.api.ListVehiclesAdminRequest.prototype.getIsMap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ListVehiclesAdminRequest} returns this
 */
proto.api.ListVehiclesAdminRequest.prototype.setIsMap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListVehiclesAdminResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && page_pb.PageResponse.toObject(includeInstance, f),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.api.ListVehiclesAdminResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesAdminResponse}
 */
proto.api.ListVehiclesAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesAdminResponse;
  return proto.api.ListVehiclesAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesAdminResponse}
 */
proto.api.ListVehiclesAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new page_pb.PageResponse;
      reader.readMessage(value,page_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new proto.api.ListVehiclesAdminResponse.Item;
      reader.readMessage(value,proto.api.ListVehiclesAdminResponse.Item.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      page_pb.PageResponse.serializeBinaryToWriter
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListVehiclesAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehiclesAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehiclesAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assignedToUserDetails: (f = msg.getAssignedToUserDetails()) && proto.api.UserDetailsItem.toObject(includeInstance, f),
    zoneName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehiclesAdminResponse.Item}
 */
proto.api.ListVehiclesAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehiclesAdminResponse.Item;
  return proto.api.ListVehiclesAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehiclesAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehiclesAdminResponse.Item}
 */
proto.api.ListVehiclesAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelMake(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelYear(value);
      break;
    case 5:
      var value = new proto.api.UserDetailsItem;
      reader.readMessage(value,proto.api.UserDetailsItem.deserializeBinaryFromReader);
      msg.setAssignedToUserDetails(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehiclesAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehiclesAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehiclesAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelYear();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAssignedToUserDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.UserDetailsItem.serializeBinaryToWriter
    );
  }
  f = message.getZoneName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
*/
proto.api.ListVehiclesAdminResponse.Item.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_make = 3;
 * @return {string}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getModelMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.setModelMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 model_year = 4;
 * @return {number}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getModelYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.setModelYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserDetailsItem assigned_to_user_details = 5;
 * @return {?proto.api.UserDetailsItem}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getAssignedToUserDetails = function() {
  return /** @type{?proto.api.UserDetailsItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDetailsItem, 5));
};


/**
 * @param {?proto.api.UserDetailsItem|undefined} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
*/
proto.api.ListVehiclesAdminResponse.Item.prototype.setAssignedToUserDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.clearAssignedToUserDetails = function() {
  return this.setAssignedToUserDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.hasAssignedToUserDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string zone_name = 6;
 * @return {string}
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.getZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListVehiclesAdminResponse.Item} returns this
 */
proto.api.ListVehiclesAdminResponse.Item.prototype.setZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PageResponse page = 1;
 * @return {?proto.api.PageResponse}
 */
proto.api.ListVehiclesAdminResponse.prototype.getPage = function() {
  return /** @type{?proto.api.PageResponse} */ (
    jspb.Message.getWrapperField(this, page_pb.PageResponse, 1));
};


/**
 * @param {?proto.api.PageResponse|undefined} value
 * @return {!proto.api.ListVehiclesAdminResponse} returns this
*/
proto.api.ListVehiclesAdminResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehiclesAdminResponse} returns this
 */
proto.api.ListVehiclesAdminResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehiclesAdminResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item vehicles = 2;
 * @return {!Array<!proto.api.ListVehiclesAdminResponse.Item>}
 */
proto.api.ListVehiclesAdminResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.api.ListVehiclesAdminResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListVehiclesAdminResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListVehiclesAdminResponse.Item>} value
 * @return {!proto.api.ListVehiclesAdminResponse} returns this
*/
proto.api.ListVehiclesAdminResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListVehiclesAdminResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListVehiclesAdminResponse.Item}
 */
proto.api.ListVehiclesAdminResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListVehiclesAdminResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListVehiclesAdminResponse} returns this
 */
proto.api.ListVehiclesAdminResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteVehicleAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteVehicleAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteVehicleAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteVehicleAdminRequest}
 */
proto.api.DeleteVehicleAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteVehicleAdminRequest;
  return proto.api.DeleteVehicleAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteVehicleAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteVehicleAdminRequest}
 */
proto.api.DeleteVehicleAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteVehicleAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteVehicleAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteVehicleAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteVehicleAdminRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteVehicleAdminRequest} returns this
 */
proto.api.DeleteVehicleAdminRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadCsvRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadCsvRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadCsvRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadCsvRequest}
 */
proto.api.UploadCsvRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadCsvRequest;
  return proto.api.UploadCsvRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadCsvRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadCsvRequest}
 */
proto.api.UploadCsvRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadCsvRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadCsvRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadCsvRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChunkData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.api.UploadCsvRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadCsvRequest} returns this
 */
proto.api.UploadCsvRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.api.UploadCsvRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.api.UploadCsvRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.api.UploadCsvRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.api.UploadCsvRequest} returns this
 */
proto.api.UploadCsvRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.UploadCsvResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadCsvResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadCsvResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadCsvResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasErrors: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.api.UploadCsvResponse.ErrorMessages.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadCsvResponse}
 */
proto.api.UploadCsvResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadCsvResponse;
  return proto.api.UploadCsvResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadCsvResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadCsvResponse}
 */
proto.api.UploadCsvResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasErrors(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSize(value);
      break;
    case 3:
      var value = new proto.api.UploadCsvResponse.ErrorMessages;
      reader.readMessage(value,proto.api.UploadCsvResponse.ErrorMessages.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadCsvResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadCsvResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadCsvResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasErrors();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.UploadCsvResponse.ErrorMessages.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadCsvResponse.ErrorMessages.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadCsvResponse.ErrorMessages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadCsvResponse.ErrorMessages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvResponse.ErrorMessages.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadCsvResponse.ErrorMessages}
 */
proto.api.UploadCsvResponse.ErrorMessages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadCsvResponse.ErrorMessages;
  return proto.api.UploadCsvResponse.ErrorMessages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadCsvResponse.ErrorMessages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadCsvResponse.ErrorMessages}
 */
proto.api.UploadCsvResponse.ErrorMessages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadCsvResponse.ErrorMessages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadCsvResponse.ErrorMessages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadCsvResponse.ErrorMessages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadCsvResponse.ErrorMessages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.api.UploadCsvResponse.ErrorMessages.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadCsvResponse.ErrorMessages} returns this
 */
proto.api.UploadCsvResponse.ErrorMessages.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_errors = 1;
 * @return {boolean}
 */
proto.api.UploadCsvResponse.prototype.getHasErrors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UploadCsvResponse} returns this
 */
proto.api.UploadCsvResponse.prototype.setHasErrors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint32 size = 2;
 * @return {number}
 */
proto.api.UploadCsvResponse.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UploadCsvResponse} returns this
 */
proto.api.UploadCsvResponse.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ErrorMessages messages = 3;
 * @return {!Array<!proto.api.UploadCsvResponse.ErrorMessages>}
 */
proto.api.UploadCsvResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.api.UploadCsvResponse.ErrorMessages>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UploadCsvResponse.ErrorMessages, 3));
};


/**
 * @param {!Array<!proto.api.UploadCsvResponse.ErrorMessages>} value
 * @return {!proto.api.UploadCsvResponse} returns this
*/
proto.api.UploadCsvResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.UploadCsvResponse.ErrorMessages=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UploadCsvResponse.ErrorMessages}
 */
proto.api.UploadCsvResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.UploadCsvResponse.ErrorMessages, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.UploadCsvResponse} returns this
 */
proto.api.UploadCsvResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteVehicleManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteVehicleManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteVehicleManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteVehicleManagementRequest}
 */
proto.api.DeleteVehicleManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteVehicleManagementRequest;
  return proto.api.DeleteVehicleManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteVehicleManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteVehicleManagementRequest}
 */
proto.api.DeleteVehicleManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteVehicleManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteVehicleManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteVehicleManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteVehicleManagementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteVehicleManagementRequest} returns this
 */
proto.api.DeleteVehicleManagementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteVehicleManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteVehicleManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteVehicleManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteVehicleManagementResponse}
 */
proto.api.DeleteVehicleManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteVehicleManagementResponse;
  return proto.api.DeleteVehicleManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteVehicleManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteVehicleManagementResponse}
 */
proto.api.DeleteVehicleManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteVehicleManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteVehicleManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteVehicleManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteVehicleManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleModelsRequest}
 */
proto.api.ListVehicleModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleModelsRequest;
  return proto.api.ListVehicleModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleModelsRequest}
 */
proto.api.ListVehicleModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListVehicleModelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.api.ListVehicleModelsResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleModelsResponse}
 */
proto.api.ListVehicleModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleModelsResponse;
  return proto.api.ListVehicleModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleModelsResponse}
 */
proto.api.ListVehicleModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListVehicleModelsResponse.Item;
      reader.readMessage(value,proto.api.ListVehicleModelsResponse.Item.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListVehicleModelsResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListVehicleModelsResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListVehicleModelsResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListVehicleModelsResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ModelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListVehicleModelsResponse.Item}
 */
proto.api.ListVehicleModelsResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListVehicleModelsResponse.Item;
  return proto.api.ListVehicleModelsResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListVehicleModelsResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListVehicleModelsResponse.Item}
 */
proto.api.ListVehicleModelsResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ModelItem;
      reader.readMessage(value,proto.api.ModelItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListVehicleModelsResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListVehicleModelsResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListVehicleModelsResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListVehicleModelsResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ModelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModelItem basic = 1;
 * @return {?proto.api.ModelItem}
 */
proto.api.ListVehicleModelsResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ModelItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ModelItem, 1));
};


/**
 * @param {?proto.api.ModelItem|undefined} value
 * @return {!proto.api.ListVehicleModelsResponse.Item} returns this
*/
proto.api.ListVehicleModelsResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListVehicleModelsResponse.Item} returns this
 */
proto.api.ListVehicleModelsResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListVehicleModelsResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item models = 1;
 * @return {!Array<!proto.api.ListVehicleModelsResponse.Item>}
 */
proto.api.ListVehicleModelsResponse.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.api.ListVehicleModelsResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListVehicleModelsResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListVehicleModelsResponse.Item>} value
 * @return {!proto.api.ListVehicleModelsResponse} returns this
*/
proto.api.ListVehicleModelsResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListVehicleModelsResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListVehicleModelsResponse.Item}
 */
proto.api.ListVehicleModelsResponse.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListVehicleModelsResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListVehicleModelsResponse} returns this
 */
proto.api.ListVehicleModelsResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateVehicleFirmwareVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateVehicleFirmwareVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateVehicleFirmwareVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleFirmwareVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateVehicleFirmwareVersionRequest}
 */
proto.api.UpdateVehicleFirmwareVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateVehicleFirmwareVersionRequest;
  return proto.api.UpdateVehicleFirmwareVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateVehicleFirmwareVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateVehicleFirmwareVersionRequest}
 */
proto.api.UpdateVehicleFirmwareVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateVehicleFirmwareVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateVehicleFirmwareVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateVehicleFirmwareVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleFirmwareVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UpdateVehicleFirmwareVersionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleFirmwareVersionRequest} returns this
 */
proto.api.UpdateVehicleFirmwareVersionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateVehicleFirmwareVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateVehicleFirmwareVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateVehicleFirmwareVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleFirmwareVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateVehicleFirmwareVersionResponse}
 */
proto.api.UpdateVehicleFirmwareVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateVehicleFirmwareVersionResponse;
  return proto.api.UpdateVehicleFirmwareVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateVehicleFirmwareVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateVehicleFirmwareVersionResponse}
 */
proto.api.UpdateVehicleFirmwareVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateVehicleFirmwareVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateVehicleFirmwareVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateVehicleFirmwareVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateVehicleFirmwareVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_url = 1;
 * @return {string}
 */
proto.api.UpdateVehicleFirmwareVersionResponse.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateVehicleFirmwareVersionResponse} returns this
 */
proto.api.UpdateVehicleFirmwareVersionResponse.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadVehicleFirmwareToSpacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadVehicleFirmwareToSpacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileExtension: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadVehicleFirmwareToSpacesRequest}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadVehicleFirmwareToSpacesRequest;
  return proto.api.UploadVehicleFirmwareToSpacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadVehicleFirmwareToSpacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadVehicleFirmwareToSpacesRequest}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileExtension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadVehicleFirmwareToSpacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadVehicleFirmwareToSpacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileExtension();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.api.UploadVehicleFirmwareToSpacesRequest} returns this
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadVehicleFirmwareToSpacesRequest} returns this
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_extension = 3;
 * @return {string}
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.getFileExtension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadVehicleFirmwareToSpacesRequest} returns this
 */
proto.api.UploadVehicleFirmwareToSpacesRequest.prototype.setFileExtension = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadVehicleFirmwareToSpacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadVehicleFirmwareToSpacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadVehicleFirmwareToSpacesResponse}
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadVehicleFirmwareToSpacesResponse;
  return proto.api.UploadVehicleFirmwareToSpacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadVehicleFirmwareToSpacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadVehicleFirmwareToSpacesResponse}
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadVehicleFirmwareToSpacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadVehicleFirmwareToSpacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_url = 1;
 * @return {string}
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadVehicleFirmwareToSpacesResponse} returns this
 */
proto.api.UploadVehicleFirmwareToSpacesResponse.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateBulkVehicleFirmwareVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateBulkVehicleFirmwareVersionRequest}
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateBulkVehicleFirmwareVersionRequest;
  return proto.api.UpdateBulkVehicleFirmwareVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateBulkVehicleFirmwareVersionRequest}
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateBulkVehicleFirmwareVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateBulkVehicleFirmwareVersionRequest} returns this
 */
proto.api.UpdateBulkVehicleFirmwareVersionRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateBulkVehicleFirmwareVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateBulkVehicleFirmwareVersionResponse}
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateBulkVehicleFirmwareVersionResponse;
  return proto.api.UpdateBulkVehicleFirmwareVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateBulkVehicleFirmwareVersionResponse}
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateBulkVehicleFirmwareVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateBulkVehicleFirmwareVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBulkVehicleFirmwareVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadFirmwareToModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadFirmwareToModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadFirmwareToModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadFirmwareToModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileExtension: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadFirmwareToModelRequest}
 */
proto.api.UploadFirmwareToModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadFirmwareToModelRequest;
  return proto.api.UploadFirmwareToModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadFirmwareToModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadFirmwareToModelRequest}
 */
proto.api.UploadFirmwareToModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileExtension(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadFirmwareToModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadFirmwareToModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadFirmwareToModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadFirmwareToModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileExtension();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.api.UploadFirmwareToModelRequest} returns this
 */
proto.api.UploadFirmwareToModelRequest.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadFirmwareToModelRequest} returns this
 */
proto.api.UploadFirmwareToModelRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_extension = 3;
 * @return {string}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFileExtension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadFirmwareToModelRequest} returns this
 */
proto.api.UploadFirmwareToModelRequest.prototype.setFileExtension = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string firmware_version = 4;
 * @return {string}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadFirmwareToModelRequest} returns this
 */
proto.api.UploadFirmwareToModelRequest.prototype.setFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.api.UploadFirmwareToModelRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UploadFirmwareToModelRequest} returns this
 */
proto.api.UploadFirmwareToModelRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UploadFirmwareToModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UploadFirmwareToModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UploadFirmwareToModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadFirmwareToModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UploadFirmwareToModelResponse}
 */
proto.api.UploadFirmwareToModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UploadFirmwareToModelResponse;
  return proto.api.UploadFirmwareToModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UploadFirmwareToModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UploadFirmwareToModelResponse}
 */
proto.api.UploadFirmwareToModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UploadFirmwareToModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UploadFirmwareToModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UploadFirmwareToModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UploadFirmwareToModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateFirmwareVersionToModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateFirmwareVersionToModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareVersionToModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firmwareId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateFirmwareVersionToModelRequest}
 */
proto.api.UpdateFirmwareVersionToModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateFirmwareVersionToModelRequest;
  return proto.api.UpdateFirmwareVersionToModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateFirmwareVersionToModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateFirmwareVersionToModelRequest}
 */
proto.api.UpdateFirmwareVersionToModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateFirmwareVersionToModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateFirmwareVersionToModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareVersionToModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirmwareId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateFirmwareVersionToModelRequest} returns this
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firmware_id = 2;
 * @return {string}
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.getFirmwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateFirmwareVersionToModelRequest} returns this
 */
proto.api.UpdateFirmwareVersionToModelRequest.prototype.setFirmwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateFirmwareVersionToModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateFirmwareVersionToModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateFirmwareVersionToModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareVersionToModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateFirmwareVersionToModelResponse}
 */
proto.api.UpdateFirmwareVersionToModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateFirmwareVersionToModelResponse;
  return proto.api.UpdateFirmwareVersionToModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateFirmwareVersionToModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateFirmwareVersionToModelResponse}
 */
proto.api.UpdateFirmwareVersionToModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateFirmwareVersionToModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateFirmwareVersionToModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateFirmwareVersionToModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareVersionToModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateMqttServerByVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateMqttServerByVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMqttServerByVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    server: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateMqttServerByVehicleRequest}
 */
proto.api.UpdateMqttServerByVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateMqttServerByVehicleRequest;
  return proto.api.UpdateMqttServerByVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateMqttServerByVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateMqttServerByVehicleRequest}
 */
proto.api.UpdateMqttServerByVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateMqttServerByVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateMqttServerByVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMqttServerByVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateMqttServerByVehicleRequest} returns this
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string server = 2;
 * @return {string}
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.getServer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateMqttServerByVehicleRequest} returns this
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.setServer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string port = 3;
 * @return {string}
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateMqttServerByVehicleRequest} returns this
 */
proto.api.UpdateMqttServerByVehicleRequest.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateMqttServerByVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateMqttServerByVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateMqttServerByVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMqttServerByVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateMqttServerByVehicleResponse}
 */
proto.api.UpdateMqttServerByVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateMqttServerByVehicleResponse;
  return proto.api.UpdateMqttServerByVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateMqttServerByVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateMqttServerByVehicleResponse}
 */
proto.api.UpdateMqttServerByVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateMqttServerByVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateMqttServerByVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateMqttServerByVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMqttServerByVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.UpdateFirmwareOnVehiclesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateFirmwareOnVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateFirmwareOnVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareOnVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firmwareId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateFirmwareOnVehiclesRequest;
  return proto.api.UpdateFirmwareOnVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateFirmwareOnVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateFirmwareOnVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateFirmwareOnVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareOnVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirmwareId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string firmware_id = 1;
 * @return {string}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.getFirmwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest} returns this
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.setFirmwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string vehicle_ids = 2;
 * @return {!Array<string>}
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest} returns this
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest} returns this
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.UpdateFirmwareOnVehiclesRequest} returns this
 */
proto.api.UpdateFirmwareOnVehiclesRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateFirmwareOnVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateFirmwareOnVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateFirmwareOnVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareOnVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateFirmwareOnVehiclesResponse}
 */
proto.api.UpdateFirmwareOnVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateFirmwareOnVehiclesResponse;
  return proto.api.UpdateFirmwareOnVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateFirmwareOnVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateFirmwareOnVehiclesResponse}
 */
proto.api.UpdateFirmwareOnVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateFirmwareOnVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateFirmwareOnVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateFirmwareOnVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateFirmwareOnVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.FirmwareItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.FirmwareItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.FirmwareItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FirmwareItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    versionName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.FirmwareItem}
 */
proto.api.FirmwareItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.FirmwareItem;
  return proto.api.FirmwareItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.FirmwareItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.FirmwareItem}
 */
proto.api.FirmwareItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.FirmwareItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.FirmwareItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.FirmwareItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FirmwareItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.FirmwareItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FirmwareItem} returns this
 */
proto.api.FirmwareItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version_name = 2;
 * @return {string}
 */
proto.api.FirmwareItem.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FirmwareItem} returns this
 */
proto.api.FirmwareItem.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListFirmwaresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListFirmwaresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListFirmwaresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListFirmwaresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListFirmwaresRequest}
 */
proto.api.ListFirmwaresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListFirmwaresRequest;
  return proto.api.ListFirmwaresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListFirmwaresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListFirmwaresRequest}
 */
proto.api.ListFirmwaresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListFirmwaresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListFirmwaresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListFirmwaresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListFirmwaresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListFirmwaresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListFirmwaresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListFirmwaresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListFirmwaresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListFirmwaresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    firmwareList: jspb.Message.toObjectList(msg.getFirmwareList(),
    proto.api.FirmwareItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListFirmwaresResponse}
 */
proto.api.ListFirmwaresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListFirmwaresResponse;
  return proto.api.ListFirmwaresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListFirmwaresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListFirmwaresResponse}
 */
proto.api.ListFirmwaresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.FirmwareItem;
      reader.readMessage(value,proto.api.FirmwareItem.deserializeBinaryFromReader);
      msg.addFirmware(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListFirmwaresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListFirmwaresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListFirmwaresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListFirmwaresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirmwareList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.FirmwareItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FirmwareItem firmware = 1;
 * @return {!Array<!proto.api.FirmwareItem>}
 */
proto.api.ListFirmwaresResponse.prototype.getFirmwareList = function() {
  return /** @type{!Array<!proto.api.FirmwareItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.FirmwareItem, 1));
};


/**
 * @param {!Array<!proto.api.FirmwareItem>} value
 * @return {!proto.api.ListFirmwaresResponse} returns this
*/
proto.api.ListFirmwaresResponse.prototype.setFirmwareList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.FirmwareItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.FirmwareItem}
 */
proto.api.ListFirmwaresResponse.prototype.addFirmware = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.FirmwareItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListFirmwaresResponse} returns this
 */
proto.api.ListFirmwaresResponse.prototype.clearFirmwareList = function() {
  return this.setFirmwareList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetTotalDistanceByClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetTotalDistanceByClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetTotalDistanceByClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetTotalDistanceByClientRequest}
 */
proto.api.GetTotalDistanceByClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetTotalDistanceByClientRequest;
  return proto.api.GetTotalDistanceByClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetTotalDistanceByClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetTotalDistanceByClientRequest}
 */
proto.api.GetTotalDistanceByClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetTotalDistanceByClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetTotalDistanceByClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetTotalDistanceByClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string from_date = 1;
 * @return {string}
 */
proto.api.GetTotalDistanceByClientRequest.prototype.getFromDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetTotalDistanceByClientRequest} returns this
 */
proto.api.GetTotalDistanceByClientRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_date = 2;
 * @return {string}
 */
proto.api.GetTotalDistanceByClientRequest.prototype.getToDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetTotalDistanceByClientRequest} returns this
 */
proto.api.GetTotalDistanceByClientRequest.prototype.setToDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.api.GetTotalDistanceByClientRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetTotalDistanceByClientRequest} returns this
 */
proto.api.GetTotalDistanceByClientRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetTotalDistanceByClientResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetTotalDistanceByClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetTotalDistanceByClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetTotalDistanceByClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationalBikes: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleDistanceList: jspb.Message.toObjectList(msg.getVehicleDistanceList(),
    proto.api.GetTotalDistanceByClientResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetTotalDistanceByClientResponse}
 */
proto.api.GetTotalDistanceByClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetTotalDistanceByClientResponse;
  return proto.api.GetTotalDistanceByClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetTotalDistanceByClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetTotalDistanceByClientResponse}
 */
proto.api.GetTotalDistanceByClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperationalBikes(value);
      break;
    case 2:
      var value = new proto.api.GetTotalDistanceByClientResponse.Item;
      reader.readMessage(value,proto.api.GetTotalDistanceByClientResponse.Item.deserializeBinaryFromReader);
      msg.addVehicleDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetTotalDistanceByClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetTotalDistanceByClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetTotalDistanceByClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationalBikes();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVehicleDistanceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.GetTotalDistanceByClientResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetTotalDistanceByClientResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetTotalDistanceByClientResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalDistance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetTotalDistanceByClientResponse.Item}
 */
proto.api.GetTotalDistanceByClientResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetTotalDistanceByClientResponse.Item;
  return proto.api.GetTotalDistanceByClientResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetTotalDistanceByClientResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetTotalDistanceByClientResponse.Item}
 */
proto.api.GetTotalDistanceByClientResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDistance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetTotalDistanceByClientResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetTotalDistanceByClientResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalDistanceByClientResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalDistance();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 total_distance = 1;
 * @return {number}
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.getTotalDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetTotalDistanceByClientResponse.Item} returns this
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.setTotalDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetTotalDistanceByClientResponse.Item} returns this
 */
proto.api.GetTotalDistanceByClientResponse.Item.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 operational_bikes = 1;
 * @return {number}
 */
proto.api.GetTotalDistanceByClientResponse.prototype.getOperationalBikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetTotalDistanceByClientResponse} returns this
 */
proto.api.GetTotalDistanceByClientResponse.prototype.setOperationalBikes = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Item vehicle_distance = 2;
 * @return {!Array<!proto.api.GetTotalDistanceByClientResponse.Item>}
 */
proto.api.GetTotalDistanceByClientResponse.prototype.getVehicleDistanceList = function() {
  return /** @type{!Array<!proto.api.GetTotalDistanceByClientResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.GetTotalDistanceByClientResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.GetTotalDistanceByClientResponse.Item>} value
 * @return {!proto.api.GetTotalDistanceByClientResponse} returns this
*/
proto.api.GetTotalDistanceByClientResponse.prototype.setVehicleDistanceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.GetTotalDistanceByClientResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.GetTotalDistanceByClientResponse.Item}
 */
proto.api.GetTotalDistanceByClientResponse.prototype.addVehicleDistance = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.GetTotalDistanceByClientResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetTotalDistanceByClientResponse} returns this
 */
proto.api.GetTotalDistanceByClientResponse.prototype.clearVehicleDistanceList = function() {
  return this.setVehicleDistanceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddVehicleFromCSVRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddVehicleFromCSVRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddVehicleFromCSVRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    fileExt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddVehicleFromCSVRequest}
 */
proto.api.AddVehicleFromCSVRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddVehicleFromCSVRequest;
  return proto.api.AddVehicleFromCSVRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddVehicleFromCSVRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddVehicleFromCSVRequest}
 */
proto.api.AddVehicleFromCSVRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileExt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddVehicleFromCSVRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddVehicleFromCSVRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddVehicleFromCSVRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileExt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.api.AddVehicleFromCSVRequest.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.api.AddVehicleFromCSVRequest.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.api.AddVehicleFromCSVRequest.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.api.AddVehicleFromCSVRequest} returns this
 */
proto.api.AddVehicleFromCSVRequest.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_ext = 2;
 * @return {string}
 */
proto.api.AddVehicleFromCSVRequest.prototype.getFileExt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleFromCSVRequest} returns this
 */
proto.api.AddVehicleFromCSVRequest.prototype.setFileExt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 13;
 * @return {string}
 */
proto.api.AddVehicleFromCSVRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleFromCSVRequest} returns this
 */
proto.api.AddVehicleFromCSVRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AddVehicleFromCSVResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddVehicleFromCSVResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddVehicleFromCSVResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddVehicleFromCSVResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    successMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorMessagesList: jspb.Message.toObjectList(msg.getErrorMessagesList(),
    proto.api.AddVehicleFromCSVResponse.ErrorMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddVehicleFromCSVResponse}
 */
proto.api.AddVehicleFromCSVResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddVehicleFromCSVResponse;
  return proto.api.AddVehicleFromCSVResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddVehicleFromCSVResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddVehicleFromCSVResponse}
 */
proto.api.AddVehicleFromCSVResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessMessage(value);
      break;
    case 2:
      var value = new proto.api.AddVehicleFromCSVResponse.ErrorMessage;
      reader.readMessage(value,proto.api.AddVehicleFromCSVResponse.ErrorMessage.deserializeBinaryFromReader);
      msg.addErrorMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddVehicleFromCSVResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddVehicleFromCSVResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddVehicleFromCSVResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccessMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.AddVehicleFromCSVResponse.ErrorMessage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddVehicleFromCSVResponse.ErrorMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddVehicleFromCSVResponse.ErrorMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddVehicleFromCSVResponse.ErrorMessage}
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddVehicleFromCSVResponse.ErrorMessage;
  return proto.api.AddVehicleFromCSVResponse.ErrorMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddVehicleFromCSVResponse.ErrorMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddVehicleFromCSVResponse.ErrorMessage}
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddVehicleFromCSVResponse.ErrorMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddVehicleFromCSVResponse.ErrorMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string error_message = 1;
 * @return {string}
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleFromCSVResponse.ErrorMessage} returns this
 */
proto.api.AddVehicleFromCSVResponse.ErrorMessage.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string success_message = 1;
 * @return {string}
 */
proto.api.AddVehicleFromCSVResponse.prototype.getSuccessMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddVehicleFromCSVResponse} returns this
 */
proto.api.AddVehicleFromCSVResponse.prototype.setSuccessMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ErrorMessage error_messages = 2;
 * @return {!Array<!proto.api.AddVehicleFromCSVResponse.ErrorMessage>}
 */
proto.api.AddVehicleFromCSVResponse.prototype.getErrorMessagesList = function() {
  return /** @type{!Array<!proto.api.AddVehicleFromCSVResponse.ErrorMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AddVehicleFromCSVResponse.ErrorMessage, 2));
};


/**
 * @param {!Array<!proto.api.AddVehicleFromCSVResponse.ErrorMessage>} value
 * @return {!proto.api.AddVehicleFromCSVResponse} returns this
*/
proto.api.AddVehicleFromCSVResponse.prototype.setErrorMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.AddVehicleFromCSVResponse.ErrorMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AddVehicleFromCSVResponse.ErrorMessage}
 */
proto.api.AddVehicleFromCSVResponse.prototype.addErrorMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.AddVehicleFromCSVResponse.ErrorMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AddVehicleFromCSVResponse} returns this
 */
proto.api.AddVehicleFromCSVResponse.prototype.clearErrorMessagesList = function() {
  return this.setErrorMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateRegistrationPlateNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateRegistrationPlateNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateRegistrationPlateNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    registrationPlateNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateRegistrationPlateNumberRequest}
 */
proto.api.UpdateRegistrationPlateNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateRegistrationPlateNumberRequest;
  return proto.api.UpdateRegistrationPlateNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateRegistrationPlateNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateRegistrationPlateNumberRequest}
 */
proto.api.UpdateRegistrationPlateNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationPlateNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateRegistrationPlateNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateRegistrationPlateNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateRegistrationPlateNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegistrationPlateNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateRegistrationPlateNumberRequest} returns this
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string registration_plate_number = 2;
 * @return {string}
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.getRegistrationPlateNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateRegistrationPlateNumberRequest} returns this
 */
proto.api.UpdateRegistrationPlateNumberRequest.prototype.setRegistrationPlateNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateRegistrationPlateNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateRegistrationPlateNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateRegistrationPlateNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateRegistrationPlateNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.api.VehicleItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateRegistrationPlateNumberResponse}
 */
proto.api.UpdateRegistrationPlateNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateRegistrationPlateNumberResponse;
  return proto.api.UpdateRegistrationPlateNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateRegistrationPlateNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateRegistrationPlateNumberResponse}
 */
proto.api.UpdateRegistrationPlateNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.VehicleItem;
      reader.readMessage(value,proto.api.VehicleItem.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateRegistrationPlateNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateRegistrationPlateNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateRegistrationPlateNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateRegistrationPlateNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.VehicleItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleItem vehicle = 1;
 * @return {?proto.api.VehicleItem}
 */
proto.api.UpdateRegistrationPlateNumberResponse.prototype.getVehicle = function() {
  return /** @type{?proto.api.VehicleItem} */ (
    jspb.Message.getWrapperField(this, proto.api.VehicleItem, 1));
};


/**
 * @param {?proto.api.VehicleItem|undefined} value
 * @return {!proto.api.UpdateRegistrationPlateNumberResponse} returns this
*/
proto.api.UpdateRegistrationPlateNumberResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateRegistrationPlateNumberResponse} returns this
 */
proto.api.UpdateRegistrationPlateNumberResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateRegistrationPlateNumberResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GenerateVehicleRIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GenerateVehicleRIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GenerateVehicleRIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenerateVehicleRIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GenerateVehicleRIDRequest}
 */
proto.api.GenerateVehicleRIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GenerateVehicleRIDRequest;
  return proto.api.GenerateVehicleRIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GenerateVehicleRIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GenerateVehicleRIDRequest}
 */
proto.api.GenerateVehicleRIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GenerateVehicleRIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GenerateVehicleRIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GenerateVehicleRIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenerateVehicleRIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.GenerateVehicleRIDRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenerateVehicleRIDRequest} returns this
 */
proto.api.GenerateVehicleRIDRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GenerateVehicleRIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GenerateVehicleRIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GenerateVehicleRIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenerateVehicleRIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GenerateVehicleRIDResponse}
 */
proto.api.GenerateVehicleRIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GenerateVehicleRIDResponse;
  return proto.api.GenerateVehicleRIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GenerateVehicleRIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GenerateVehicleRIDResponse}
 */
proto.api.GenerateVehicleRIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GenerateVehicleRIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GenerateVehicleRIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GenerateVehicleRIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenerateVehicleRIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.api.GenerateVehicleRIDResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenerateVehicleRIDResponse} returns this
 */
proto.api.GenerateVehicleRIDResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleListRequest}
 */
proto.api.GetVehicleListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleListRequest;
  return proto.api.GetVehicleListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleListRequest}
 */
proto.api.GetVehicleListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string created_at = 1;
 * @return {string}
 */
proto.api.GetVehicleListRequest.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleListRequest} returns this
 */
proto.api.GetVehicleListRequest.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetVehicleListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleListList: jspb.Message.toObjectList(msg.getVehicleListList(),
    proto.api.GetVehicleListResponse.VehicleListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleListResponse}
 */
proto.api.GetVehicleListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleListResponse;
  return proto.api.GetVehicleListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleListResponse}
 */
proto.api.GetVehicleListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetVehicleListResponse.VehicleListItem;
      reader.readMessage(value,proto.api.GetVehicleListResponse.VehicleListItem.deserializeBinaryFromReader);
      msg.addVehicleList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.GetVehicleListResponse.VehicleListItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetVehicleListResponse.VehicleListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetVehicleListResponse.VehicleListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListResponse.VehicleListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem}
 */
proto.api.GetVehicleListResponse.VehicleListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetVehicleListResponse.VehicleListItem;
  return proto.api.GetVehicleListResponse.VehicleListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetVehicleListResponse.VehicleListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem}
 */
proto.api.GetVehicleListResponse.VehicleListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetVehicleListResponse.VehicleListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetVehicleListResponse.VehicleListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetVehicleListResponse.VehicleListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem} returns this
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem} returns this
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_name = 3;
 * @return {string}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem} returns this
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_active = 4;
 * @return {boolean}
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem} returns this
 */
proto.api.GetVehicleListResponse.VehicleListItem.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated VehicleListItem vehicle_list = 1;
 * @return {!Array<!proto.api.GetVehicleListResponse.VehicleListItem>}
 */
proto.api.GetVehicleListResponse.prototype.getVehicleListList = function() {
  return /** @type{!Array<!proto.api.GetVehicleListResponse.VehicleListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.GetVehicleListResponse.VehicleListItem, 1));
};


/**
 * @param {!Array<!proto.api.GetVehicleListResponse.VehicleListItem>} value
 * @return {!proto.api.GetVehicleListResponse} returns this
*/
proto.api.GetVehicleListResponse.prototype.setVehicleListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.GetVehicleListResponse.VehicleListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.GetVehicleListResponse.VehicleListItem}
 */
proto.api.GetVehicleListResponse.prototype.addVehicleList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.GetVehicleListResponse.VehicleListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetVehicleListResponse} returns this
 */
proto.api.GetVehicleListResponse.prototype.clearVehicleListList = function() {
  return this.setVehicleListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetTotalVehicleMileageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetTotalVehicleMileageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetTotalVehicleMileageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalVehicleMileageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleDeviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetTotalVehicleMileageRequest}
 */
proto.api.GetTotalVehicleMileageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetTotalVehicleMileageRequest;
  return proto.api.GetTotalVehicleMileageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetTotalVehicleMileageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetTotalVehicleMileageRequest}
 */
proto.api.GetTotalVehicleMileageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetTotalVehicleMileageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetTotalVehicleMileageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetTotalVehicleMileageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalVehicleMileageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_device_id = 1;
 * @return {string}
 */
proto.api.GetTotalVehicleMileageRequest.prototype.getVehicleDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetTotalVehicleMileageRequest} returns this
 */
proto.api.GetTotalVehicleMileageRequest.prototype.setVehicleDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetTotalVehicleMileageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetTotalVehicleMileageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetTotalVehicleMileageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalVehicleMileageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalMileage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetTotalVehicleMileageResponse}
 */
proto.api.GetTotalVehicleMileageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetTotalVehicleMileageResponse;
  return proto.api.GetTotalVehicleMileageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetTotalVehicleMileageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetTotalVehicleMileageResponse}
 */
proto.api.GetTotalVehicleMileageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMileage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetTotalVehicleMileageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetTotalVehicleMileageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetTotalVehicleMileageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetTotalVehicleMileageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalMileage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 total_mileage = 1;
 * @return {number}
 */
proto.api.GetTotalVehicleMileageResponse.prototype.getTotalMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetTotalVehicleMileageResponse} returns this
 */
proto.api.GetTotalVehicleMileageResponse.prototype.setTotalMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.api);
