import { Dispatch } from "react";
import { v4 as uuidV4 } from "uuid";
import { Struct, Value } from 'google-protobuf/google/protobuf/struct_pb';
import { setModalName} from "../../store/actions";

import {
    AddBatteriesFromCSVRequest, AddBatteriesFromCSVResponse,
    AddBatteryModelRequest,
    AddBatteryModelResponse,
    AddBatteryRequest,
    AddBatteryResponse,
    BatteryItem,
    BatteryModelItem,
    BatteryUpdateItem,
    DeleteBatteryModelRequest,
    DeleteBatteryModelResponse,
    DeleteBatteryRequest,
    GetBatteriesRequest,
    GetBatteriesResponse,
    GetBatteryModelsRequest,
    GetBatteryModelsResponse,
    UpdateBatteryModelItem,
    UpdateBatteryModelRequest,
    UpdateBatteryModelResponse,
    UpdateBatteryRequest,
    UpdateBatteryResponse
} from "../../protobuf/battery_service_pb";

import { PageRequest, PageResponse } from "protobuf/page_pb";
import {
    CLIENT_CONSIGNEE_ID,
    PAGE_SIZE,
    SUCCESS_MESSAGES,
} from "utils/constants";
import {fileToByteArray, getSecondsFromDate, getValueArrFromFilterOptions} from "utils/helpers";

import {
    batteryManagmentServiceClient,
    batteryModelManagementServiceClient, vehicleManagementServiceClient,
    vehicleModelAdminServiceClient,
    vehicleModelManagementServiceClient
} from "utils/grpc-clients";


import {
    Vehicle,
    FilterItem,
    VehiclesActions,
    VehiclesState,
    CommonActions,
    GetState, VehicleModelState, VehicleModelsActions, VehicleModel,
    BatteryState, BatteryActions, Battery, BatteryModel, BatteryModelActions, BatteryModelState, BatteryHeartbeat
} from "types/store";

import {
    BatteryManagementServiceClient, BatteryModelManagementServiceClient
} from "../../protobuf/Battery_serviceServiceClientPb";
import {
    AddModelManagmentRequest, AddModelManagmentResponse, AddVehicleFromCSVRequest, AddVehicleFromCSVResponse,
    ListModelsAdminRequest,
    ListModelsAdminResponse,
    ModelItem,
    ModelUpdateItem
} from "../../protobuf/vehicle_service_pb";
import {apiResponse} from "./common-actions";
import {setError} from "./common-actions";
import {
    VehicleManagementServiceClient,
    VehicleModelAdminServiceClient,
    VehicleModelManagementServiceClient
} from "../../protobuf/Vehicle_serviceServiceClientPb";
import {initializeVehicleModel, initializeVehicles, setVehicleModels} from "./vehicles-actions";
import {RcFile} from "antd/lib/upload";


export function initializeBatteries(
){

    const request = new GetBatteriesRequest()
    // request.setOwnerOrgId("86ee2476-4ca0-44f0-b73c-3cb07eab2ea5");

    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetBatteriesResponse) {
            const batteries: Battery[] = resp?.getBatteriesList()?.map(battery => ({
                ...(battery.getBasic()?.toObject() as BatteryItem.AsObject),
                make_model: battery.getMakeModel(),
                
            }));
            console.log("Batteries from DB: ", batteries)
            dispatch(setBatteries(batteries))
        }
        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                GetBatteriesRequest,
                GetBatteriesResponse
                >({
                client: batteryManagmentServiceClient,
                action: "getBatteries",
                request,
                dispatcher,
            })
        );
    };
}


//Add New Battery
export function addBattery({
    battery_id,
    operational_state,
    host_id,
    battery_model_id,
    client_org_id,
    battery_alias,
}: {
    battery_id:string;
    operational_state:string;
    host_id:string,
    battery_model_id: string;
    client_org_id:string;
    battery_alias:string;
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();

        const batteryUpdateItem = new BatteryUpdateItem();
        batteryUpdateItem.setBatteryId(battery_id);
        batteryUpdateItem.setOperationalState(operational_state)
        batteryUpdateItem.setHostId(host_id)
        batteryUpdateItem.setBatteryModelId(battery_model_id);
        batteryUpdateItem.setClientOrgId(client_org_id);
        batteryUpdateItem.setBatteryAlias(battery_alias);

        const request = new AddBatteryRequest()

        request.setBattery(batteryUpdateItem)
        function dispatcher() {
            dispatch(initializeBatteries());
        }

        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                AddBatteryRequest,
                AddBatteryResponse
                >({
                request,
                client: batteryManagmentServiceClient,
                action: "addBattery",
                successMessage: SUCCESS_MESSAGES.addBattery,
                dispatcher,
            })
        );
    };
}
//end add new battery
//Add batteries from csv
export function uploadCsvBatteries(file: RcFile) {
    return async (dispatch: Dispatch<any>, getState: GetState) => {
        const bytesData = await fileToByteArray(file);
        const request = new AddBatteriesFromCSVRequest();
        request.setFileName(file.name);
        request.setFileExt(file.type)
        request.setFile(bytesData);

        function dispatcher(resp: AddBatteriesFromCSVResponse) {
            const errorMessages: string  = resp.getErrorMessagesList().map(error => error.getErrorMessage()).join('- ');
            if (errorMessages) {
                dispatch(setError(errorMessages));
                dispatch(setModalName("display_error"));

            } else {
                dispatch(initializeBatteries());
            }
        }

        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                AddBatteriesFromCSVRequest,
                AddBatteriesFromCSVResponse
            >({
                request,
                client: batteryManagmentServiceClient,
                action: "addBatteriesFromCSV",
                successMessage: SUCCESS_MESSAGES.uploadCsv,
                dispatcher,
            })
        );
    };
}
// end

//Update Battery
export function updateBattery({
                                id,
                               battery_id,
                              battery_model_id,
                           }: {
    id:string;
    battery_id:string;
    battery_model_id:string,
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();

        const batteryUpdateItem = new BatteryUpdateItem();
        batteryUpdateItem.setBatteryId(battery_id);
        batteryUpdateItem.setBatteryModelId(battery_model_id);



        const request = new UpdateBatteryRequest()

        request.setBattery(batteryUpdateItem)
        request.setId(id)
        function dispatcher() {
            dispatch(initializeBatteries());
        }

        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                UpdateBatteryRequest,
                UpdateBatteryResponse
                >({
                request,
                client: batteryManagmentServiceClient,
                action: "updateBattery",
                successMessage: SUCCESS_MESSAGES.updateBattery,
                dispatcher,
            })
        );
    };
}
//end update battery

//delete battery
export function deleteBattery({
                                       id,
                                   }: {
    id:string;
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();





        const request = new DeleteBatteryRequest()
        request.setId(id)
        function dispatcher() {
            dispatch(initializeBatteries());
        }

        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                DeleteBatteryRequest,
                DeleteBatteryRequest
                >({
                request,
                client: batteryManagmentServiceClient,
                action: "deleteBattery",
                successMessage: SUCCESS_MESSAGES.deleteBattery,
                dispatcher,
            })
        );
    };
}
//end delete battery


export function setBatteryPage(
    payload: PageResponse.AsObject
): BatteryActions {
    return {
        type: "SET_BATTERY_TOTAL_PAGE",
        payload,
    };
}

export function setBatteries(
    payload: Battery[]
): BatteryActions {
    return {
        type: "SET_BATTERIES",
        payload,
    };
}

export function setSelectedBattery(
    payload: BatteryState["selectedBattery"]
): BatteryActions {
    return {
        type: "SET_SELECTED_BATTERY",
        payload,
    };
}


////Battery Model Actions Start //////

export function initializeBatteryModels(
){

    const request = new GetBatteryModelsRequest()


    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetBatteryModelsResponse) {
            const batteryModels: BatteryModel[] = resp?.getBatteryModelList()?.map(batteryModel => ({
                ...(batteryModel.getBasic()?.toObject() as BatteryModelItem.AsObject),
                ownerOrgName: "test",
            }));
            console.log("Batteries from DB: ", batteryModels)
            dispatch(setBatteryModels(batteryModels))
        }
        dispatch(
            apiResponse<
                BatteryModelManagementServiceClient,
                GetBatteryModelsRequest,
                GetBatteryModelsResponse
                >({
                client: batteryModelManagementServiceClient,
                action: "getBatteryModels",
                request,
                dispatcher,
            })
        );
    };
}

export function setBatteryModels(
    payload: BatteryModel[]
): BatteryModelActions {
    return {
        type: "SET_BATTERY_MODELS",
        payload,
    };
}

export function setBatteryModelsDropdown(
    payload: BatteryModel[]
): BatteryModelActions {
    return {
        type: "SET_BATTERY_MODEL_DROPDOWN",
        payload,
    };
}

export function setSelectedBatteryModel(
    payload: BatteryModelState["selectedBatteryModel"]
): BatteryModelActions {
    return {
        type: "SET_SELECTED_BATTERY_MODEL",
        payload,
    };
}

export function addBatteryModel({
                                       model,
                                       make,
                                       year,
                                   }: {
    model:string;
    make:string;
    year:number;
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();

        const batteryModelUpdateItem = new UpdateBatteryModelItem();
        batteryModelUpdateItem.setModel(model);
        batteryModelUpdateItem.setMake(make);
        batteryModelUpdateItem.setYear(year);



        const request = new AddBatteryModelRequest()

        request.setBatteryModel(batteryModelUpdateItem)
        function dispatcher() {
            dispatch(initializeBatteryModels());
        }

        dispatch(
            apiResponse<
                BatteryModelManagementServiceClient,
                AddBatteryModelRequest,
                AddBatteryModelResponse
                >({
                request,
                client: batteryModelManagementServiceClient,
                action: "addBatteryModel",
                successMessage: SUCCESS_MESSAGES.addBatteryModel,
                dispatcher,
            })
        );
    };
}


export function updateBatteryModel({
                                  id,
                                  model,
                                  make,
                                  year,
                              }: {
    id:string;
    model:string;
    make:string;
    year:number;
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();

        const batteryModelUpdateItem = new UpdateBatteryModelItem();
        batteryModelUpdateItem.setModel(model);
        batteryModelUpdateItem.setMake(make);
        batteryModelUpdateItem.setYear(year);



        const request = new UpdateBatteryModelRequest()

        request.setBatteryModel(batteryModelUpdateItem)
        request.setId(id)
        function dispatcher() {
            dispatch(initializeBatteryModels());
        }

        dispatch(
            apiResponse<
                BatteryModelManagementServiceClient,
                UpdateBatteryModelRequest,
                UpdateBatteryModelResponse
                >({
                request,
                client: batteryModelManagementServiceClient,
                action: "updateBatteryModel",
                successMessage: SUCCESS_MESSAGES.updateBatteryModel,
                dispatcher,
            })
        );
    };
}


export function deleteBatteryModel({
                                    id,
                                }: {
    id:string;
}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const {
            models: { page, filters },
        } = getState();





        const request = new DeleteBatteryModelRequest()
        request.setId(id)
        function dispatcher() {
            dispatch(initializeBatteryModels());
        }

        dispatch(
            apiResponse<
                BatteryModelManagementServiceClient,
                DeleteBatteryModelRequest,
                DeleteBatteryModelResponse
                >({
                request,
                client: batteryModelManagementServiceClient,
                action: "deleteBatteryModel",
                successMessage: SUCCESS_MESSAGES.deleteBatteryModel,
                dispatcher,
            })
        );
    };
}


export function getBatteryModelsDropdown(
){

    const request = new GetBatteryModelsRequest()
    //request.setOwnerOrg("2d0978ac-aea6-44d4-812e-cdaf26d79862");

    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetBatteryModelsResponse) {
            const batteryModels: BatteryModel[] = resp?.getBatteryModelList()?.map(batteryModel => ({
                ...(batteryModel.getBasic()?.toObject() as BatteryModelItem.AsObject),
                ownerOrgName: "test",
            }));
            console.log("Batteries from DB: ", batteryModels)
            dispatch(setBatteryModelsDropdown(batteryModels))
        }
        dispatch(
            apiResponse<
                BatteryModelManagementServiceClient,
                GetBatteryModelsRequest,
                GetBatteryModelsResponse
                >({
                client: batteryModelManagementServiceClient,
                action: "getBatteryModels",
                request,
                dispatcher,
            })
        );
    };
}

////End Battery Model Actions ////////
