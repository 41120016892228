
import { Dispatch } from "react";
import { v4 as uuidV4 } from "uuid";
import { Struct, Value } from 'google-protobuf/google/protobuf/struct_pb';
import {setError} from "./common-actions";
import { setModalName} from "../../store/actions";
import {
    AddVehicleRequest,
    ListModelsManagementRequest,
    ModelItem,
    ModelUpdateItem,
    UpdateVehicleItem,
    VehicleItem,
    ListVehiclesManagementRequest,
    UploadCsvRequest,
    ListVehiclesManagementResponse,
    ListModelsManagementResponse,
    AddVehicleResponse,
    UploadCsvResponse,
    DeleteVehicleManagementRequest,
    DeleteVehicleManagementResponse,
    UpdateVehicleRequest,
    UpdateVehicleResponse,
    ListDropDownVehiclesRequest,
    ListDropDownVehiclesResponse,
    GetVehicleManagementRequest,
    GetVehicleManagementResponse,
    UpdateVehicleFirmwareVersionRequest,
    UpdateVehicleFirmwareVersionResponse,
    UploadVehicleFirmwareToSpacesRequest,
    UploadVehicleFirmwareToSpacesResponse,
    ListModelsAdminRequest,
    ListModelsAdminResponse,
    AddModelManagmentRequest,
    AddModelManagmentResponse,
    UpdateModelRequest,
    UploadFirmwareToModelRequest,
    UploadFirmwareToModelResponse,
    UpdateFirmwareVersionToModelRequest,
    UpdateFirmwareVersionToModelResponse,
    DeleteModelRequest,
    DeleteModelResponse,
    UpdateBulkVehicleFirmwareVersionRequest,
    UpdateBulkVehicleFirmwareVersionResponse,
    VehicleModelFileItem,
    UpdateMqttServerByVehicleRequest,
    UpdateMqttServerByVehicleResponse,
    ListFirmwaresRequest,
    ListFirmwaresResponse,
    UpdateFirmwareOnVehiclesRequest,
    UpdateFirmwareOnVehiclesResponse,
    FirmwareItem, AddVehicleFromCSVRequest, AddVehicleFromCSVResponse
} from "protobuf/vehicle_service_pb";
import { PageRequest, PageResponse } from "protobuf/page_pb";
import { GetVehicleLocationHistoryByVehicleIdRequest, GetVehicleLocationHistoryByVehicleIdResponse, VehicleLocationHistoryItem } from "protobuf/log_service_pb";
import {
  CLIENT_CONSIGNEE_ID,
  PAGE_SIZE,
  SUCCESS_MESSAGES,
} from "utils/constants";
import {fileToByteArray, getSecondsFromDate, getValueArrFromFilterOptions} from "utils/helpers";
import {
  ownerOrgManagementServiceClient, userManagementServiceClient,
  vehicleManagementServiceClient,
  vehicleModelManagementServiceClient,
  vehicleModelAdminServiceClient, issueManagementServiceClient, logServiceClient,
} from "utils/grpc-clients";
import {
    Vehicle,
    FilterItem,
    VehiclesActions,
    VehiclesState,
    CommonActions,
    GetState, VehicleModelState, VehicleModelsActions, VehicleModel, MapMarkerActions, markerObject, VehicleLogHistory,
} from "types/store";
import { Filters } from "protobuf/filter_pb";
import { apiResponse } from "./common-actions";
import { RcFile } from "antd/lib/upload";
import {
  VehicleManagementServiceClient,
  VehicleModelManagementServiceClient,
  VehicleModelAdminServiceClient,
} from "protobuf/Vehicle_serviceServiceClientPb";
import { OwnerOrgManagementServiceClient } from "protobuf/Client_serviceServiceClientPb";
import {
  OwnerOrgItem,
  ListOwnerOrgAdminResponse,
  ListOwnerOrgManagementRequest, NullableClientOrgId,
} from "protobuf/client_service_pb";
import {
  AddUserManagementRequest,
  AddUserManagementResponse,
  PhoneNumber,
  UserUpdateItem
} from "../../protobuf/user_service_pb";
import {UserManagementServiceClient} from "../../protobuf/User_serviceServiceClientPb";
import {dispatchUsers} from "./users-actions";
import {
  AddIssueManagementRequest,
  AddIssueManagementResponse,
  IssueType,
  IssueUpdateItem
} from "../../protobuf/issue_service_pb";
import {IssueManagementServiceClient} from "../../protobuf/Issue_serviceServiceClientPb";
import {initializeIssues} from "./issues-actions";
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import { LogServiceClient } from "protobuf/Log_serviceServiceClientPb";
import {ChangeMqttServerRequest} from "../../protobuf/device_operations_pb";


interface FirmwarePayload {
  document: Uint8Array | string;
  documentName: string;
  documentExt: string;
}
interface ModelFirmwarePayload {
  document: Uint8Array | string;
  documentName: string;
  documentExt: string;
  model_id: string;
  firmware_version: string;
  md_hash: string;
}
interface updateModelFirmwarePayload {
  model_id: string;
}

function getFilterState(data: FilterItem, type: "full" | "empty" = "full") {
  if (type === "empty") {
    const emptyObj: any = {};
    Object.keys(data).forEach((filterName) => {
      emptyObj[filterName] = [];
    });
    return emptyObj as FilterItem;
  }

  return data;
}

export function setVehicles(payload: Vehicle[]): VehiclesActions {
  return {
    type: "SET_VEHICLES_TABLE",
    payload,
  };
}

export function setDropdownVehicles(
  payload: VehiclesState["dropdownVehicles"]
): VehiclesActions {
  return {
    type: "SET_DROPDOWN_VEHICLES",
    payload,
  };
}

export function setDropdownFirmwares(
  payload: VehiclesState["dropdownFirmwares"]
): VehiclesActions {
  return {
    type: "SET_DROPDOWN_FIRMWARE",
    payload,
  };
}

export function setClients(payload: VehiclesState["clients"]): VehiclesActions {
  return {
    type: "SET_VEHICLE_CLIENTS",
    payload,
  };
}

export function setVehiclesPage(
  payload: PageResponse.AsObject
): VehiclesActions {
  return {
    type: "SET_VEHICLES_TOTAL_PAGE",
    payload,
  };
}



export function setVehiclesSelectedPage(payload: number): VehiclesActions {
  return {
    type: "SET_VEHICLES_SELECTED_PAGE",
    payload,
  };
}

export function setSelectedVehicle(
  payload: VehiclesState["selectedVehicle"]
): VehiclesActions {
  return {
    type: "SET_SELECTED_VEHICLE",
    payload,
  };
}

export function setSelectedVehicles(
    payload: (Vehicle | null)[]
): { payload: (Vehicle | null)[]; type: string } {
    return {
        type: "SET_SELECTED_VEHICLES",
        payload,
    };
}

export function setSelectedVehicleModel(
    payload: VehicleModelState["selectedModels"]
): VehicleModelsActions {
  return {
    type: "SET_SELECTED_VEHICLE_MODEL",
    payload,
  };
}

export function updateVehiclesFilters(
  payload: VehiclesState["filters"]["updated"]
): VehiclesActions {
  return {
    type: "SET_VEHICLES_UPDATED_FILTERS",
    payload,
  };
}

export function setVehiclesSearch(
  payload: VehiclesState["search"]
): VehiclesActions {
  return {
    type: "SET_VEHICLE_SEARCH",
    payload,
  };
}

export function setBulkActionsVehicles(
  payload: VehiclesState["showbulkActionsVehicles"]
): VehiclesActions {
  return {
    type: "SET_VEHICLES_BULK_ACTIONS",
    payload,
  };
}



export function setBulkUpdateFirmwareActionsVehicles(
    payload: VehiclesState["vehicles"]
): VehiclesActions {
  return {
    type: "SET_VEHICLES_BULK_UPDATE",
    payload,
  };
}

export function setOriginalFilters(
  payload: VehiclesState["filters"]["original"]
): VehiclesActions {
  return {
    type: "SET_VEHICLES_ORIGINAL_FILTERS",
    payload,
  };
}

export function setVehicleStateEmpty():VehiclesActions {
    return {
        type: "SET_VEHICLE_EMPTY",
        payload:undefined,
    }
}

/////////Vehicle Model Action ////////

export function initializeVehicleModel(
){

  const request = new ListModelsAdminRequest()

  return async (dispatch: Dispatch<any>) => {
    function dispatcher(resp: ListModelsAdminResponse) {
        console.log("ListModelsAdminResponse Response : ", ListModelsAdminResponse)
      const modelList: VehicleModel[] = resp?.getModelsList()?.map(model => ({
          ...(model.getBasic()?.toObject() as ModelItem.AsObject),
          firmwareVersion: model.getFirmwareVersion() || "-",
      }));

      /*
      *  const vehicles: Vehicle[] = resp?.getVehiclesList().map((vehicle) => ({
        ...(vehicle.getVehicle()?.toObject() as VehicleItem.AsObject),
        point: vehicle.getLocation()?.toObject(),
        make: vehicle.getModelMake(),
        model: vehicle.getModelName(),
        battery: vehicle.getBatteryLevel(),
        assignee: vehicle
          .getCurrentlyAssignedToList()
          ?.map((user) => user.toObject()),
        ownerOrgName: vehicle.getOwnerOrgName(),
      }));
      * */
      dispatch(setVehicleModels(modelList))
    }
    dispatch(
        apiResponse<
            VehicleModelAdminServiceClient,
            ListModelsAdminRequest,
            ListModelsAdminResponse
        >({
          client: vehicleModelAdminServiceClient,
          action: "listModels",
          request,
          dispatcher,
        })
    );
  };
}

export function setVehicleModelsPage(
    payload: PageResponse.AsObject
): VehicleModelsActions {
  return {
    type: "SET_VEHICLE_MODEL_TOTAL_PAGE",
    payload,
  };
}

export function setVehicleModels(
    payload: VehicleModel[]
): VehicleModelsActions {
  return {
    type: "SET_VEHICLE_MODEL",
    payload,
  };
}

export function addVehicleModel({
  model,
  make,
  year,
  document,
  documentName,
  documentExt,
    modelImages,
 }: {
  model:string;
  make:string;
  year:number;
    document: Uint8Array | string;
    documentName: string;
    documentExt: string;
    modelImages:RcFile[];
}) {

  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      models: { page, filters },
    } = getState();

    const modelUpdateItem = new ModelUpdateItem()
    modelUpdateItem.setName(model);
    modelUpdateItem.setMake(make);
    modelUpdateItem.setYear(year);

    let modelImagesArray = []

      for(let i = 0; i<modelImages.length; i++) {
          const modelImageItem = new VehicleModelFileItem()
          let file = modelImages[i]
          const unit8Arr = await fileToByteArray(file);
          modelImageItem.setFile(unit8Arr)
          modelImageItem.setFileName(file.name)
          modelImageItem.setFileExt(file.type)

          modelImagesArray.push(modelImageItem)

      }
      modelUpdateItem.setModelImagesList(modelImagesArray)

    const struct = new Struct();
    struct.getFieldsMap().set('foo', Value.fromJavaScript('bar'));
    modelUpdateItem.setProperties(struct)

    const request = new AddModelManagmentRequest()
    request.setModel(modelUpdateItem)
    function dispatcher() {
      dispatch(initializeVehicleModel());
    }

    dispatch(
        apiResponse<
            VehicleModelManagementServiceClient,
            AddModelManagmentRequest,
            AddModelManagmentResponse
            >({
          request,
          client: vehicleModelManagementServiceClient,
          action: "addModel",
          successMessage: SUCCESS_MESSAGES.addVehicleModel,
          dispatcher,
        })
    );
  };
}

export function editVehicleModel(
    newVehicleModel: Partial<ModelUpdateItem.AsObject> & {
      id: string;
      ownerOrgId: string;
    }
) {
  const {
      make = "",
      name = "",
      year = 2023,
      ownerOrgId,
      id,

  } = newVehicleModel;

  const vehicleModel = new ModelUpdateItem();
  vehicleModel.setName(name);
  vehicleModel.setMake(make);
  vehicleModel.setYear(year);

  const struct = new Struct();
  struct.getFieldsMap().set('foo', Value.fromJavaScript('bar'));
  vehicleModel.setProperties(struct)

  console.log("Update Model : ", vehicleModel);

  const request = new UpdateModelRequest()
  request.setModel(vehicleModel)
  request.setId(id)
  console.log("current Request : ", request)

  return (dispatch: any, getState: GetState) => {
    const {
      models: { page, filters },
    } = getState();

    function dispatcher() {
      dispatch(initializeVehicleModel());
      // dispatch(getVehicle(id));
    }

    dispatch(
        apiResponse<
            VehicleModelAdminServiceClient,
            UpdateModelRequest,
            UpdateVehicleResponse
            >({
          request,
          client: vehicleModelAdminServiceClient,
          action: "updateModel",
          successMessage: SUCCESS_MESSAGES.updateVehicleModel,
          dispatcher,
          ownerOrgId,
        })
    );
  };
}

//upload firmware to model
export function addFirmwareToModel({
                              document,
                              documentExt,
                              documentName,
                              model_id,
                              md_hash,
                              firmware_version,
                            }: ModelFirmwarePayload) {
  return (dispatch: Dispatch<any>, getState: GetState) => {

    const request = new UploadFirmwareToModelRequest();

    request.setFile(document);
    request.setFileExtension(documentExt);
    request.setFileName(documentName);
    request.setModelId(model_id)
    request.setFirmwareVersion(firmware_version)

     function dispatcher() {
          dispatch(initializeVehicleModel());
     }
    dispatch(
        apiResponse<
            VehicleModelAdminServiceClient,
            UploadFirmwareToModelRequest,
            UploadFirmwareToModelResponse
            >({
          client: vehicleModelAdminServiceClient,
          action: "uploadFirmwareToModel",
          request,
          successMessage: SUCCESS_MESSAGES.modelFirmwareUpload,
          modalAction: true,
          dispatcher,
        })
    );
  };
}

//update firmware version
export function updateModelFirmware({
                                      model_id,
                                    }: updateModelFirmwarePayload) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const request = new UpdateFirmwareVersionToModelRequest()
    request.setModelId(model_id);
    dispatch(
        apiResponse<
            VehicleModelAdminServiceClient,
            UpdateFirmwareVersionToModelRequest,
            UpdateFirmwareVersionToModelResponse
            >({
          client: vehicleModelAdminServiceClient,
          action: "updateFirmwareVersionToModel",
          request,
          successMessage: SUCCESS_MESSAGES.modelUpdateFirmware,
          modalAction: true,
        })
    )
  };
}

//delete vehicle model
export function deleteVehicleModel({
                                      model_id,
                                    }: updateModelFirmwarePayload) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const request = new DeleteModelRequest()
    request.setId(model_id)
    function dispatcher() {
      dispatch(initializeVehicleModel());
    }
    dispatch(
        apiResponse<
            VehicleModelAdminServiceClient,
            DeleteModelRequest,
            DeleteModelResponse
            >({
          client: vehicleModelAdminServiceClient,
          action: "deleteModel",
          request,
          successMessage: SUCCESS_MESSAGES.deleteVehicleModel,
          modalAction: true,
          dispatcher,
        })
    )
  };
}




/////////END vehicle Model Action ////////

export function setDynamicFilters(models: VehiclesState["models"]) {
  return (dispatch: Dispatch<VehiclesActions>, getState: GetState) => {
    const { original } = getState().vehicles.filters;
    const modelsFilters =
      models?.map((model) => ({
        label: model.name,
        value: model.name,
      })) || [];
      console.log("setDynamicFilters modelsFilters :",modelsFilters);
    dispatch(setOriginalFilters({ ...original, Model: modelsFilters }));
  };
}

export function setClientDynamicFilters(clients: VehiclesState["clients"]) {
  return (dispatch: Dispatch<VehiclesActions>, getState: GetState) => {
    const { original } = getState().vehicles.filters;
    const clientsFilters =
      clients?.map((client) => ({
        label: client.name,
        value: client.id,
      })) || [];

    dispatch(setOriginalFilters({ ...original, Client: clientsFilters }));
  };
}

export function getVehiclesLocations(
  pageNo: number = 1,
  updatedFilters: FilterItem,
  isMap: boolean = false,
  triggerLoading: boolean = true
) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const { search } = getState().vehicles;

    const modelFilterList = new Filters.In();
    modelFilterList.setStringsList(
      getValueArrFromFilterOptions("Model", updatedFilters)
    );

    const functionalStatus = new Filters.In();
    functionalStatus.setStringsList(
      getValueArrFromFilterOptions("Functional Status", updatedFilters)
    );

    const filters = new ListVehiclesManagementRequest.Filter();
    filters.setModelName(modelFilterList);
    filters.setStatus(functionalStatus);
    filters.setSearchString(search);

    const sort = new PageRequest.Sort();
    sort.setField("added_at");
    sort.setOrder(1);

    const page = new PageRequest();
    page.setPageSize(PAGE_SIZE);
    page.setPageNumber(pageNo);
    page.setSort(sort);

    const request = new ListVehiclesManagementRequest();
    request.setPage(page);
    request.setFilter(filters);
    request.setIsMap(isMap);
    console.log("request : ", request.toObject())
    function dispatcher(resp: ListVehiclesManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const vehicles: Vehicle[] = resp?.getVehiclesList().map((vehicle) => ({
        ...(vehicle.getVehicle()?.toObject() as VehicleItem.AsObject),
        point: vehicle.getLocation()?.toObject(),
        make: vehicle.getModelMake(),
        model: vehicle.getModelName(),
        battery: vehicle.getBatteryLevel(),
        assignee: vehicle
          .getCurrentlyAssignedToList()
          ?.map((user) => user.toObject()),
        ownerOrgName: vehicle.getOwnerOrgName(),
          zone_name: vehicle.getZoneName(),
          zone_id: vehicle.getVehicle()?.getZoneId(),
          locationUpdatedAt: vehicle.getUpdatedAtLocation(),
      }));
      return vehicles;
    }

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        ListVehiclesManagementRequest,
        ListVehiclesManagementResponse
      >({
        request,
        dispatcher,
        client: vehicleManagementServiceClient,
        action: "listClientVehicles",
        ownerOrgId: "",
        triggerLoading,
      })
    );
  };
}


//get vehicle location history
export function getVehicleLocationHistory(
  {
  id,
  pageNo = 1
}: { id:string,  pageNo: number}){  
  return (dispatch: Dispatch<any>, getState: GetState) => {

    const page = new PageRequest();
    page.setPageSize(PAGE_SIZE);
    page.setPageNumber(pageNo);
   
    var request = new GetVehicleLocationHistoryByVehicleIdRequest();
    request.setVehicleId(id)
    request.setPage(page)
     function dispatcher(resp: GetVehicleLocationHistoryByVehicleIdResponse){
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
          const  VehicleLogHistory = resp.getVehicleLocationsList(); 
         dispatch(setLogsPage(page));     
         dispatch(setVehicleLocationHistory(VehicleLogHistory));
     }
     dispatch(
         apiResponse<
             LogServiceClient,
             GetVehicleLocationHistoryByVehicleIdRequest,
             GetVehicleLocationHistoryByVehicleIdResponse
         >({
             request,
             client: logServiceClient,
             action: "getVehicleLocationHistoryByVehicleId",
             dispatcher
         })
     )
  };
}
//end vehicle location history

export function setLogsPage(payload: PageResponse.AsObject): VehiclesActions {
  return {
    type: "SET_LOGS_TOTAL_PAGE",
    payload,
  };
}

export function setLogsSelectedPage(payload: number): VehiclesActions {
  return {
    type: "SET_LOGS_SELECTED_PAGE",
    payload,
  };
}
export function initializeVehicles(
    pageNo: number = 1,
    updatedFilters: FilterItem,
    isMap: boolean = false,
    triggerLoading: boolean = true
) {
    return async (dispatch: Dispatch<any>, getState: GetState) => {
        const { search } = getState().vehicles;
        console.log("updated filter: ", updatedFilters)
        const modelFilterList = new Filters.In();
        modelFilterList.setStringsList(
            getValueArrFromFilterOptions("Model", updatedFilters)
        );


        const functionalStatus = new Filters.In();
        functionalStatus.setStringsList(
            getValueArrFromFilterOptions("Functional Status", updatedFilters)
        );

        console.log("functionalStatus : ", functionalStatus)

        const clientFilterList = new Filters.In();
        clientFilterList.setStringsList(
            getValueArrFromFilterOptions("Client", updatedFilters)
        );

        const serviceStatusFilterList = new Filters.In();
        serviceStatusFilterList.setStringsList(
            getValueArrFromFilterOptions("Service Status", updatedFilters)
        );
        console.log("serviceStatusFilterList : ", serviceStatusFilterList)
        const filters = new ListVehiclesManagementRequest.Filter();
        filters.setModelName(modelFilterList);
        filters.setClientOrgId(clientFilterList);
        filters.setStatus(serviceStatusFilterList);
        filters.setSearchString(search);
        filters.setFunctionalStatus(functionalStatus);

        const sort = new PageRequest.Sort();
        sort.setField("added_at");
        sort.setOrder(1);
        const page = new PageRequest();

      if(!isMap){
        page.setPageSize(PAGE_SIZE);
        page.setPageNumber(pageNo);
        page.setSort(sort);
      }else{
        page.setPageSize(100);
        page.setPageNumber(1);
        page.setSort(sort);
      }
        const request = new ListVehiclesManagementRequest();
        request.setPage(page);
        request.setFilter(filters);
        request.setIsMap(isMap);
        function dispatcher(resp: ListVehiclesManagementResponse) {
            const page = resp?.getPage()?.toObject() || {
                totalElements: 0,
                totalPages: 0,
            };
            const vehicles: Vehicle[] = resp?.getVehiclesList().map((vehicle) => ({
                ...(vehicle.getVehicle()?.toObject() as VehicleItem.AsObject),
                point: vehicle.getLocation()?.toObject(),
                make: vehicle.getModelMake(),
                model: vehicle.getModelName(),
                battery: vehicle.getBatteryLevel(),
                assignee: vehicle
                    .getCurrentlyAssignedToList()
                    ?.map((user) => user.toObject()),
                ownerOrgName: vehicle.getOwnerOrgName(),
                zone_name: vehicle.getZoneName(),
                zone_id: vehicle.getVehicle()?.getZoneId(),
                locationUpdatedAt: vehicle.getUpdatedAtLocation(),
            }));

            dispatch(setVehiclesPage(page));
            dispatch(setVehicles(vehicles));
        }

        dispatch(
            apiResponse<
                VehicleManagementServiceClient,
                ListVehiclesManagementRequest,
                ListVehiclesManagementResponse
            >({
                request,
                dispatcher,
                client: vehicleManagementServiceClient,
                action: "listClientVehicles",
                ownerOrgId: "",
                triggerLoading,
            })
        );
    };
}

export function setModels() {
  return (dispatch: Dispatch<any>) => {
    const request = new ListModelsManagementRequest();

    dispatch(
      apiResponse<
        VehicleModelManagementServiceClient,
        ListModelsManagementRequest,
        ListModelsManagementResponse
      >({
        request,
        action: "listModels",
        client: vehicleModelManagementServiceClient,
        dispatcher: (resp) => {
          const list = resp
            .getModelsList()
            .map((item) => item.toObject()?.basic as ModelItem.AsObject);

          dispatch({ type: "SET_MODELS", payload: list });
          dispatch({ type: "SET_VEHICLE_MODEL", payload: list})
        },
      })
    );
  };
}

export function addVehicle(
  newVehicle: Partial<UpdateVehicleItem.AsObject> & { ownerOrgId: string }
) {
  const {
    vin = uuidV4().slice(0, 17),
    licensePlate = "fe-Lplate",
    modelId = "0de62cf5-7cf9-45bb-ad36-b1406a41baa4",
    businessId = uuidV4(),
    deviceIotId = uuidV4(),
    ownerOrgId,
    locationName = "",
    currentlyAssignedToArrayList = [],
      zoneId = "",
  } = newVehicle;

  const vehicle = new UpdateVehicleItem();
  vehicle.setLicensePlate(licensePlate);
  vehicle.setModelId(modelId);
  vehicle.setVin(vin);
  vehicle.setBusinessId(businessId);
  vehicle.setDeviceIotId(deviceIotId);
  vehicle.setCurrentlyAssignedToArrayList(currentlyAssignedToArrayList);
  vehicle.setLocationName(locationName);
  vehicle.setZoneId(zoneId)

  const request = new AddVehicleRequest();
  request.setVehicle(vehicle);
  request.setClientOrgId(CLIENT_CONSIGNEE_ID);
  request.setOwnerOrgId(ownerOrgId)

  return (dispatch: any, getState: GetState) => {
    const {
      vehicles: { page, filters },
    } = getState();

    function dispatcher() {
      dispatch(initializeVehicles(page.selectedPage, filters.updated));
    }

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        AddVehicleRequest,
        AddVehicleResponse
      >({
        request,
        client: vehicleManagementServiceClient,
        action: "addVehicle",
        successMessage: SUCCESS_MESSAGES.addVehicle,
        dispatcher,
        ownerOrgId,
      })
    );
  };
}

export function onChangeVehiclesFilters(
  name: string,
  value: string[],
  type: "all" | "list"
) {
  return (
    dispatch: Dispatch<VehiclesActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      vehicles: {
        filters: { original, updated },
      },
    } = getState();
    if (type === "list") {
      const filteredValues = original[name].filter((filter) =>
        value.includes(filter.value)
      );
      const updatedFilters = { ...updated, [name]: filteredValues };
      console.log("Updated filter: ",updatedFilters)
      dispatch(updateVehiclesFilters(updatedFilters));
    } else {
      const updatedFilters = {
        ...updated,
        [name]: value ? original[name] : [],
      };
      dispatch(updateVehiclesFilters(updatedFilters));
    }
  };
}

export function onChangeRootVehiclesFilters(checked?: boolean) {
  return (
    dispatch: Dispatch<VehiclesActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      vehicles: {
        filters: { original },
      },
    } = getState();
    const updatedFilters = checked
      ? getFilterState(original, "full")
      : getFilterState(original, "empty");

    dispatch(updateVehiclesFilters(updatedFilters));
  };
}

export function uploadCsvVehicles(file: RcFile, token: string) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      vehicles: { page, filters },
    } = getState();

    const bytesData = await fileToByteArray(file);
    const request = new AddVehicleFromCSVRequest();
    request.setFileName(file.name);
    request.setFileExt(file.type)
    request.setFile(bytesData);

    function dispatcher(resp:AddVehicleFromCSVResponse) {
        const errorMessages = resp.getErrorMessagesList().map(error => error.getErrorMessage()).join('- ');
        if (errorMessages) {
            console.error('Error uploading CSV:', errorMessages);
            dispatch(setError(errorMessages));
            dispatch(setModalName("display_error"));

        } else {
          dispatch(initializeVehicles(page.selectedPage, filters.updated));
        }
    }
     
    

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        AddVehicleFromCSVRequest,
        AddVehicleFromCSVResponse
      >({
        request,
        client: vehicleManagementServiceClient,
        action: "addVehicleFromCSV",
        successMessage: SUCCESS_MESSAGES.uploadCsv,
        dispatcher,
      })
    );
  };
}

export function getDropdownVehicles() {
  return (dispatch: Dispatch<any>) => {
    const request = new ListDropDownVehiclesRequest();

    function dispatcher(resp: ListDropDownVehiclesResponse) {
      const vehicles = resp
        ?.getVehiclesList()
        .map(
          (vehicle) => vehicle.getVehicle()?.toObject() as VehicleItem.AsObject
        );
      dispatch(setDropdownVehicles(vehicles));
    }
    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        ListDropDownVehiclesRequest,
        ListDropDownVehiclesResponse
      >({
        request,
        dispatcher,
        action: "listAllClientVehicles",
        client: vehicleManagementServiceClient,
        triggerLoading: false,
      })
    );
  };
}

export function getDropdownFirmwares() {
  return (dispatch: Dispatch<any>) => {
    const request = new ListFirmwaresRequest();

    function dispatcher(resp: ListFirmwaresResponse) {
      const firmwares = resp
        ?.getFirmwareList().map(( firmwares)=> firmwares.cloneMessage()?.toObject() as FirmwareItem.AsObject
        );
      dispatch(setDropdownFirmwares(firmwares));
    }
    dispatch(
      apiResponse<
        VehicleModelAdminServiceClient,
        ListFirmwaresRequest,
        ListFirmwaresResponse
      >({
        request,
        dispatcher,
        action: "listFirmwares",
        client: vehicleModelAdminServiceClient,
        triggerLoading: false,
      })
    );
  };
}

export function updateFirmwareOnVehicles(firmware: string, vehicles: string [] ) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      page: { selectedPage },
      filters: { updated },
    } = getState().vehicles;
    const request = new UpdateFirmwareOnVehiclesRequest();
    request.setVehicleIdsList(vehicles)
    request.setFirmwareId(firmware)

    function dispatcher() {
      dispatch(initializeVehicles(selectedPage, updated, false, true));
    }

    dispatch(
        apiResponse<
           VehicleModelAdminServiceClient,
            UpdateFirmwareOnVehiclesRequest,
            UpdateFirmwareOnVehiclesResponse
            >({
          request,
          dispatcher,
          action: "updateFirmwareOnVehicles",
          client: vehicleModelAdminServiceClient,
          successMessage: SUCCESS_MESSAGES.firmwareVehicle,
        })
    );
  };
}

export function getClients() {
  return (dispatch: Dispatch<any>) => {
    const request = new ListOwnerOrgManagementRequest();

    function dispatcher(resp: ListOwnerOrgAdminResponse) {
      const clients: VehiclesState["clients"] = resp
        .getOwnerOrgsList()
        .map(
          (owner) => owner.toObject()?.ownerOrg as OwnerOrgItem.AsObject
        );

      dispatch(setClients(clients));
    }

    dispatch(
      apiResponse<
        OwnerOrgManagementServiceClient,
        ListOwnerOrgManagementRequest,
        ListOwnerOrgAdminResponse
      >({
        request,
        client: ownerOrgManagementServiceClient,
        action: "listOwnerOrgs",
        dispatcher,
      })
    );
  };
}

export function deleteVehicle(id: string) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      page: { selectedPage },
      filters: { updated },
    } = getState().vehicles;
    const request = new DeleteVehicleManagementRequest();
    request.setId(id);

    function dispatcher() {
      dispatch(initializeVehicles(selectedPage, updated, false, true));
    }

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        DeleteVehicleManagementRequest,
        DeleteVehicleManagementResponse
      >({
        request,
        dispatcher,
        action: "deleteVehicle",
        client: vehicleManagementServiceClient,
        successMessage: SUCCESS_MESSAGES.deleteVehicle,
      })
    );
  };
}

export function updateFirmwareVehicle(id: string) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      page: { selectedPage },
      filters: { updated },
    } = getState().vehicles;
    const request = new UpdateBulkVehicleFirmwareVersionRequest();
    request.setVehicleId(id)

    function dispatcher() {
      dispatch(initializeVehicles(selectedPage, updated, false, true));
    }

    dispatch(
        apiResponse<
            VehicleManagementServiceClient,
            UpdateBulkVehicleFirmwareVersionRequest,
            UpdateBulkVehicleFirmwareVersionResponse
            >({
          request,
          dispatcher,
          action: "updateBulkVehicleFirmwareVersion",
          client: vehicleManagementServiceClient,
          successMessage: SUCCESS_MESSAGES.firmwareVehicle,
        })
    );
  };
}

export function editVehicle(
  newVehicle: Partial<UpdateVehicleItem.AsObject> & {
    id: string;
    ownerOrgId: string;
  }
) {
  const {
    vin = uuidV4().slice(0, 17),
    licensePlate = "fe-Lplate",
    modelId = "0de62cf5-7cf9-45bb-ad36-b1406a41baa4",
    businessId = uuidV4(),
    deviceIotId = uuidV4(),
    ownerOrgId,
    currentlyAssignedToArrayList = [],
    id,
    locationName = "",
      zoneId = "",
      vehicleServiceStatus = 0,
  } = newVehicle;

  const vehicle = new UpdateVehicleItem();
  vehicle.setLicensePlate(licensePlate);
  vehicle.setModelId(modelId);
  // vehicle.setVin(vin);
  vehicle.setBusinessId(businessId);
  vehicle.setDeviceIotId(deviceIotId);
  vehicle.setCurrentlyAssignedToArrayList(currentlyAssignedToArrayList);
  vehicle.setLocationName(locationName);
  vehicle.setZoneId(zoneId)
    vehicle.setVehicleServiceStatus(vehicleServiceStatus)
  const request = new UpdateVehicleRequest();
  request.setVehicle(vehicle);

  request.setId(id);

  return (dispatch: any, getState: GetState) => {
    const {
      vehicles: { page, filters },
    } = getState();

    function dispatcher() {
      dispatch(initializeVehicles(page.selectedPage, filters.updated));
      //dispatch(getVehicle(id));
    }

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        UpdateVehicleRequest,
        UpdateVehicleResponse
      >({
        request,
        client: vehicleManagementServiceClient,
        action: "updateVehicle",
        successMessage: SUCCESS_MESSAGES.updateVehicle,
        dispatcher,
        ownerOrgId,
      })
    );
  };
}

export function getVehicle(id: string) {
  const request = new GetVehicleManagementRequest();
  request.setId(id);

  return (dispatch: Dispatch<any>) => {
    function dispatcher(resp: GetVehicleManagementResponse) {
      const vehicleResponse = resp
        .getVehicle()
        ?.getBasic()
        ?.toObject() as VehicleItem.AsObject;
      const vehicleAttr = resp.getVehicle() as GetVehicleManagementResponse.Item;
      const vehicle = {
        ...vehicleResponse,
        point: vehicleAttr.getLocation()?.toObject(),
        make: vehicleAttr.getModelMake(),
        model: vehicleAttr.getModelName(),
        assignee: vehicleAttr
          .getCurrentlyAssignedToList()
          ?.map((usr) => usr.toObject()),
        ownerOrgName: vehicleAttr.getOwnerOrgName(),
        locationUpdatedAt: vehicleAttr.getUpdatedAtLocation(),
      };

      dispatch(setSelectedVehicle(vehicle));
    }

    dispatch(
      apiResponse<
        VehicleManagementServiceClient,
        GetVehicleManagementRequest,
        GetVehicleManagementResponse
      >({
        request,
        client: vehicleManagementServiceClient,
        action: "getVehicle",
        dispatcher,
      })
    );
  };
}


export function addFirmWare({
                          document,
                          documentExt,
                          documentName,
                        }: FirmwarePayload) {
  return (dispatch: Dispatch<any>, getState: GetState) => {

    const request = new UploadVehicleFirmwareToSpacesRequest();

    request.setFile(document);
    request.setFileExtension(documentExt);
    request.setFileName(documentName);


    dispatch(
        apiResponse<
            VehicleManagementServiceClient,
            UploadVehicleFirmwareToSpacesRequest,
            UploadVehicleFirmwareToSpacesResponse
            >({
          client: vehicleManagementServiceClient,
          action: "uploadVehicleFirmwareToSpaces",
          request,

          successMessage: SUCCESS_MESSAGES.addFirmwareToSpaces,
          modalAction: true,
        })
    );
  };
}

export function setVehicleLocationHistory(
  payload: VehicleLocationHistoryItem[]
): VehiclesActions {
    return {
        type: "SET_VEHICLE_LOCATION_HISTORY",
        payload,
    }
}
//delete vehicle model
export function changeMqttServer({
       mqtt_server,
        vehicle_id,
        port,
    }: {mqtt_server:string, vehicle_id:string, port:string}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        const request = new UpdateMqttServerByVehicleRequest()
        request.setServer(mqtt_server)
        request.setVehicleId(vehicle_id)
        request.setPort(port)
        dispatch(
            apiResponse<
                VehicleManagementServiceClient,
                UpdateMqttServerByVehicleRequest,
                UpdateMqttServerByVehicleResponse
            >({
                client: vehicleManagementServiceClient,
                action: "updateMqttServerByVehicle",
                request,
                successMessage: SUCCESS_MESSAGES.mqtt_server_change,
                modalAction: true,
            })
        )
    };
}


//set map center if change
export function setMapCenter(
    payload: markerObject
): MapMarkerActions {
    console.log("center payload : ",payload)
    return {
        type: "SET_MAP_MARKER",
        payload,
    };
}

//set map center if change
export function setMapCenterChange(
    payload: boolean
): MapMarkerActions {
    return {
        type: "SET_MAP_MARKER_CHANGE",
        payload,
    };
}