import { Button, Dropdown, Menu } from "antd";
import {useCallback, useMemo, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, SwitchButton } from "components/base";
import {Link, useHistory, useLocation} from "react-router-dom";
import {getVehicle, setModalName, setSelectedVehicle, setSelectedVehicleModel, setVehiclesSearch} from "store/actions";
import {
    actionMenu,
    exportIcon,
    plusIcon,
    searchIcon,
    zonesSmallIcon,
    addAssigneeIcon,
    archiveIcon, serviceIcon, uploadIcon, uploadIconSmall, settingsIcon, settingsIcon2, settingsIcon3,
} from "svgs";
import { ROUTES } from "utils/constants";
import { AppState } from "store";

export default function VehiclesHeader() {
  const location = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
    const {
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);

  const selectedView = location.pathname.split("/")[2];
  const isVehicleModel = pathname === ROUTES.vehicle_models_list;
  const { showbulkActionsVehicles } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );
  let defaultTabByUser = userType == 5 ? "map" : "table";

  useEffect(() => {
    dispatch(setVehiclesSearch(""));
  }, [dispatch]);

  const searchValue = useSelector<AppState, string>(
    (state) => state.vehicles.search || ""
  );

  const onAddVehicles = useCallback(() => {
    dispatch(setModalName("add_firmware_vehicle"));
  }, [dispatch]);
  const onBulkVehiclesFirmwareUpdate = useCallback(() => {
       // setSelectedVehicle()
        dispatch(setModalName("bulk_update_vehicle_firmware"));
  }, [dispatch]);

    const onMqttServerChange = useCallback(() => {
       // dispatch(setSelectedVehicle(vehicle));
        dispatch(setModalName("change_mqtt_server"));
    }, [dispatch]); //,vehicle]);

    const onUploadFirmware = useCallback(() => {
       // dispatch(setSelectedVehicleModel(model));
        dispatch(setModalName("add_model_firmware"));
    }, [dispatch]); //,model]);

  const onAddVehicle = useCallback(() => {
        dispatch(setModalName("add_vehicle"));
    }, [dispatch]);

  const onAddVehicleModel = useCallback(() => {
      dispatch(setModalName("add_vehicle_model"));
  }, [dispatch]);

  const onSwitchView = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch(setVehiclesSearch(""));
      history.push( value == "Vehicles" ? ROUTES.vehicles_list : ROUTES.vehicle_models_list );
    },
    [history]
  );

    const onMapSwitchView = useCallback(
        (event) => {
            const { value } = event.target;
            history.push(`${ROUTES.vehicles}/${value}`);
        },
        [history]
    );

  const onImportCsv = useCallback(() => {
    dispatch(setModalName("import_csv"));
  }, [dispatch]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setVehiclesSearch(event.target.value));
    },
    [dispatch]
  );
  const menu = (
    <Menu className="vehicle-drop-menu">
      <Menu.Item key="0" onClick={onImportCsv}>
        <span> Import CSV</span>
      </Menu.Item>
      {/*<Menu.Item key="1" disabled>*/}
      {/*  <span> Export</span>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

  return (
    <div className="common-header">
      { ! isVehicleModel ? <>
        <Input
          customClasses="--standard"
          placeholder="Search Vehicles..."
          prefix={searchIcon}
          value={searchValue}
          onChange={onChange}
        />
        </>: ""
      }
      {showbulkActionsVehicles && ! isVehicleModel ? (
        <article className="bulk-actions">
          <Button  icon={settingsIcon3} onClick={onMqttServerChange}>
            Change Server
          </Button>
          <Button  icon={uploadIconSmall} onClick={onBulkVehiclesFirmwareUpdate} >
            Update Firmware
          </Button>
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      ): ""}
      <div className="actions-container">
      {userType == 1 || userType == 2 &&
          <><SwitchButton
            label=""
            options={["Vehicles", "Vehicle Models"]}
            defaultOption={isVehicleModel ? "Vehicle Models" : "Vehicles"}
            onChange={onSwitchView}
            type="secondary"
         /><div className="vertical-separator" /></>
      }

        {!isVehicleModel && <><SwitchButton
          label="Select View:"
          options={["table", "map"]}
          defaultOption={selectedView ?? defaultTabByUser}
          onChange={onMapSwitchView}
          type="secondary"
        /></>}

        {/* <div className="vertical-separator" />
          {userType == 1 || userType == 2 ?
              <>
                  <Dropdown overlay={menu} trigger={["click"]} className="vehicle-menu">
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {actionMenu}
                  </a>
                </Dropdown> */
        }
          {userType == 1 || userType == 2 ? isVehicleModel ?
              <>
                  <Button
                      type="primary"
                    //  icon={<span className="custom-icon">{uploadIconSmall}</span>}
                      onClick={onUploadFirmware}>
                      Upload Firmware
                  </Button>
                  <div className="vertical-separator" />
            <Button
              type="primary"
              icon={<span className="custom-icon">{plusIcon}</span>}
              onClick={onAddVehicleModel}>
              Add Vehicle Model
          </Button>
              </>
          : <>
                  <div className="vertical-separator" />
                  <Dropdown overlay={menu} trigger={["click"]} className="vehicle-menu">
                      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                          {actionMenu}
                      </a>
                  </Dropdown>
                  <Button
                  type="primary"
                  icon={<span className="custom-icon">{plusIcon}</span>}
                  onClick={onAddVehicle}>
                  Add Vehicle
                </Button> </>: "" }
              {/* </> : "" */}
          {/* } */}

      </div>
    </div>
  );
}
