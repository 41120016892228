// source: billing_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.api.BankAccount', null, global);
goog.exportSymbol('proto.api.Batch', null, global);
goog.exportSymbol('proto.api.Bill', null, global);
goog.exportSymbol('proto.api.BillComponents', null, global);
goog.exportSymbol('proto.api.BillData', null, global);
goog.exportSymbol('proto.api.Component', null, global);
goog.exportSymbol('proto.api.Connection', null, global);
goog.exportSymbol('proto.api.ConnectionData', null, global);
goog.exportSymbol('proto.api.ConnectionInput', null, global);
goog.exportSymbol('proto.api.CreatePackageRequest', null, global);
goog.exportSymbol('proto.api.CreatePackageResponse', null, global);
goog.exportSymbol('proto.api.CreatePlanRequest', null, global);
goog.exportSymbol('proto.api.CreatePlanResponse', null, global);
goog.exportSymbol('proto.api.CreateSubscriptionRequest', null, global);
goog.exportSymbol('proto.api.CreateSubscriptionResponse', null, global);
goog.exportSymbol('proto.api.CustomerBill', null, global);
goog.exportSymbol('proto.api.DateConnectionInput', null, global);
goog.exportSymbol('proto.api.GetBillsRequest', null, global);
goog.exportSymbol('proto.api.GetBillsResponse', null, global);
goog.exportSymbol('proto.api.GetLatestBillByCustomerRequest', null, global);
goog.exportSymbol('proto.api.GetLatestBillByCustomerResponse', null, global);
goog.exportSymbol('proto.api.GetPackageRequest', null, global);
goog.exportSymbol('proto.api.GetPackageResponse', null, global);
goog.exportSymbol('proto.api.GetPlanRequest', null, global);
goog.exportSymbol('proto.api.GetPlanResponse', null, global);
goog.exportSymbol('proto.api.GetPlansListRequest', null, global);
goog.exportSymbol('proto.api.GetPlansListResponse', null, global);
goog.exportSymbol('proto.api.GetSubscriptionListRequest', null, global);
goog.exportSymbol('proto.api.GetSubscriptionListResponse', null, global);
goog.exportSymbol('proto.api.GetSubscriptionRequest', null, global);
goog.exportSymbol('proto.api.GetSubscriptionResponse', null, global);
goog.exportSymbol('proto.api.MeteredInput', null, global);
goog.exportSymbol('proto.api.Package', null, global);
goog.exportSymbol('proto.api.PackageItem', null, global);
goog.exportSymbol('proto.api.PlanItem', null, global);
goog.exportSymbol('proto.api.PlanPriceItem', null, global);
goog.exportSymbol('proto.api.PlanTariff', null, global);
goog.exportSymbol('proto.api.PostBillRequest', null, global);
goog.exportSymbol('proto.api.PostBillResponse', null, global);
goog.exportSymbol('proto.api.SubscriptionItem', null, global);
goog.exportSymbol('proto.api.Tariff', null, global);
goog.exportSymbol('proto.api.TariffData', null, global);
goog.exportSymbol('proto.api.UnmeteredInput', null, global);
goog.exportSymbol('proto.api.UpdateNetSwapsRequest', null, global);
goog.exportSymbol('proto.api.UpdateNetSwapsResponse', null, global);
goog.exportSymbol('proto.api.UpdateSubscriptionRequest', null, global);
goog.exportSymbol('proto.api.UpdateSubscriptionResponse', null, global);
goog.exportSymbol('proto.api.Value', null, global);
goog.exportSymbol('proto.api.ValueFormula', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePackageRequest.displayName = 'proto.api.CreatePackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePackageResponse.displayName = 'proto.api.CreatePackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Package = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Package.repeatedFields_, null);
};
goog.inherits(proto.api.Package, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Package.displayName = 'proto.api.Package';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Value.displayName = 'proto.api.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Component = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Component, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Component.displayName = 'proto.api.Component';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ConnectionInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ConnectionInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ConnectionInput.displayName = 'proto.api.ConnectionInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DateConnectionInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DateConnectionInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DateConnectionInput.displayName = 'proto.api.DateConnectionInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MeteredInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MeteredInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MeteredInput.displayName = 'proto.api.MeteredInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UnmeteredInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UnmeteredInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UnmeteredInput.displayName = 'proto.api.UnmeteredInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BankAccount.displayName = 'proto.api.BankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Batch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Batch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Batch.displayName = 'proto.api.Batch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Tariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Tariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Tariff.displayName = 'proto.api.Tariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePlanRequest.displayName = 'proto.api.CreatePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePlanResponse.displayName = 'proto.api.CreatePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TariffData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TariffData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TariffData.displayName = 'proto.api.TariffData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ValueFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ValueFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ValueFormula.displayName = 'proto.api.ValueFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PlanTariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.PlanTariff.repeatedFields_, null);
};
goog.inherits(proto.api.PlanTariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PlanTariff.displayName = 'proto.api.PlanTariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.CreateSubscriptionRequest.repeatedFields_, null);
};
goog.inherits(proto.api.CreateSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateSubscriptionRequest.displayName = 'proto.api.CreateSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateSubscriptionResponse.displayName = 'proto.api.CreateSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ConnectionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ConnectionData.repeatedFields_, null);
};
goog.inherits(proto.api.ConnectionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ConnectionData.displayName = 'proto.api.ConnectionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Connection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Connection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Connection.displayName = 'proto.api.Connection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PostBillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.PostBillRequest.repeatedFields_, null);
};
goog.inherits(proto.api.PostBillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PostBillRequest.displayName = 'proto.api.PostBillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PostBillResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PostBillResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PostBillResponse.displayName = 'proto.api.PostBillResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BillData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.BillData.repeatedFields_, null);
};
goog.inherits(proto.api.BillData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BillData.displayName = 'proto.api.BillData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Bill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Bill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Bill.displayName = 'proto.api.Bill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BillComponents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BillComponents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BillComponents.displayName = 'proto.api.BillComponents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PlanItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PlanItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PlanItem.displayName = 'proto.api.PlanItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PlanPriceItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PlanPriceItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PlanPriceItem.displayName = 'proto.api.PlanPriceItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SubscriptionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SubscriptionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SubscriptionItem.displayName = 'proto.api.SubscriptionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPlansListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetPlansListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPlansListRequest.displayName = 'proto.api.GetPlansListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPlansListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetPlansListResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetPlansListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPlansListResponse.displayName = 'proto.api.GetPlansListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSubscriptionListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSubscriptionListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSubscriptionListRequest.displayName = 'proto.api.GetSubscriptionListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSubscriptionListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetSubscriptionListResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetSubscriptionListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSubscriptionListResponse.displayName = 'proto.api.GetSubscriptionListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBillsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetBillsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBillsRequest.displayName = 'proto.api.GetBillsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBillsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetBillsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetBillsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBillsResponse.displayName = 'proto.api.GetBillsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateSubscriptionRequest.displayName = 'proto.api.UpdateSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateSubscriptionResponse.displayName = 'proto.api.UpdateSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetLatestBillByCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetLatestBillByCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetLatestBillByCustomerRequest.displayName = 'proto.api.GetLatestBillByCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CustomerBill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CustomerBill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CustomerBill.displayName = 'proto.api.CustomerBill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetLatestBillByCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetLatestBillByCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetLatestBillByCustomerResponse.displayName = 'proto.api.GetLatestBillByCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPackageRequest.displayName = 'proto.api.GetPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPackageResponse.displayName = 'proto.api.GetPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PackageItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PackageItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PackageItem.displayName = 'proto.api.PackageItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPlanRequest.displayName = 'proto.api.GetPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetPlanResponse.displayName = 'proto.api.GetPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSubscriptionRequest.displayName = 'proto.api.GetSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSubscriptionResponse.displayName = 'proto.api.GetSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateNetSwapsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateNetSwapsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateNetSwapsRequest.displayName = 'proto.api.UpdateNetSwapsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateNetSwapsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateNetSwapsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateNetSwapsResponse.displayName = 'proto.api.UpdateNetSwapsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_package: (f = msg.getPackage()) && proto.api.Package.toObject(includeInstance, f),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    billingcycle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePackageRequest}
 */
proto.api.CreatePackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePackageRequest;
  return proto.api.CreatePackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePackageRequest}
 */
proto.api.CreatePackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Package;
      reader.readMessage(value,proto.api.Package.deserializeBinaryFromReader);
      msg.setPackage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingcycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Package.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBillingcycle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Package package = 1;
 * @return {?proto.api.Package}
 */
proto.api.CreatePackageRequest.prototype.getPackage = function() {
  return /** @type{?proto.api.Package} */ (
    jspb.Message.getWrapperField(this, proto.api.Package, 1));
};


/**
 * @param {?proto.api.Package|undefined} value
 * @return {!proto.api.CreatePackageRequest} returns this
*/
proto.api.CreatePackageRequest.prototype.setPackage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreatePackageRequest} returns this
 */
proto.api.CreatePackageRequest.prototype.clearPackage = function() {
  return this.setPackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreatePackageRequest.prototype.hasPackage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.api.CreatePackageRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePackageRequest} returns this
 */
proto.api.CreatePackageRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.api.CreatePackageRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePackageRequest} returns this
 */
proto.api.CreatePackageRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string billingCycle = 4;
 * @return {string}
 */
proto.api.CreatePackageRequest.prototype.getBillingcycle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePackageRequest} returns this
 */
proto.api.CreatePackageRequest.prototype.setBillingcycle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePackageResponse}
 */
proto.api.CreatePackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePackageResponse;
  return proto.api.CreatePackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePackageResponse}
 */
proto.api.CreatePackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.api.CreatePackageResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePackageResponse} returns this
 */
proto.api.CreatePackageResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.CreatePackageResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePackageResponse} returns this
 */
proto.api.CreatePackageResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Package.repeatedFields_ = [7,8,9,10,11,12,13,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Package.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Package.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Package} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Package.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingcycle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    duedateoffset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    chargingtype: jspb.Message.getFieldWithDefault(msg, 6, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.api.Value.toObject, includeInstance),
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.api.Component.toObject, includeInstance),
    connectioninputsList: jspb.Message.toObjectList(msg.getConnectioninputsList(),
    proto.api.ConnectionInput.toObject, includeInstance),
    meteredconnectioninputsList: jspb.Message.toObjectList(msg.getMeteredconnectioninputsList(),
    proto.api.ConnectionInput.toObject, includeInstance),
    dateconnectioninputsList: jspb.Message.toObjectList(msg.getDateconnectioninputsList(),
    proto.api.DateConnectionInput.toObject, includeInstance),
    meteredinputsList: jspb.Message.toObjectList(msg.getMeteredinputsList(),
    proto.api.MeteredInput.toObject, includeInstance),
    unmeteredinputsList: jspb.Message.toObjectList(msg.getUnmeteredinputsList(),
    proto.api.UnmeteredInput.toObject, includeInstance),
    bankaccountsList: jspb.Message.toObjectList(msg.getBankaccountsList(),
    proto.api.BankAccount.toObject, includeInstance),
    batchesList: jspb.Message.toObjectList(msg.getBatchesList(),
    proto.api.Batch.toObject, includeInstance),
    tariffsList: jspb.Message.toObjectList(msg.getTariffsList(),
    proto.api.Tariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Package}
 */
proto.api.Package.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Package;
  return proto.api.Package.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Package} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Package}
 */
proto.api.Package.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingcycle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuedateoffset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargingtype(value);
      break;
    case 7:
      var value = new proto.api.Value;
      reader.readMessage(value,proto.api.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 8:
      var value = new proto.api.Component;
      reader.readMessage(value,proto.api.Component.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    case 9:
      var value = new proto.api.ConnectionInput;
      reader.readMessage(value,proto.api.ConnectionInput.deserializeBinaryFromReader);
      msg.addConnectioninputs(value);
      break;
    case 10:
      var value = new proto.api.ConnectionInput;
      reader.readMessage(value,proto.api.ConnectionInput.deserializeBinaryFromReader);
      msg.addMeteredconnectioninputs(value);
      break;
    case 11:
      var value = new proto.api.DateConnectionInput;
      reader.readMessage(value,proto.api.DateConnectionInput.deserializeBinaryFromReader);
      msg.addDateconnectioninputs(value);
      break;
    case 12:
      var value = new proto.api.MeteredInput;
      reader.readMessage(value,proto.api.MeteredInput.deserializeBinaryFromReader);
      msg.addMeteredinputs(value);
      break;
    case 13:
      var value = new proto.api.UnmeteredInput;
      reader.readMessage(value,proto.api.UnmeteredInput.deserializeBinaryFromReader);
      msg.addUnmeteredinputs(value);
      break;
    case 14:
      var value = new proto.api.BankAccount;
      reader.readMessage(value,proto.api.BankAccount.deserializeBinaryFromReader);
      msg.addBankaccounts(value);
      break;
    case 15:
      var value = new proto.api.Batch;
      reader.readMessage(value,proto.api.Batch.deserializeBinaryFromReader);
      msg.addBatches(value);
      break;
    case 16:
      var value = new proto.api.Tariff;
      reader.readMessage(value,proto.api.Tariff.deserializeBinaryFromReader);
      msg.addTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Package.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Package.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Package} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Package.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingcycle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDuedateoffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChargingtype();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.api.Value.serializeBinaryToWriter
    );
  }
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.Component.serializeBinaryToWriter
    );
  }
  f = message.getConnectioninputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.api.ConnectionInput.serializeBinaryToWriter
    );
  }
  f = message.getMeteredconnectioninputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.api.ConnectionInput.serializeBinaryToWriter
    );
  }
  f = message.getDateconnectioninputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.api.DateConnectionInput.serializeBinaryToWriter
    );
  }
  f = message.getMeteredinputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.api.MeteredInput.serializeBinaryToWriter
    );
  }
  f = message.getUnmeteredinputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.api.UnmeteredInput.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.api.BankAccount.serializeBinaryToWriter
    );
  }
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.api.Batch.serializeBinaryToWriter
    );
  }
  f = message.getTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.api.Tariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional string billingCycle = 1;
 * @return {string}
 */
proto.api.Package.prototype.getBillingcycle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setBillingcycle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.Package.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 dueDateOffset = 3;
 * @return {number}
 */
proto.api.Package.prototype.getDuedateoffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setDuedateoffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.api.Package.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.api.Package.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string chargingType = 6;
 * @return {string}
 */
proto.api.Package.prototype.getChargingtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.setChargingtype = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Value values = 7;
 * @return {!Array<!proto.api.Value>}
 */
proto.api.Package.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.api.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Value, 7));
};


/**
 * @param {!Array<!proto.api.Value>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.api.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Value}
 */
proto.api.Package.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.api.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * repeated Component components = 8;
 * @return {!Array<!proto.api.Component>}
 */
proto.api.Package.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.api.Component>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Component, 8));
};


/**
 * @param {!Array<!proto.api.Component>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.Component=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Component}
 */
proto.api.Package.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.Component, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearComponentsList = function() {
  return this.setComponentsList([]);
};


/**
 * repeated ConnectionInput connectionInputs = 9;
 * @return {!Array<!proto.api.ConnectionInput>}
 */
proto.api.Package.prototype.getConnectioninputsList = function() {
  return /** @type{!Array<!proto.api.ConnectionInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ConnectionInput, 9));
};


/**
 * @param {!Array<!proto.api.ConnectionInput>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setConnectioninputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.api.ConnectionInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ConnectionInput}
 */
proto.api.Package.prototype.addConnectioninputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.api.ConnectionInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearConnectioninputsList = function() {
  return this.setConnectioninputsList([]);
};


/**
 * repeated ConnectionInput meteredConnectionInputs = 10;
 * @return {!Array<!proto.api.ConnectionInput>}
 */
proto.api.Package.prototype.getMeteredconnectioninputsList = function() {
  return /** @type{!Array<!proto.api.ConnectionInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ConnectionInput, 10));
};


/**
 * @param {!Array<!proto.api.ConnectionInput>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setMeteredconnectioninputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.api.ConnectionInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ConnectionInput}
 */
proto.api.Package.prototype.addMeteredconnectioninputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.api.ConnectionInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearMeteredconnectioninputsList = function() {
  return this.setMeteredconnectioninputsList([]);
};


/**
 * repeated DateConnectionInput dateConnectionInputs = 11;
 * @return {!Array<!proto.api.DateConnectionInput>}
 */
proto.api.Package.prototype.getDateconnectioninputsList = function() {
  return /** @type{!Array<!proto.api.DateConnectionInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.DateConnectionInput, 11));
};


/**
 * @param {!Array<!proto.api.DateConnectionInput>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setDateconnectioninputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.api.DateConnectionInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.DateConnectionInput}
 */
proto.api.Package.prototype.addDateconnectioninputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.api.DateConnectionInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearDateconnectioninputsList = function() {
  return this.setDateconnectioninputsList([]);
};


/**
 * repeated MeteredInput meteredInputs = 12;
 * @return {!Array<!proto.api.MeteredInput>}
 */
proto.api.Package.prototype.getMeteredinputsList = function() {
  return /** @type{!Array<!proto.api.MeteredInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.MeteredInput, 12));
};


/**
 * @param {!Array<!proto.api.MeteredInput>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setMeteredinputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.api.MeteredInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.MeteredInput}
 */
proto.api.Package.prototype.addMeteredinputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.api.MeteredInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearMeteredinputsList = function() {
  return this.setMeteredinputsList([]);
};


/**
 * repeated UnmeteredInput unmeteredInputs = 13;
 * @return {!Array<!proto.api.UnmeteredInput>}
 */
proto.api.Package.prototype.getUnmeteredinputsList = function() {
  return /** @type{!Array<!proto.api.UnmeteredInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UnmeteredInput, 13));
};


/**
 * @param {!Array<!proto.api.UnmeteredInput>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setUnmeteredinputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.api.UnmeteredInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UnmeteredInput}
 */
proto.api.Package.prototype.addUnmeteredinputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.api.UnmeteredInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearUnmeteredinputsList = function() {
  return this.setUnmeteredinputsList([]);
};


/**
 * repeated BankAccount bankAccounts = 14;
 * @return {!Array<!proto.api.BankAccount>}
 */
proto.api.Package.prototype.getBankaccountsList = function() {
  return /** @type{!Array<!proto.api.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.BankAccount, 14));
};


/**
 * @param {!Array<!proto.api.BankAccount>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setBankaccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.api.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.BankAccount}
 */
proto.api.Package.prototype.addBankaccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.api.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearBankaccountsList = function() {
  return this.setBankaccountsList([]);
};


/**
 * repeated Batch batches = 15;
 * @return {!Array<!proto.api.Batch>}
 */
proto.api.Package.prototype.getBatchesList = function() {
  return /** @type{!Array<!proto.api.Batch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Batch, 15));
};


/**
 * @param {!Array<!proto.api.Batch>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.api.Batch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Batch}
 */
proto.api.Package.prototype.addBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.api.Batch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * repeated Tariff tariffs = 16;
 * @return {!Array<!proto.api.Tariff>}
 */
proto.api.Package.prototype.getTariffsList = function() {
  return /** @type{!Array<!proto.api.Tariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Tariff, 16));
};


/**
 * @param {!Array<!proto.api.Tariff>} value
 * @return {!proto.api.Package} returns this
*/
proto.api.Package.prototype.setTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.api.Tariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Tariff}
 */
proto.api.Package.prototype.addTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.api.Tariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Package} returns this
 */
proto.api.Package.prototype.clearTariffsList = function() {
  return this.setTariffsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Value}
 */
proto.api.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Value;
  return proto.api.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Value}
 */
proto.api.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Value.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.api.Value.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Component.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Component.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Component} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Component.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentpriority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    latepayment: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Component}
 */
proto.api.Component.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Component;
  return proto.api.Component.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Component} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Component}
 */
proto.api.Component.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentpriority(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatepayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Component.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Component.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Component} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Component.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPaymentpriority();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLatepayment();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Component.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Component} returns this
 */
proto.api.Component.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.api.Component.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Component} returns this
 */
proto.api.Component.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 paymentPriority = 3;
 * @return {number}
 */
proto.api.Component.prototype.getPaymentpriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Component} returns this
 */
proto.api.Component.prototype.setPaymentpriority = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double latePayment = 4;
 * @return {number}
 */
proto.api.Component.prototype.getLatepayment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Component} returns this
 */
proto.api.Component.prototype.setLatepayment = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ConnectionInput.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ConnectionInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ConnectionInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConnectionInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    values: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ConnectionInput}
 */
proto.api.ConnectionInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ConnectionInput;
  return proto.api.ConnectionInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ConnectionInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ConnectionInput}
 */
proto.api.ConnectionInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ConnectionInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ConnectionInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ConnectionInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConnectionInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValues();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.ConnectionInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConnectionInput} returns this
 */
proto.api.ConnectionInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string values = 2;
 * @return {string}
 */
proto.api.ConnectionInput.prototype.getValues = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConnectionInput} returns this
 */
proto.api.ConnectionInput.prototype.setValues = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DateConnectionInput.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DateConnectionInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DateConnectionInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DateConnectionInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    values: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DateConnectionInput}
 */
proto.api.DateConnectionInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DateConnectionInput;
  return proto.api.DateConnectionInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DateConnectionInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DateConnectionInput}
 */
proto.api.DateConnectionInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DateConnectionInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DateConnectionInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DateConnectionInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DateConnectionInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValues();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.DateConnectionInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DateConnectionInput} returns this
 */
proto.api.DateConnectionInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string values = 2;
 * @return {string}
 */
proto.api.DateConnectionInput.prototype.getValues = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DateConnectionInput} returns this
 */
proto.api.DateConnectionInput.prototype.setValues = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MeteredInput.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MeteredInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MeteredInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MeteredInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MeteredInput}
 */
proto.api.MeteredInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MeteredInput;
  return proto.api.MeteredInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MeteredInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MeteredInput}
 */
proto.api.MeteredInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MeteredInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MeteredInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MeteredInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MeteredInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.MeteredInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MeteredInput} returns this
 */
proto.api.MeteredInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UnmeteredInput.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UnmeteredInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UnmeteredInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UnmeteredInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addOn: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transferable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    expire: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UnmeteredInput}
 */
proto.api.UnmeteredInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UnmeteredInput;
  return proto.api.UnmeteredInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UnmeteredInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UnmeteredInput}
 */
proto.api.UnmeteredInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddOn(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransferable(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UnmeteredInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UnmeteredInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UnmeteredInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UnmeteredInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddOn();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransferable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExpire();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.UnmeteredInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string limit = 2;
 * @return {string}
 */
proto.api.UnmeteredInput.prototype.getLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.api.UnmeteredInput.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string add_on = 4;
 * @return {string}
 */
proto.api.UnmeteredInput.prototype.getAddOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setAddOn = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool transferable = 5;
 * @return {boolean}
 */
proto.api.UnmeteredInput.prototype.getTransferable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setTransferable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool expire = 6;
 * @return {boolean}
 */
proto.api.UnmeteredInput.prototype.getExpire = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UnmeteredInput} returns this
 */
proto.api.UnmeteredInput.prototype.setExpire = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountnumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BankAccount}
 */
proto.api.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BankAccount;
  return proto.api.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BankAccount}
 */
proto.api.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string accountNumber = 1;
 * @return {string}
 */
proto.api.BankAccount.prototype.getAccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BankAccount} returns this
 */
proto.api.BankAccount.prototype.setAccountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Batch.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Batch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Batch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Batch.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Batch}
 */
proto.api.Batch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Batch;
  return proto.api.Batch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Batch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Batch}
 */
proto.api.Batch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Batch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Batch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Batch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Batch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.api.Batch.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Batch} returns this
 */
proto.api.Batch.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Tariff.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Tariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Tariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Tariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Tariff}
 */
proto.api.Tariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Tariff;
  return proto.api.Tariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Tariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Tariff}
 */
proto.api.Tariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Tariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Tariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Tariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Tariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Tariff.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Tariff} returns this
 */
proto.api.Tariff.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariff: (f = msg.getTariff()) && proto.api.PlanTariff.toObject(includeInstance, f),
    servicecode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plancode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    planname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    swaprate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePlanRequest}
 */
proto.api.CreatePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePlanRequest;
  return proto.api.CreatePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePlanRequest}
 */
proto.api.CreatePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.PlanTariff;
      reader.readMessage(value,proto.api.PlanTariff.deserializeBinaryFromReader);
      msg.setTariff(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicecode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlancode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSwaprate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.PlanTariff.serializeBinaryToWriter
    );
  }
  f = message.getServicecode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlancode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlanname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSwaprate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional PlanTariff tariff = 1;
 * @return {?proto.api.PlanTariff}
 */
proto.api.CreatePlanRequest.prototype.getTariff = function() {
  return /** @type{?proto.api.PlanTariff} */ (
    jspb.Message.getWrapperField(this, proto.api.PlanTariff, 1));
};


/**
 * @param {?proto.api.PlanTariff|undefined} value
 * @return {!proto.api.CreatePlanRequest} returns this
*/
proto.api.CreatePlanRequest.prototype.setTariff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreatePlanRequest} returns this
 */
proto.api.CreatePlanRequest.prototype.clearTariff = function() {
  return this.setTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreatePlanRequest.prototype.hasTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string serviceCode = 2;
 * @return {string}
 */
proto.api.CreatePlanRequest.prototype.getServicecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePlanRequest} returns this
 */
proto.api.CreatePlanRequest.prototype.setServicecode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string planCode = 3;
 * @return {string}
 */
proto.api.CreatePlanRequest.prototype.getPlancode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePlanRequest} returns this
 */
proto.api.CreatePlanRequest.prototype.setPlancode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string planName = 4;
 * @return {string}
 */
proto.api.CreatePlanRequest.prototype.getPlanname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePlanRequest} returns this
 */
proto.api.CreatePlanRequest.prototype.setPlanname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 SwapRate = 5;
 * @return {number}
 */
proto.api.CreatePlanRequest.prototype.getSwaprate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreatePlanRequest} returns this
 */
proto.api.CreatePlanRequest.prototype.setSwaprate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.api.TariffData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePlanResponse}
 */
proto.api.CreatePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePlanResponse;
  return proto.api.CreatePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePlanResponse}
 */
proto.api.CreatePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.api.TariffData;
      reader.readMessage(value,proto.api.TariffData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.TariffData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.api.CreatePlanResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePlanResponse} returns this
 */
proto.api.CreatePlanResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.CreatePlanResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePlanResponse} returns this
 */
proto.api.CreatePlanResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TariffData data = 3;
 * @return {?proto.api.TariffData}
 */
proto.api.CreatePlanResponse.prototype.getData = function() {
  return /** @type{?proto.api.TariffData} */ (
    jspb.Message.getWrapperField(this, proto.api.TariffData, 3));
};


/**
 * @param {?proto.api.TariffData|undefined} value
 * @return {!proto.api.CreatePlanResponse} returns this
*/
proto.api.CreatePlanResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreatePlanResponse} returns this
 */
proto.api.CreatePlanResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreatePlanResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TariffData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TariffData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TariffData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TariffData.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariff: (f = msg.getTariff()) && proto.api.Tariff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TariffData}
 */
proto.api.TariffData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TariffData;
  return proto.api.TariffData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TariffData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TariffData}
 */
proto.api.TariffData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Tariff;
      reader.readMessage(value,proto.api.Tariff.deserializeBinaryFromReader);
      msg.setTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TariffData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TariffData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TariffData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TariffData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Tariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional Tariff tariff = 1;
 * @return {?proto.api.Tariff}
 */
proto.api.TariffData.prototype.getTariff = function() {
  return /** @type{?proto.api.Tariff} */ (
    jspb.Message.getWrapperField(this, proto.api.Tariff, 1));
};


/**
 * @param {?proto.api.Tariff|undefined} value
 * @return {!proto.api.TariffData} returns this
*/
proto.api.TariffData.prototype.setTariff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.TariffData} returns this
 */
proto.api.TariffData.prototype.clearTariff = function() {
  return this.setTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TariffData.prototype.hasTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ValueFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ValueFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ValueFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ValueFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    formula: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ValueFormula}
 */
proto.api.ValueFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ValueFormula;
  return proto.api.ValueFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ValueFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ValueFormula}
 */
proto.api.ValueFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormula(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ValueFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ValueFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ValueFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ValueFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormula();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.api.ValueFormula.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ValueFormula} returns this
 */
proto.api.ValueFormula.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string formula = 2;
 * @return {string}
 */
proto.api.ValueFormula.prototype.getFormula = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ValueFormula} returns this
 */
proto.api.ValueFormula.prototype.setFormula = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.PlanTariff.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PlanTariff.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PlanTariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PlanTariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanTariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicecode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    valueformulasList: jspb.Message.toObjectList(msg.getValueformulasList(),
    proto.api.ValueFormula.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PlanTariff}
 */
proto.api.PlanTariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PlanTariff;
  return proto.api.PlanTariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PlanTariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PlanTariff}
 */
proto.api.PlanTariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicecode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.api.ValueFormula;
      reader.readMessage(value,proto.api.ValueFormula.deserializeBinaryFromReader);
      msg.addValueformulas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PlanTariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PlanTariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PlanTariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanTariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicecode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValueformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.ValueFormula.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serviceCode = 1;
 * @return {string}
 */
proto.api.PlanTariff.prototype.getServicecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanTariff} returns this
 */
proto.api.PlanTariff.prototype.setServicecode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.PlanTariff.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanTariff} returns this
 */
proto.api.PlanTariff.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.api.PlanTariff.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanTariff} returns this
 */
proto.api.PlanTariff.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.api.PlanTariff.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanTariff} returns this
 */
proto.api.PlanTariff.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ValueFormula valueFormulas = 5;
 * @return {!Array<!proto.api.ValueFormula>}
 */
proto.api.PlanTariff.prototype.getValueformulasList = function() {
  return /** @type{!Array<!proto.api.ValueFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ValueFormula, 5));
};


/**
 * @param {!Array<!proto.api.ValueFormula>} value
 * @return {!proto.api.PlanTariff} returns this
*/
proto.api.PlanTariff.prototype.setValueformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.ValueFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ValueFormula}
 */
proto.api.PlanTariff.prototype.addValueformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.ValueFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.PlanTariff} returns this
 */
proto.api.PlanTariff.prototype.clearValueformulasList = function() {
  return this.setValueformulasList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.CreateSubscriptionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionsList: jspb.Message.toObjectList(msg.getConnectionsList(),
    proto.api.Connection.toObject, includeInstance),
    packagecode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plancode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bstcharges: jspb.Message.getFieldWithDefault(msg, 5, ""),
    distanceallowed: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    connectionnumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    subscriptionrefno: jspb.Message.getFieldWithDefault(msg, 9, ""),
    partyrefno: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateSubscriptionRequest}
 */
proto.api.CreateSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateSubscriptionRequest;
  return proto.api.CreateSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateSubscriptionRequest}
 */
proto.api.CreateSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Connection;
      reader.readMessage(value,proto.api.Connection.deserializeBinaryFromReader);
      msg.addConnections(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackagecode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlancode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBstcharges(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistanceallowed(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectionnumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionrefno(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyrefno(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Connection.serializeBinaryToWriter
    );
  }
  f = message.getPackagecode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlancode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBstcharges();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDistanceallowed();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConnectionnumber();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSubscriptionrefno();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPartyrefno();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * repeated Connection connections = 1;
 * @return {!Array<!proto.api.Connection>}
 */
proto.api.CreateSubscriptionRequest.prototype.getConnectionsList = function() {
  return /** @type{!Array<!proto.api.Connection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Connection, 1));
};


/**
 * @param {!Array<!proto.api.Connection>} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
*/
proto.api.CreateSubscriptionRequest.prototype.setConnectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Connection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Connection}
 */
proto.api.CreateSubscriptionRequest.prototype.addConnections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Connection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.clearConnectionsList = function() {
  return this.setConnectionsList([]);
};


/**
 * optional string packageCode = 2;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getPackagecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setPackagecode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string planCode = 3;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getPlancode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setPlancode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batchCode = 4;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getBatchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setBatchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string BSTCharges = 5;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getBstcharges = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setBstcharges = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 distanceAllowed = 6;
 * @return {number}
 */
proto.api.CreateSubscriptionRequest.prototype.getDistanceallowed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setDistanceallowed = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 connectionNumber = 8;
 * @return {number}
 */
proto.api.CreateSubscriptionRequest.prototype.getConnectionnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setConnectionnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string subscriptionRefNo = 9;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getSubscriptionrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setSubscriptionrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string partyRefNo = 10;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getPartyrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setPartyrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vin = 11;
 * @return {string}
 */
proto.api.CreateSubscriptionRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionRequest} returns this
 */
proto.api.CreateSubscriptionRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.api.ConnectionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateSubscriptionResponse}
 */
proto.api.CreateSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateSubscriptionResponse;
  return proto.api.CreateSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateSubscriptionResponse}
 */
proto.api.CreateSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.api.ConnectionData;
      reader.readMessage(value,proto.api.ConnectionData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ConnectionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.api.CreateSubscriptionResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionResponse} returns this
 */
proto.api.CreateSubscriptionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.CreateSubscriptionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateSubscriptionResponse} returns this
 */
proto.api.CreateSubscriptionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ConnectionData data = 3;
 * @return {?proto.api.ConnectionData}
 */
proto.api.CreateSubscriptionResponse.prototype.getData = function() {
  return /** @type{?proto.api.ConnectionData} */ (
    jspb.Message.getWrapperField(this, proto.api.ConnectionData, 3));
};


/**
 * @param {?proto.api.ConnectionData|undefined} value
 * @return {!proto.api.CreateSubscriptionResponse} returns this
*/
proto.api.CreateSubscriptionResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateSubscriptionResponse} returns this
 */
proto.api.CreateSubscriptionResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateSubscriptionResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ConnectionData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ConnectionData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ConnectionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ConnectionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConnectionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionsList: jspb.Message.toObjectList(msg.getConnectionsList(),
    proto.api.Connection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ConnectionData}
 */
proto.api.ConnectionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ConnectionData;
  return proto.api.ConnectionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ConnectionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ConnectionData}
 */
proto.api.ConnectionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Connection;
      reader.readMessage(value,proto.api.Connection.deserializeBinaryFromReader);
      msg.addConnections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ConnectionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ConnectionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ConnectionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConnectionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Connection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Connection connections = 1;
 * @return {!Array<!proto.api.Connection>}
 */
proto.api.ConnectionData.prototype.getConnectionsList = function() {
  return /** @type{!Array<!proto.api.Connection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Connection, 1));
};


/**
 * @param {!Array<!proto.api.Connection>} value
 * @return {!proto.api.ConnectionData} returns this
*/
proto.api.ConnectionData.prototype.setConnectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Connection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Connection}
 */
proto.api.ConnectionData.prototype.addConnections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Connection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ConnectionData} returns this
 */
proto.api.ConnectionData.prototype.clearConnectionsList = function() {
  return this.setConnectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Connection.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Connection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Connection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Connection.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usernumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    servicecode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tariffcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    batchcode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    connectiondate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    connectionnumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    subscriptionrefno: jspb.Message.getFieldWithDefault(msg, 10, ""),
    partyrefno: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Connection}
 */
proto.api.Connection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Connection;
  return proto.api.Connection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Connection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Connection}
 */
proto.api.Connection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicecode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchcode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectiondate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectionnumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionrefno(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyrefno(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Connection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Connection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Connection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Connection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsernumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getServicecode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTariffcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBatchcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConnectiondate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConnectionnumber();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSubscriptionrefno();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPartyrefno();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.api.Connection.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 userNumber = 2;
 * @return {number}
 */
proto.api.Connection.prototype.getUsernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setUsernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string serviceCode = 3;
 * @return {string}
 */
proto.api.Connection.prototype.getServicecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setServicecode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tariffCode = 4;
 * @return {string}
 */
proto.api.Connection.prototype.getTariffcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setTariffcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string batchCode = 5;
 * @return {string}
 */
proto.api.Connection.prototype.getBatchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setBatchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.api.Connection.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 connectionDate = 7;
 * @return {number}
 */
proto.api.Connection.prototype.getConnectiondate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setConnectiondate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.api.Connection.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 connectionNumber = 9;
 * @return {number}
 */
proto.api.Connection.prototype.getConnectionnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setConnectionnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string subscriptionRefNo = 10;
 * @return {string}
 */
proto.api.Connection.prototype.getSubscriptionrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setSubscriptionrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string partyRefNo = 11;
 * @return {string}
 */
proto.api.Connection.prototype.getPartyrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Connection} returns this
 */
proto.api.Connection.prototype.setPartyrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.PostBillRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PostBillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PostBillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PostBillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PostBillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    billsList: jspb.Message.toObjectList(msg.getBillsList(),
    proto.api.Bill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PostBillRequest}
 */
proto.api.PostBillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PostBillRequest;
  return proto.api.PostBillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PostBillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PostBillRequest}
 */
proto.api.PostBillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Bill;
      reader.readMessage(value,proto.api.Bill.deserializeBinaryFromReader);
      msg.addBills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PostBillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PostBillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PostBillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PostBillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Bill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Bill bills = 1;
 * @return {!Array<!proto.api.Bill>}
 */
proto.api.PostBillRequest.prototype.getBillsList = function() {
  return /** @type{!Array<!proto.api.Bill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Bill, 1));
};


/**
 * @param {!Array<!proto.api.Bill>} value
 * @return {!proto.api.PostBillRequest} returns this
*/
proto.api.PostBillRequest.prototype.setBillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Bill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Bill}
 */
proto.api.PostBillRequest.prototype.addBills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Bill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.PostBillRequest} returns this
 */
proto.api.PostBillRequest.prototype.clearBillsList = function() {
  return this.setBillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PostBillResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PostBillResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PostBillResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PostBillResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.api.BillData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PostBillResponse}
 */
proto.api.PostBillResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PostBillResponse;
  return proto.api.PostBillResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PostBillResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PostBillResponse}
 */
proto.api.PostBillResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.api.BillData;
      reader.readMessage(value,proto.api.BillData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PostBillResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PostBillResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PostBillResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PostBillResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.BillData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.api.PostBillResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PostBillResponse} returns this
 */
proto.api.PostBillResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.PostBillResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PostBillResponse} returns this
 */
proto.api.PostBillResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BillData data = 3;
 * @return {?proto.api.BillData}
 */
proto.api.PostBillResponse.prototype.getData = function() {
  return /** @type{?proto.api.BillData} */ (
    jspb.Message.getWrapperField(this, proto.api.BillData, 3));
};


/**
 * @param {?proto.api.BillData|undefined} value
 * @return {!proto.api.PostBillResponse} returns this
*/
proto.api.PostBillResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.PostBillResponse} returns this
 */
proto.api.PostBillResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.PostBillResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.BillData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BillData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BillData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BillData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BillData.toObject = function(includeInstance, msg) {
  var f, obj = {
    billsList: jspb.Message.toObjectList(msg.getBillsList(),
    proto.api.Bill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BillData}
 */
proto.api.BillData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BillData;
  return proto.api.BillData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BillData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BillData}
 */
proto.api.BillData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Bill;
      reader.readMessage(value,proto.api.Bill.deserializeBinaryFromReader);
      msg.addBills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BillData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BillData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BillData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BillData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Bill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Bill bills = 1;
 * @return {!Array<!proto.api.Bill>}
 */
proto.api.BillData.prototype.getBillsList = function() {
  return /** @type{!Array<!proto.api.Bill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Bill, 1));
};


/**
 * @param {!Array<!proto.api.Bill>} value
 * @return {!proto.api.BillData} returns this
*/
proto.api.BillData.prototype.setBillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Bill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Bill}
 */
proto.api.BillData.prototype.addBills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Bill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.BillData} returns this
 */
proto.api.BillData.prototype.clearBillsList = function() {
  return this.setBillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Bill.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Bill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Bill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Bill.toObject = function(includeInstance, msg) {
  var f, obj = {
    billno: jspb.Message.getFieldWithDefault(msg, 1, 0),
    partyno: jspb.Message.getFieldWithDefault(msg, 2, 0),
    partyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partyrefno: jspb.Message.getFieldWithDefault(msg, 4, ""),
    connectionnumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    issuedate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postingdate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duedate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    latefeeapplied: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pb_package: jspb.Message.getFieldWithDefault(msg, 11, ""),
    plan: jspb.Message.getFieldWithDefault(msg, 12, ""),
    batch: jspb.Message.getFieldWithDefault(msg, 13, ""),
    term: jspb.Message.getFieldWithDefault(msg, 14, ""),
    components: (f = msg.getComponents()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    unmeteredusage: (f = msg.getUnmeteredusage()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    connectionrefno: jspb.Message.getFieldWithDefault(msg, 17, ""),
    numbermonth: jspb.Message.getFieldWithDefault(msg, 18, ""),
    lineitems: (f = msg.getLineitems()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    vehicleLicencePlate: jspb.Message.getFieldWithDefault(msg, 23, ""),
    billingItems: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Bill}
 */
proto.api.Bill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Bill;
  return proto.api.Bill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Bill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Bill}
 */
proto.api.Bill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBillno(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartyno(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyrefno(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConnectionnumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuedate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostingdate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuedate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatefeeapplied(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackage(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlan(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatch(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerm(value);
      break;
    case 15:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setComponents(value);
      break;
    case 16:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setUnmeteredusage(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionrefno(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumbermonth(value);
      break;
    case 19:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLineitems(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleLicencePlate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Bill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Bill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Bill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Bill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillno();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPartyno();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPartyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartyrefno();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConnectionnumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIssuedate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostingdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuedate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLatefeeapplied();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPackage();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPlan();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBatch();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTerm();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getComponents();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getUnmeteredusage();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getConnectionrefno();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNumbermonth();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLineitems();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getVehicleLicencePlate();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getBillingItems();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional int32 billNo = 1;
 * @return {number}
 */
proto.api.Bill.prototype.getBillno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setBillno = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 partyNo = 2;
 * @return {number}
 */
proto.api.Bill.prototype.getPartyno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPartyno = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string partyName = 3;
 * @return {string}
 */
proto.api.Bill.prototype.getPartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string partyRefNo = 4;
 * @return {string}
 */
proto.api.Bill.prototype.getPartyrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPartyrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 connectionNumber = 5;
 * @return {number}
 */
proto.api.Bill.prototype.getConnectionnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setConnectionnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string issueDate = 6;
 * @return {string}
 */
proto.api.Bill.prototype.getIssuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setIssuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postingDate = 7;
 * @return {string}
 */
proto.api.Bill.prototype.getPostingdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPostingdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dueDate = 8;
 * @return {string}
 */
proto.api.Bill.prototype.getDuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setDuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.api.Bill.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 lateFeeApplied = 10;
 * @return {number}
 */
proto.api.Bill.prototype.getLatefeeapplied = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setLatefeeapplied = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string package = 11;
 * @return {string}
 */
proto.api.Bill.prototype.getPackage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPackage = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string plan = 12;
 * @return {string}
 */
proto.api.Bill.prototype.getPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setPlan = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string batch = 13;
 * @return {string}
 */
proto.api.Bill.prototype.getBatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setBatch = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string term = 14;
 * @return {string}
 */
proto.api.Bill.prototype.getTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Struct components = 15;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.Bill.prototype.getComponents = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 15));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.Bill} returns this
*/
proto.api.Bill.prototype.setComponents = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.clearComponents = function() {
  return this.setComponents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Bill.prototype.hasComponents = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Struct unmeteredUsage = 16;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.Bill.prototype.getUnmeteredusage = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 16));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.Bill} returns this
*/
proto.api.Bill.prototype.setUnmeteredusage = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.clearUnmeteredusage = function() {
  return this.setUnmeteredusage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Bill.prototype.hasUnmeteredusage = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string connectionRefNo = 17;
 * @return {string}
 */
proto.api.Bill.prototype.getConnectionrefno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setConnectionrefno = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string numberMonth = 18;
 * @return {string}
 */
proto.api.Bill.prototype.getNumbermonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setNumbermonth = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional google.protobuf.Struct lineItems = 19;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.Bill.prototype.getLineitems = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 19));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.Bill} returns this
*/
proto.api.Bill.prototype.setLineitems = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.clearLineitems = function() {
  return this.setLineitems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Bill.prototype.hasLineitems = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string vehicle_licence_plate = 23;
 * @return {string}
 */
proto.api.Bill.prototype.getVehicleLicencePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setVehicleLicencePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string billing_items = 24;
 * @return {string}
 */
proto.api.Bill.prototype.getBillingItems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Bill} returns this
 */
proto.api.Bill.prototype.setBillingItems = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BillComponents.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BillComponents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BillComponents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BillComponents.toObject = function(includeInstance, msg) {
  var f, obj = {
    overUsageKm: jspb.Message.getFieldWithDefault(msg, 1, 0),
    overUsageKmCharges: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fallenTierCharges: jspb.Message.getFieldWithDefault(msg, 3, 0),
    batterySubCharges: jspb.Message.getFieldWithDefault(msg, 4, 0),
    swapCharges: jspb.Message.getFieldWithDefault(msg, 5, 0),
    houseChargerRent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    total: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BillComponents}
 */
proto.api.BillComponents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BillComponents;
  return proto.api.BillComponents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BillComponents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BillComponents}
 */
proto.api.BillComponents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverUsageKm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverUsageKmCharges(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFallenTierCharges(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatterySubCharges(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSwapCharges(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHouseChargerRent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BillComponents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BillComponents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BillComponents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BillComponents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverUsageKm();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOverUsageKmCharges();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFallenTierCharges();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBatterySubCharges();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSwapCharges();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHouseChargerRent();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 over_usage_km = 1;
 * @return {number}
 */
proto.api.BillComponents.prototype.getOverUsageKm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setOverUsageKm = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 over_usage_km_charges = 2;
 * @return {number}
 */
proto.api.BillComponents.prototype.getOverUsageKmCharges = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setOverUsageKmCharges = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 fallen_tier_charges = 3;
 * @return {number}
 */
proto.api.BillComponents.prototype.getFallenTierCharges = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setFallenTierCharges = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 battery_sub_charges = 4;
 * @return {number}
 */
proto.api.BillComponents.prototype.getBatterySubCharges = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setBatterySubCharges = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 swap_charges = 5;
 * @return {number}
 */
proto.api.BillComponents.prototype.getSwapCharges = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setSwapCharges = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 house_charger_rent = 6;
 * @return {number}
 */
proto.api.BillComponents.prototype.getHouseChargerRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setHouseChargerRent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total = 7;
 * @return {number}
 */
proto.api.BillComponents.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BillComponents} returns this
 */
proto.api.BillComponents.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PlanItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PlanItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PlanItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    planComponents: jspb.Message.getFieldWithDefault(msg, 5, ""),
    packageId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PlanItem}
 */
proto.api.PlanItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PlanItem;
  return proto.api.PlanItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PlanItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PlanItem}
 */
proto.api.PlanItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanComponents(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PlanItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PlanItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PlanItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlanComponents();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.PlanItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.PlanItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.api.PlanItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_code = 4;
 * @return {string}
 */
proto.api.PlanItem.prototype.getServiceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setServiceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plan_components = 5;
 * @return {string}
 */
proto.api.PlanItem.prototype.getPlanComponents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setPlanComponents = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string package_id = 6;
 * @return {string}
 */
proto.api.PlanItem.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanItem} returns this
 */
proto.api.PlanItem.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PlanPriceItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PlanPriceItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PlanPriceItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanPriceItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    planId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PlanPriceItem}
 */
proto.api.PlanPriceItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PlanPriceItem;
  return proto.api.PlanPriceItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PlanPriceItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PlanPriceItem}
 */
proto.api.PlanPriceItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PlanPriceItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PlanPriceItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PlanPriceItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PlanPriceItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.PlanPriceItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanPriceItem} returns this
 */
proto.api.PlanPriceItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.PlanPriceItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanPriceItem} returns this
 */
proto.api.PlanPriceItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.api.PlanPriceItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.PlanPriceItem} returns this
 */
proto.api.PlanPriceItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string plan_id = 4;
 * @return {string}
 */
proto.api.PlanPriceItem.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PlanPriceItem} returns this
 */
proto.api.PlanPriceItem.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SubscriptionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SubscriptionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SubscriptionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubscriptionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    planCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    planComponents: jspb.Message.getFieldWithDefault(msg, 5, ""),
    connectionInputs: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    packageServiceCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    batchCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    netSwaps: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalDistance: jspb.Message.getFieldWithDefault(msg, 14, 0),
    vehicleLicencePlate: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SubscriptionItem}
 */
proto.api.SubscriptionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SubscriptionItem;
  return proto.api.SubscriptionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SubscriptionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SubscriptionItem}
 */
proto.api.SubscriptionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanComponents(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionInputs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageServiceCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNetSwaps(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDistance(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleLicencePlate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SubscriptionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SubscriptionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SubscriptionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubscriptionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlanCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlanComponents();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConnectionInputs();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserNumber();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPackageServiceCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBatchCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNetSwaps();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getTotalDistance();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getVehicleLicencePlate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string plan_id = 2;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 subscription_id = 3;
 * @return {number}
 */
proto.api.SubscriptionItem.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string plan_code = 4;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getPlanCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setPlanCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plan_components = 5;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getPlanComponents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setPlanComponents = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string connection_inputs = 6;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getConnectionInputs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setConnectionInputs = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 user_number = 8;
 * @return {number}
 */
proto.api.SubscriptionItem.prototype.getUserNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setUserNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string package_service_code = 9;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getPackageServiceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setPackageServiceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string batch_code = 10;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getBatchCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setBatchCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vehicle_id = 11;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string customer_id = 12;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional float net_swaps = 13;
 * @return {number}
 */
proto.api.SubscriptionItem.prototype.getNetSwaps = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setNetSwaps = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int64 total_distance = 14;
 * @return {number}
 */
proto.api.SubscriptionItem.prototype.getTotalDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setTotalDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string vehicle_licence_plate = 15;
 * @return {string}
 */
proto.api.SubscriptionItem.prototype.getVehicleLicencePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubscriptionItem} returns this
 */
proto.api.SubscriptionItem.prototype.setVehicleLicencePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPlansListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPlansListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPlansListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlansListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPlansListRequest}
 */
proto.api.GetPlansListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPlansListRequest;
  return proto.api.GetPlansListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPlansListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPlansListRequest}
 */
proto.api.GetPlansListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPlansListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPlansListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPlansListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlansListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string package_id = 1;
 * @return {string}
 */
proto.api.GetPlansListRequest.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetPlansListRequest} returns this
 */
proto.api.GetPlansListRequest.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetPlansListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPlansListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPlansListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPlansListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlansListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.api.PlanItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPlansListResponse}
 */
proto.api.GetPlansListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPlansListResponse;
  return proto.api.GetPlansListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPlansListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPlansListResponse}
 */
proto.api.GetPlansListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.PlanItem;
      reader.readMessage(value,proto.api.PlanItem.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPlansListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPlansListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPlansListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlansListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.PlanItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanItem plans = 1;
 * @return {!Array<!proto.api.PlanItem>}
 */
proto.api.GetPlansListResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.api.PlanItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.PlanItem, 1));
};


/**
 * @param {!Array<!proto.api.PlanItem>} value
 * @return {!proto.api.GetPlansListResponse} returns this
*/
proto.api.GetPlansListResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.PlanItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.PlanItem}
 */
proto.api.GetPlansListResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.PlanItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetPlansListResponse} returns this
 */
proto.api.GetPlansListResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSubscriptionListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSubscriptionListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSubscriptionListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSubscriptionListRequest}
 */
proto.api.GetSubscriptionListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSubscriptionListRequest;
  return proto.api.GetSubscriptionListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSubscriptionListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSubscriptionListRequest}
 */
proto.api.GetSubscriptionListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSubscriptionListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSubscriptionListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSubscriptionListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.api.GetSubscriptionListRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetSubscriptionListRequest} returns this
 */
proto.api.GetSubscriptionListRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetSubscriptionListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSubscriptionListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSubscriptionListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSubscriptionListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto.api.SubscriptionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSubscriptionListResponse}
 */
proto.api.GetSubscriptionListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSubscriptionListResponse;
  return proto.api.GetSubscriptionListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSubscriptionListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSubscriptionListResponse}
 */
proto.api.GetSubscriptionListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.SubscriptionItem;
      reader.readMessage(value,proto.api.SubscriptionItem.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSubscriptionListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSubscriptionListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSubscriptionListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.SubscriptionItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionItem subscriptions = 1;
 * @return {!Array<!proto.api.SubscriptionItem>}
 */
proto.api.GetSubscriptionListResponse.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.api.SubscriptionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SubscriptionItem, 1));
};


/**
 * @param {!Array<!proto.api.SubscriptionItem>} value
 * @return {!proto.api.GetSubscriptionListResponse} returns this
*/
proto.api.GetSubscriptionListResponse.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.SubscriptionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SubscriptionItem}
 */
proto.api.GetSubscriptionListResponse.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.SubscriptionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetSubscriptionListResponse} returns this
 */
proto.api.GetSubscriptionListResponse.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBillsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBillsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBillsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBillsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBillsRequest}
 */
proto.api.GetBillsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBillsRequest;
  return proto.api.GetBillsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBillsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBillsRequest}
 */
proto.api.GetBillsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBillsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBillsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBillsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBillsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.api.GetBillsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetBillsRequest} returns this
 */
proto.api.GetBillsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetBillsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBillsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBillsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBillsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBillsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billsList: jspb.Message.toObjectList(msg.getBillsList(),
    proto.api.Bill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBillsResponse}
 */
proto.api.GetBillsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBillsResponse;
  return proto.api.GetBillsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBillsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBillsResponse}
 */
proto.api.GetBillsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Bill;
      reader.readMessage(value,proto.api.Bill.deserializeBinaryFromReader);
      msg.addBills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBillsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBillsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBillsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBillsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Bill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Bill bills = 1;
 * @return {!Array<!proto.api.Bill>}
 */
proto.api.GetBillsResponse.prototype.getBillsList = function() {
  return /** @type{!Array<!proto.api.Bill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Bill, 1));
};


/**
 * @param {!Array<!proto.api.Bill>} value
 * @return {!proto.api.GetBillsResponse} returns this
*/
proto.api.GetBillsResponse.prototype.setBillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Bill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Bill}
 */
proto.api.GetBillsResponse.prototype.addBills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Bill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetBillsResponse} returns this
 */
proto.api.GetBillsResponse.prototype.clearBillsList = function() {
  return this.setBillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateSubscriptionRequest}
 */
proto.api.UpdateSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateSubscriptionRequest;
  return proto.api.UpdateSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateSubscriptionRequest}
 */
proto.api.UpdateSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.api.UpdateSubscriptionRequest.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateSubscriptionRequest} returns this
 */
proto.api.UpdateSubscriptionRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.UpdateSubscriptionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateSubscriptionRequest} returns this
 */
proto.api.UpdateSubscriptionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.api.UpdateSubscriptionRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateSubscriptionRequest} returns this
 */
proto.api.UpdateSubscriptionRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateSubscriptionResponse}
 */
proto.api.UpdateSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateSubscriptionResponse;
  return proto.api.UpdateSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateSubscriptionResponse}
 */
proto.api.UpdateSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetLatestBillByCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetLatestBillByCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetLatestBillByCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, ""),
    month: jspb.Message.getFieldWithDefault(msg, 3, 0),
    year: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetLatestBillByCustomerRequest}
 */
proto.api.GetLatestBillByCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetLatestBillByCustomerRequest;
  return proto.api.GetLatestBillByCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetLatestBillByCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetLatestBillByCustomerRequest}
 */
proto.api.GetLatestBillByCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetLatestBillByCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetLatestBillByCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetLatestBillByCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetLatestBillByCustomerRequest} returns this
 */
proto.api.GetLatestBillByCustomerRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetLatestBillByCustomerRequest} returns this
 */
proto.api.GetLatestBillByCustomerRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 month = 3;
 * @return {number}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetLatestBillByCustomerRequest} returns this
 */
proto.api.GetLatestBillByCustomerRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 year = 4;
 * @return {number}
 */
proto.api.GetLatestBillByCustomerRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetLatestBillByCustomerRequest} returns this
 */
proto.api.GetLatestBillByCustomerRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CustomerBill.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CustomerBill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CustomerBill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CustomerBill.toObject = function(includeInstance, msg) {
  var f, obj = {
    billNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerNo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionNo: jspb.Message.getFieldWithDefault(msg, 5, 0),
    issueDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postingDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lateFeeApplied: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pb_package: jspb.Message.getFieldWithDefault(msg, 11, ""),
    plan: jspb.Message.getFieldWithDefault(msg, 12, ""),
    batch: jspb.Message.getFieldWithDefault(msg, 13, ""),
    term: jspb.Message.getFieldWithDefault(msg, 14, ""),
    components: (f = msg.getComponents()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    usageCalculations: (f = msg.getUsageCalculations()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    perSwapCost: jspb.Message.getFieldWithDefault(msg, 17, ""),
    totalOperationalBikes: jspb.Message.getFieldWithDefault(msg, 18, 0),
    billingMonth: jspb.Message.getFieldWithDefault(msg, 19, 0),
    billingYear: jspb.Message.getFieldWithDefault(msg, 20, 0),
    lineitems: (f = msg.getLineitems()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    vehicleLicencePlate: jspb.Message.getFieldWithDefault(msg, 22, ""),
    billingItems: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CustomerBill}
 */
proto.api.CustomerBill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CustomerBill;
  return proto.api.CustomerBill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CustomerBill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CustomerBill}
 */
proto.api.CustomerBill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBillNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCustomerNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubscriptionNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostingDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLateFeeApplied(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackage(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlan(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatch(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerm(value);
      break;
    case 15:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setComponents(value);
      break;
    case 16:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setUsageCalculations(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerSwapCost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalOperationalBikes(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillingMonth(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillingYear(value);
      break;
    case 21:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLineitems(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleLicencePlate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CustomerBill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CustomerBill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CustomerBill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CustomerBill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerNo();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionNo();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIssueDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostingDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLateFeeApplied();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPackage();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPlan();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBatch();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTerm();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getComponents();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getUsageCalculations();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPerSwapCost();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTotalOperationalBikes();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getBillingMonth();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getBillingYear();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getLineitems();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getVehicleLicencePlate();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBillingItems();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional int32 bill_no = 1;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getBillNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setBillNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 customer_no = 2;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getCustomerNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setCustomerNo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string customer_name = 3;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 subscription_no = 5;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getSubscriptionNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setSubscriptionNo = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string issue_date = 6;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getIssueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setIssueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string posting_date = 7;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getPostingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setPostingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string due_date = 8;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 late_fee_applied = 10;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getLateFeeApplied = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setLateFeeApplied = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string package = 11;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getPackage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setPackage = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string plan = 12;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setPlan = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string batch = 13;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getBatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setBatch = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string term = 14;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Struct components = 15;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.CustomerBill.prototype.getComponents = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 15));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.CustomerBill} returns this
*/
proto.api.CustomerBill.prototype.setComponents = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.clearComponents = function() {
  return this.setComponents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CustomerBill.prototype.hasComponents = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Struct usage_calculations = 16;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.CustomerBill.prototype.getUsageCalculations = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 16));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.CustomerBill} returns this
*/
proto.api.CustomerBill.prototype.setUsageCalculations = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.clearUsageCalculations = function() {
  return this.setUsageCalculations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CustomerBill.prototype.hasUsageCalculations = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string per_swap_cost = 17;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getPerSwapCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setPerSwapCost = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 total_operational_bikes = 18;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getTotalOperationalBikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setTotalOperationalBikes = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 billing_month = 19;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getBillingMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setBillingMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 billing_year = 20;
 * @return {number}
 */
proto.api.CustomerBill.prototype.getBillingYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setBillingYear = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional google.protobuf.Struct lineItems = 21;
 * @return {?proto.google.protobuf.Struct}
 */
proto.api.CustomerBill.prototype.getLineitems = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 21));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.api.CustomerBill} returns this
*/
proto.api.CustomerBill.prototype.setLineitems = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.clearLineitems = function() {
  return this.setLineitems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CustomerBill.prototype.hasLineitems = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string vehicle_licence_plate = 22;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getVehicleLicencePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setVehicleLicencePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string billing_items = 24;
 * @return {string}
 */
proto.api.CustomerBill.prototype.getBillingItems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CustomerBill} returns this
 */
proto.api.CustomerBill.prototype.setBillingItems = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetLatestBillByCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetLatestBillByCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetLatestBillByCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetLatestBillByCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bill: (f = msg.getBill()) && proto.api.CustomerBill.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetLatestBillByCustomerResponse}
 */
proto.api.GetLatestBillByCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetLatestBillByCustomerResponse;
  return proto.api.GetLatestBillByCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetLatestBillByCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetLatestBillByCustomerResponse}
 */
proto.api.GetLatestBillByCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CustomerBill;
      reader.readMessage(value,proto.api.CustomerBill.deserializeBinaryFromReader);
      msg.setBill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetLatestBillByCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetLatestBillByCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetLatestBillByCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetLatestBillByCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBill();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CustomerBill.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerBill bill = 1;
 * @return {?proto.api.CustomerBill}
 */
proto.api.GetLatestBillByCustomerResponse.prototype.getBill = function() {
  return /** @type{?proto.api.CustomerBill} */ (
    jspb.Message.getWrapperField(this, proto.api.CustomerBill, 1));
};


/**
 * @param {?proto.api.CustomerBill|undefined} value
 * @return {!proto.api.GetLatestBillByCustomerResponse} returns this
*/
proto.api.GetLatestBillByCustomerResponse.prototype.setBill = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetLatestBillByCustomerResponse} returns this
 */
proto.api.GetLatestBillByCustomerResponse.prototype.clearBill = function() {
  return this.setBill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetLatestBillByCustomerResponse.prototype.hasBill = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPackageRequest}
 */
proto.api.GetPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPackageRequest;
  return proto.api.GetPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPackageRequest}
 */
proto.api.GetPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string package_code = 1;
 * @return {string}
 */
proto.api.GetPackageRequest.prototype.getPackageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetPackageRequest} returns this
 */
proto.api.GetPackageRequest.prototype.setPackageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_package: (f = msg.getPackage()) && proto.api.PackageItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPackageResponse}
 */
proto.api.GetPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPackageResponse;
  return proto.api.GetPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPackageResponse}
 */
proto.api.GetPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.PackageItem;
      reader.readMessage(value,proto.api.PackageItem.deserializeBinaryFromReader);
      msg.setPackage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.PackageItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional PackageItem package = 1;
 * @return {?proto.api.PackageItem}
 */
proto.api.GetPackageResponse.prototype.getPackage = function() {
  return /** @type{?proto.api.PackageItem} */ (
    jspb.Message.getWrapperField(this, proto.api.PackageItem, 1));
};


/**
 * @param {?proto.api.PackageItem|undefined} value
 * @return {!proto.api.GetPackageResponse} returns this
*/
proto.api.GetPackageResponse.prototype.setPackage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetPackageResponse} returns this
 */
proto.api.GetPackageResponse.prototype.clearPackage = function() {
  return this.setPackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetPackageResponse.prototype.hasPackage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PackageItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PackageItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PackageItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PackageItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingCycle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    packageComponents: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PackageItem}
 */
proto.api.PackageItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PackageItem;
  return proto.api.PackageItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PackageItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PackageItem}
 */
proto.api.PackageItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCycle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageComponents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PackageItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PackageItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PackageItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PackageItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingCycle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPackageComponents();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string billing_cycle = 1;
 * @return {string}
 */
proto.api.PackageItem.prototype.getBillingCycle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PackageItem} returns this
 */
proto.api.PackageItem.prototype.setBillingCycle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.PackageItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PackageItem} returns this
 */
proto.api.PackageItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.api.PackageItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PackageItem} returns this
 */
proto.api.PackageItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string package_components = 4;
 * @return {string}
 */
proto.api.PackageItem.prototype.getPackageComponents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PackageItem} returns this
 */
proto.api.PackageItem.prototype.setPackageComponents = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPlanRequest}
 */
proto.api.GetPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPlanRequest;
  return proto.api.GetPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPlanRequest}
 */
proto.api.GetPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string plan_code = 1;
 * @return {string}
 */
proto.api.GetPlanRequest.prototype.getPlanCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetPlanRequest} returns this
 */
proto.api.GetPlanRequest.prototype.setPlanCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plan: (f = msg.getPlan()) && proto.api.PlanItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetPlanResponse}
 */
proto.api.GetPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetPlanResponse;
  return proto.api.GetPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetPlanResponse}
 */
proto.api.GetPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.PlanItem;
      reader.readMessage(value,proto.api.PlanItem.deserializeBinaryFromReader);
      msg.setPlan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlan();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.PlanItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlanItem plan = 1;
 * @return {?proto.api.PlanItem}
 */
proto.api.GetPlanResponse.prototype.getPlan = function() {
  return /** @type{?proto.api.PlanItem} */ (
    jspb.Message.getWrapperField(this, proto.api.PlanItem, 1));
};


/**
 * @param {?proto.api.PlanItem|undefined} value
 * @return {!proto.api.GetPlanResponse} returns this
*/
proto.api.GetPlanResponse.prototype.setPlan = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetPlanResponse} returns this
 */
proto.api.GetPlanResponse.prototype.clearPlan = function() {
  return this.setPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetPlanResponse.prototype.hasPlan = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSubscriptionRequest}
 */
proto.api.GetSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSubscriptionRequest;
  return proto.api.GetSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSubscriptionRequest}
 */
proto.api.GetSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.api.GetSubscriptionRequest.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetSubscriptionRequest} returns this
 */
proto.api.GetSubscriptionRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscription: (f = msg.getSubscription()) && proto.api.SubscriptionItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSubscriptionResponse}
 */
proto.api.GetSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSubscriptionResponse;
  return proto.api.GetSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSubscriptionResponse}
 */
proto.api.GetSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.SubscriptionItem;
      reader.readMessage(value,proto.api.SubscriptionItem.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.SubscriptionItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubscriptionItem subscription = 1;
 * @return {?proto.api.SubscriptionItem}
 */
proto.api.GetSubscriptionResponse.prototype.getSubscription = function() {
  return /** @type{?proto.api.SubscriptionItem} */ (
    jspb.Message.getWrapperField(this, proto.api.SubscriptionItem, 1));
};


/**
 * @param {?proto.api.SubscriptionItem|undefined} value
 * @return {!proto.api.GetSubscriptionResponse} returns this
*/
proto.api.GetSubscriptionResponse.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetSubscriptionResponse} returns this
 */
proto.api.GetSubscriptionResponse.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetSubscriptionResponse.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateNetSwapsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateNetSwapsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateNetSwapsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateNetSwapsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    netSwaps: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateNetSwapsRequest}
 */
proto.api.UpdateNetSwapsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateNetSwapsRequest;
  return proto.api.UpdateNetSwapsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateNetSwapsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateNetSwapsRequest}
 */
proto.api.UpdateNetSwapsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNetSwaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateNetSwapsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateNetSwapsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateNetSwapsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateNetSwapsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNetSwaps();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.api.UpdateNetSwapsRequest.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateNetSwapsRequest} returns this
 */
proto.api.UpdateNetSwapsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float net_swaps = 2;
 * @return {number}
 */
proto.api.UpdateNetSwapsRequest.prototype.getNetSwaps = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateNetSwapsRequest} returns this
 */
proto.api.UpdateNetSwapsRequest.prototype.setNetSwaps = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateNetSwapsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateNetSwapsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateNetSwapsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateNetSwapsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateNetSwapsResponse}
 */
proto.api.UpdateNetSwapsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateNetSwapsResponse;
  return proto.api.UpdateNetSwapsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateNetSwapsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateNetSwapsResponse}
 */
proto.api.UpdateNetSwapsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateNetSwapsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateNetSwapsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateNetSwapsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateNetSwapsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.api.UpdateNetSwapsResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateNetSwapsResponse} returns this
 */
proto.api.UpdateNetSwapsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.UpdateNetSwapsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateNetSwapsResponse} returns this
 */
proto.api.UpdateNetSwapsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.api);
